import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';

export const Hero = () => {
  return (
    <Box
      sx={{
        // bgcolor: 'background.paper',
        pt: 8,
        pb: 6
      }}
    >
      <Typography
        component="h1"
        variant="h3"
        align="center"
        // color="text.primary"
        color="#FFF"
        gutterBottom
      >
        <b>アンケート分析に、DXの力を</b>
      </Typography>
      <br />
      <Container maxWidth="sm">
        <Typography
          variant="h6"
          align="center"
          // color="text.secondary"
          color="#FFF"
          paragraph
        >
          CommentScopeを使えば、無料で登録不要、シンプルなインターフェースでサーバーにデータをアップロードすることなくAIを駆使したアンケート分析を実施できます。
        </Typography>
        <Stack
          sx={{ pt: 2 }}
          direction="row"
          spacing={2}
          justifyContent="center"
        >
          <Button
            component={Link}
            to={'/app/dashboard'}
            size="large"
            variant="contained"
            color="warning"
            sx={{ borderRadius: 20, height: 50, width: 250, fontSize: 18 }}
            disableElevation
          >
            使ってみる
          </Button>
          <Button
            target="_blank"
            href="https://forms.gle/j5qCbFJZcZRMvZgP8"
            size="large"
            variant="outlined"
            color="inherit"
            sx={{
              borderRadius: 20,
              height: 50,
              width: 250,
              fontSize: 18,
              borderColor: 'white',
              color: 'white'
            }}
            disableElevation
          >
            お問い合わせ
          </Button>
        </Stack>
      </Container>
    </Box>
  );
};
