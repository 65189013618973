import { useRoutes } from 'react-router-dom';
import { lazyImport } from '@/utils/lazyImport';
import { Landing } from '@/landing';
// import { useAuth } from '@/hooks/use-auth';

import { protectedRoutes } from './protected';
// import { publicRoutes } from './public';
// const { SignIn } = lazyImport(() => import('@/features/signin'), 'SignIn');

export const AppRoutes = () => {
  // const { isAuthenticated } = useAuth();

  const commonRoutes = [
    { path: '/', element: <Landing /> },
    // {
    //   path: '/signin',
    //   element: <SignIn />
    // }
  ];

  // const routes = isAuthenticated ? protectedRoutes : publicRoutes;

  const element = useRoutes([...protectedRoutes, ...commonRoutes]);

  return <>{element}</>;
};
