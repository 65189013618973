import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Divider from '@mui/material/Divider';
import { Hero } from './Hero';
import { AppBarLanding } from './AppBarLanding';
import { Section } from './Section';
import { Cta } from './Cta';
import FunctionCard from './FunctionCard';
import demo from './demo_hero.png';
import demo1 from './demo_1.png';
import demo2 from './demo_2.png';
import demo3 from './demo_3.png';
import GridViewIcon from '@mui/icons-material/GridView';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import Groups3Icon from '@mui/icons-material/Groups3';
import TrendingUpIcon from '@mui/icons-material/TrendingUp';
import ViewCompactIcon from '@mui/icons-material/ViewCompact';
import { Helmet } from 'react-helmet-async';

const functions: any = [
  {
    name: 'ダッシュボード機能',
    text: 'コメント統計を俯瞰する',
    color: '#90caf9',
    description: 'ポジネガ統計やワードクラウドなどの複数の情報を一覧できます。',
    icon: <GridViewIcon fontSize="large" />
  },
  {
    name: 'ポジネガ分析',
    text: '回答のポジティブ・ネガティブを判定',
    color: '#ffcc80',
    description: 'コメントがポジティブかネガティブかを文章から分析できます。',
    icon: <SentimentSatisfiedAltIcon fontSize="large" />
  },
  {
    name: '要望意見の抽出',
    text: '回答に含まれる改善点や気づきを逃さない',
    color: '#80cbc4',
    description: 'コメントに含まれる要望や改善点などの意見を抽出できます。',
    icon: <AddReactionIcon fontSize="large" />
  },
  {
    name: '多数派・少数派意見の抽出',
    text: 'アンケート結果についての洞察を得る',
    color: '#a5d6a7',
    description: '一般的な意見や珍しい意見をそれぞれ抽出することができます。',
    icon: <Groups3Icon fontSize="large" />
  },
  {
    name: 'ワードクラウド機能',
    text: 'アンケート回答を単語レベルで視える化',
    color: '#9fa8da',
    description: 'アンケートの全ての回答を単語レベルで視える化できます。',
    icon: <ViewCompactIcon fontSize="large" />
  },
  {
    name: '時系列分析機能',
    text: 'アンケート結果の改善具合を視覚化',
    color: '#81d4fa',
    description:
      'アンケート回答のポジネガ結果を元に、時系列での改善具合を視覚化できます。',
    icon: <TrendingUpIcon fontSize="large" />
  }
];

function Copyright() {
  return (
    <Typography variant="body2" align="center">
      {'Copyright © '}
      {/* <Link color="inherit" href="https://mui.com/"> */}
      CommentScope
      {/* </Link> */} {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

export function Landing() {
  console.log('Landing is rendered');
  return (
    <>
      <Helmet>
        <title>CommentScope</title>
        <meta
          name="description"
          content="CommentScopeはシンプルなインターフェースでサーバーにデータをアップロードすることなくAIを駆使したアンケート分析を実施することができるWebサービスです。"
        />
      </Helmet>
      <Container
        maxWidth={false}
        sx={{
          background:
            'linear-gradient(180deg, #2E3192 0%, rgba(106,215,232,1) 40%, rgba(255,255,255,1) 50%);'
        }}
      >
        <CssBaseline />
        <Container maxWidth="lg">
          <AppBarLanding />
          <Hero />
          <img src={demo} width="100%" alt="CommentScope画面" />
          <Box m={10}>
            <Divider />
          </Box>
          <Typography variant="h4" align="center" gutterBottom>
            <b>アンケート分析を支えるCommentScope３つの強み</b>
          </Typography>
          <Box m={10} />
          <Section
            image={demo1}
            subtitle="AIによる客観的なアンケート分析"
            title="データドリブンで客観的なAI分析"
            content="「とてもそう思う」「そう思わない」「10点」などの評価は本音が反映されない、人によって採点基準が違うといった問題を抱えています。CommentScopeはAIによる感情分析などの手法を使い、文章から客観性を持った分析を可能とします。"
          />
          <Box m={5} />
          <Section
            image={demo2}
            flip
            subtitle="シンプルかつモダンなインターフェース"
            title="シンプルかつモダンな操作性"
            content="自然言語処理や感情分析などのAIを駆使した高度な分析アプリケーションは操作やUI理解などのハードルが高いもの。CommentScopeでは全機能にクリック1回で到達できる設計により、Excelよりも低いハードルで高度な分析を実施することができます。"
          />
          <Box m={5} />
          <Section
            image={demo3}
            subtitle="情報漏洩が起きない仕組み"
            title="データをサーバーに送らず分析"
            content="アンケート結果などのデータは属性やコメント本文に個人情報などが含まれるおそれがあり、取り扱いが難しいもの。CommentScopeではデータをサーバーにアップロードせず解析を行う高度な仕組みにより、安心してお使いいただくことができます。"
          />
          {/* End hero unit */}
          <Box m={5} />
        </Container>
      </Container>

      <Container
        maxWidth={false}
        sx={{
          background: '#EFF9FC'
        }}
      >
        <Container maxWidth="lg">
          <Box m={5}>{'　'}</Box>
          <Box m={3}>{'　'}</Box>
          <Typography variant="h4" align="center" gutterBottom>
            <b>CommentScopeの分析機能一覧</b>
          </Typography>
          <Typography
            variant="h6"
            align="center"
            color="text.secondary"
            paragraph
          >
            以下のすべての機能に、１クリックでアクセスすることができます。
          </Typography>
          <Box m={10} />
          <Grid container spacing={1}>
            {functions.map((f: any) => {
              return (
                <Grid item xs={4} key={f.name}>
                  <center>
                    <FunctionCard
                      icon={f.icon}
                      lead={f.name}
                      color={f.color}
                      body={f.description}
                    />
                  </center>
                </Grid>
              );
            })}
          </Grid>
          <Box m={3}>{'　'}</Box>
          <Box m={5}>{'　'}</Box>
          <Box m={10} />
        </Container>
      </Container>

      <Cta />

      <Container
        maxWidth={false}
        sx={{
          background: '#454343'
        }}
      >
        <Box sx={{ p: 6, color: 'white' }} component="footer">
          <Typography variant="h6" align="center" gutterBottom>
            CommentScope Inc.
          </Typography>
          <Copyright />
        </Box>
      </Container>
    </>
  );
}
