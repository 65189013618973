import { Link, useLocation } from 'react-router-dom';
import List from '@mui/material/List';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import AutoAwesomeMosaicIcon from '@mui/icons-material/AutoAwesomeMosaic';
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import MoodBadIcon from '@mui/icons-material/MoodBad';
import Toolbar from '@mui/material/Toolbar';
import AddReactionIcon from '@mui/icons-material/AddReaction';
import FmdBadIcon from '@mui/icons-material/FmdBad';
import LensBlurIcon from '@mui/icons-material/LensBlur';
import TimelineIcon from '@mui/icons-material/Timeline';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import DataUsageIcon from '@mui/icons-material/DataUsage';
import { StyledDropzone } from '@/components/misc';
import { useEffect, useState } from 'react';
import * as XLSX from 'xlsx';
import { useWord, useFile, useData, useAnalysis } from '@/stores';
import { CountVectorizer } from '../../lib/vectorizers';
import {
  getSentiments,
  segment,
  countWords,
  isRequestComment
} from '@/lib/text';
import { getClusters } from '@/lib/_clusters';
import { IsolationForest } from 'isolation-forest';
import { SelectItems } from './SelectItems';
import Drawer from '@mui/material/Drawer';
import { iconColor } from '@/config/colors';
import { getColumnHeaders } from '@/lib/excel-utils';

const item = {
  py: 0.5,
  color: 'rgba(255, 255, 255, 0.7)',
  '&:hover, &:focus': {
    bgcolor: 'rgba(255, 255, 255, 0.08)'
  }
};

const iconStyle = { minWidth: '40px' };

export const actions: any = [
  {
    name: 'ダッシュボードを表示',
    link: '/app/dashboard',
    icon: <AutoAwesomeMosaicIcon style={iconStyle} />
  },
  {
    name: 'コメント統計を表示',
    link: '/app/comment_stats',
    icon: <DataUsageIcon style={iconStyle} />
  },
  {
    name: 'ポジティブな意見を抽出',
    link: '/app/positive_comments',
    icon: <SentimentSatisfiedAltIcon style={iconStyle} />
  },
  {
    name: 'ネガティブな意見を抽出',
    link: '/app/negative_comments',
    icon: <MoodBadIcon style={iconStyle} />
  },
  {
    name: '要望を含む意見を抽出',
    link: '/app/request_comments',
    icon: <AddReactionIcon style={iconStyle} />
  },
  {
    name: 'よくある意見を抽出',
    link: '/app/normal_comments',
    icon: <SentimentSatisfiedIcon style={iconStyle} />
  },
  {
    name: '珍しい意見を抽出',
    link: '/app/rare_comments',
    icon: <FmdBadIcon style={iconStyle} />
  },
  {
    name: 'ワードクラウドを表示',
    link: '/app/wordcloud',
    icon: <LensBlurIcon style={iconStyle} />
  },
  {
    name: '時系列分析を表示',
    link: '/app/timeseries',
    icon: <TimelineIcon style={iconStyle} />
  }
];

export function SideNavigator() {
  console.log('SideNavigator is rendered');
  const [refreshed, setRefreshed] = useState(false);
  const { setWords } = useWord();
  const { acceptedFiles, currentFile, setCurrentFile } = useFile();
  const {
    columns,
    setColumns,
    setData,
    currentColumn,
    setCurrentColumn,
    setXColumns
  } = useData();
  const {
    sheet,
    setSheet,
    currentSheet,
    setCurrentSheet,
    setClusters,
    ub,
    lb
  } = useAnalysis();

  function getChipColor(score: number) {
    if (score > ub) {
      return 'success';
    } else if (score < lb) {
      return 'error';
    } else {
      return 'warning';
    }
  }

  function getChipLabel(score: number) {
    if (score > ub) {
      return 'ポジティブ';
    } else if (score < lb) {
      return 'ネガティブ';
    } else {
      return 'ニュートラル';
    }
  }

  useEffect(() => {
    const handleDropAsync = async (file: any, setSheet: any) => {
      setCurrentFile(file.name);
      const data = await file.arrayBuffer();
      const workbook = await XLSX.read(data);
      setSheet(workbook);

      var sheet_name_list = workbook.SheetNames;

      if (!currentSheet) {
        setCurrentSheet(workbook.SheetNames[0]);
      }
      const columnHeaders = await getColumnHeaders(sheet_name_list, workbook);
      setXColumns(columnHeaders);
      let indata: any = XLSX.utils.sheet_to_json(
        workbook.Sheets[sheet_name_list[0]]
      );

      let columnStringHeaders = [];
      columnStringHeaders = columnHeaders.filter(
        (header: any) => typeof indata[1][header] === 'string'
      );

      setColumns(columnStringHeaders);

      let anaColumn: string = columnStringHeaders[0];
      if (!currentColumn) {
        setCurrentColumn(columnStringHeaders[0]);
      } else {
        anaColumn = currentColumn;
      }

      indata = indata.map((v: any) => {
        var temp = Object.assign({}, v);
        if (temp[anaColumn] === undefined) {
          temp[anaColumn] = '(空白)';
        }
        return temp;
      });

      indata = indata.map((v: any) => ({
        ...v,
        segmented: Array.from(segment(v[anaColumn]))
      }));

      indata = indata.map((v: any) => ({
        ...v,
        sentiment: getSentiments(v.segmented),
        request: isRequestComment(v[anaColumn])
      }));

      const counted = countWords(
        segment(indata.map((item: any) => item[anaColumn]).join('。'))
      );

      const segText = indata.map((v: any) => v.segmented.join(' '));

      setWords(
        Object.keys(counted).map((item) => {
          return { text: item, value: counted[item] };
        })
      );

      const cvec = new CountVectorizer();
      await cvec.fit(segText);
      const bowresult = await cvec.predict(segText);
      const clusters: any = await getClusters(bowresult);

      var uniqueClusters = clusters.indexes.filter(
        (v: any, i: any, a: any) => a.indexOf(v) === i
      );
      setClusters(uniqueClusters.sort());

      var isolationForest = new IsolationForest();
      isolationForest.fit(bowresult);

      var trainingScores = isolationForest.scores();

      indata = indata.map((v: any, index: any) => ({
        ...v,
        cluster: clusters.indexes[index],
        anomalyScore: trainingScores[index],
        dateConv: new Date(Date.UTC(0, 0, v.date - 1)),
        chipLabel: getChipLabel(v.sentiment),
        chipColor: getChipColor(v.sentiment)
      }));

      setData(indata);
    };

    if (
      typeof acceptedFiles !== 'undefined' &&
      typeof acceptedFiles[0] !== 'undefined'
    ) {
      handleDropAsync(acceptedFiles[0], setSheet);
    }
  }, [acceptedFiles, refreshed]);

  const location = useLocation();

  const inActions = actions.map((action: any) => {
    if (columns) {
      return {
        ...action,
        disabled: false
      };
    } else {
      return {
        ...action,
        disabled: true
      };
    }
  });

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: 320,
        flexShrink: 0,
        [`& .MuiDrawer-paper`]: { width: 320, boxSizing: 'border-box' }
      }}
    >
      <Toolbar />
      <List
        sx={{
          '&& .Mui-selected, && .Mui-selected:hover': {
            bgcolor: 'rgba(245, 247, 250, 1)',
            borderRadius: 2,
            '&, & .MuiListItemIcon-root': {
              color: 'primary.main'
            }
          },
          '& .MuiListItemButton-root:hover': {
            bgcolor: '#e3f2fd',
            borderRadius: 2,
            '&, & .MuiListItemIcon-root': {
              color: 'primary.light'
            }
          }
        }}
      >
        <ListItem>
          <ListItemText>ファイルの読み込み</ListItemText>
        </ListItem>

        <ListItem sx={{ ...item, fontSize: 22, color: '#64b5f6', mb: 0.5 }}>
          <Grid container>
            <Grid item xs={12} style={{ textAlign: 'center' }}>
              <StyledDropzone />
            </Grid>
          </Grid>
        </ListItem>

        {typeof acceptedFiles !== 'undefined' &&
        typeof acceptedFiles[0] !== 'undefined' ? (
          <ListItem>
            <SelectItems
              mapBasis={acceptedFiles}
              current={currentFile}
              setCurrent={setCurrentFile}
              label={'ファイル名を選択'}
              refreshed={refreshed}
              setRefreshed={setRefreshed}
            />
          </ListItem>
        ) : (
          <></>
        )}

        {sheet && (
          <ListItem>
            <SelectItems
              mapBasis={sheet.SheetNames}
              current={currentSheet}
              setCurrent={setCurrentSheet}
              label={'シート名を選択'}
              refreshed={refreshed}
              setRefreshed={setRefreshed}
            />
          </ListItem>
        )}

        {columns && (
          <ListItem>
            <SelectItems
              mapBasis={columns}
              current={currentColumn}
              setCurrent={setCurrentColumn}
              label={'文章を含む列名を選択'}
              refreshed={refreshed}
              setRefreshed={setRefreshed}
            />
          </ListItem>
        )}

        <ListItem>
          <ListItemText>アンケート分析の実行</ListItemText>
        </ListItem>
        <Box mr={2} ml={2}>
          {inActions.map((action: any) => {
            if (action.disabled) {
              return (
                <ListItem
                  disablePadding
                  key={action.name}
                  selected={location.pathname === action.link}
                >
                  <ListItemButton sx={item} disabled>
                    <ListItemIcon sx={{ color: iconColor }}>
                      {action.icon}
                    </ListItemIcon>
                    <ListItemText
                      sx={{ bgcolor: '', color: 'rgba(116, 127, 149, 1 )' }}
                    >
                      {action.name}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              );
            } else {
              return (
                <ListItem
                  disablePadding
                  key={action.name}
                  component={Link}
                  to={action.link}
                  selected={location.pathname === action.link}
                >
                  <ListItemButton sx={item}>
                    <ListItemIcon sx={{ color: iconColor }}>
                      {action.icon}
                    </ListItemIcon>
                    <ListItemText
                      sx={{ bgcolor: '', color: 'rgba(116, 127, 149, 1 )' }}
                    >
                      {action.name}
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              );
            }
          })}
        </Box>
      </List>
    </Drawer>
  );
}
