import AppBar from '@mui/material/AppBar';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { iconColor } from '@/config/colors';
import LogoutIcon from '@mui/icons-material/Logout';
import { useAuth } from '@/hooks/use-auth';
import { useNavigate } from 'react-router-dom';

export function Header(props: any) {
  console.log('Header is rendered');
  const auth = useAuth();
  const navigate = useNavigate();

  const handleSignOut = async () => {
    await auth.signOut();
    navigate({ pathname: '/signin' });
  };

  return (
    <AppBar
      color="inherit"
      position="fixed"
      elevation={0}
      style={{ zIndex: 1251 }}
      sx={{ borderBottom: 1, borderColor: 'RGBA(240,240,240,1)' }}
    >
      <Toolbar>
        <Grid container spacing={1} alignItems="center">
          <Grid sx={{ display: { sm: 'block', xs: 'block' } }} item>
            <Grid item xs>
              <Typography
                variant="h6"
                noWrap
                sx={{
                  mr: 2,
                  display: { xs: 'flex', md: 'flex' },
                  flexGrow: 1,
                  fontWeight: 700,
                  color: 'RGBA(61,76,93,1)',
                  textDecoration: 'none'
                }}
              >
                <ContentPasteSearchIcon
                  sx={{
                    display: { xs: 'flex', md: 'flex' },
                    mt: 0.43,
                    mr: 1
                  }}
                />
                CommentScope
              </Typography>
            </Grid>
          </Grid>
          <Grid item xs />
          <Grid item>
            <Typography variant="subtitle1" noWrap color={'black'}>
              アンケート分析
            </Typography>
          </Grid>
          <Grid item>
            <KeyboardArrowRightIcon
              fontSize="large"
              sx={{ color: iconColor, fontSize: 25 }}
            />
          </Grid>
          <Grid item>
            <Typography variant="subtitle1" noWrap color={'primary.dark'}>
              {/* {typeof actions !== 'undefined' &&
                actions.find(
                  (element: any) => element.link === location.pathname
                ).name} */}
            </Typography>
          </Grid>
          <Grid item xs />
          {/* <Grid item>
            <IconButton
              disabled={!data}
              onClick={() => writeArrayToExcel(data)}
            >
              <FileDownloadIcon
                fontSize="large"
                sx={{ color: iconColor, fontSize: 30 }}
              />
            </IconButton>
          </Grid> */}
          {/* <Grid item>
            <IconButton>
              <AccountCircleIcon
                fontSize="large"
                sx={{ color: iconColor, fontSize: 30 }}
              />
            </IconButton>
          </Grid> */}
          <Grid item>
            <IconButton onClick={() => window.location.reload()}>
              <LogoutIcon
                fontSize="large"
                sx={{ color: iconColor, fontSize: 30 }}
              />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
}
