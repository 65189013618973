import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import { styled } from '@mui/material/styles';

const CustomSelect = styled(Select)((borderRadius: any) => ({
  color: 'grey',
  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#eaeaea'
    },
    '&:hover fieldset': {
      borderColor: 'primary.light'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'primary.dark'
    }
  }
}));

const CustomSelectRounded = styled(Select)((borderRadius: any) => ({
  color: 'grey',
  borderRadius: 20,
  '&.MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: '#eaeaea'
    },
    '&:hover fieldset': {
      borderColor: 'primary.light'
    },
    '&.Mui-focused fieldset': {
      borderColor: 'primary.dark'
    }
  }
}));

export function SelectItems(props: any) {
  const handleSelectChange = (e: any, setTarget: any) => {
    setTarget(e.target.value);

    if (typeof props.refreshed !== 'undefined') {
      props.setRefreshed(!props.refreshed);
    }
  };

  return (
    <FormControl
      size="small"
      sx={{
        minWidth: props.width ? props.width : 'calc(100%)',
        maxWidth: 300
      }}
    >
      <InputLabel id="demo-simple-select-label">{props.label}</InputLabel>
      {props.rounded ? (
        <CustomSelectRounded
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.current}
          onChange={(e) => handleSelectChange(e, props.setCurrent)}
          label={props.label}
        >
          {props.mapBasis.map((file: any) => {
            return (
              <MenuItem value={file.name || file} key={file.name || file}>
                {file.name || file}
              </MenuItem>
            );
          })}
        </CustomSelectRounded>
      ) : (
        <CustomSelect
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          value={props.current}
          onChange={(e) => handleSelectChange(e, props.setCurrent)}
          label={props.label}
        >
          {props.mapBasis.map((file: any) => {
            return (
              <MenuItem value={file.name || file} key={file.name || file}>
                {file.name || file}
              </MenuItem>
            );
          })}
        </CustomSelect>
      )}
    </FormControl>
  );
}
