import { createContext, useContext, useState } from 'react';

const WordContext = createContext(
  {} as {
    words: any;
    setWords: React.Dispatch<React.SetStateAction<any>>;
    userStopWords: any;
    setUserStopWords: React.Dispatch<React.SetStateAction<any>>;
    displayUserStopWords: any;
    setDisplayUserStopWords: React.Dispatch<React.SetStateAction<any>>;
  }
);

export const WordContextProvider = (props: { children: React.ReactNode }) => {
  const [words, setWords] = useState();
  const [userStopWords, setUserStopWords] = useState(['.', '空白']);
  const [displayUserStopWords, setDisplayUserStopWords] = useState(false);

  return (
    <WordContext.Provider
      value={{
        words,
        setWords,
        userStopWords,
        setUserStopWords,
        displayUserStopWords,
        setDisplayUserStopWords
      }}
    >
      {props.children}
    </WordContext.Provider>
  );
};

export function useWord() {
  return useContext(WordContext);
}
