import * as React from 'react';
import { MemoryRouter as Router } from 'react-router-dom';
// import { createTheme, ThemeProvider } from '@mui/material/styles';
// import 'fontsource-noto-sans-jp';
import Grid from '@mui/material/Grid';
import { HelmetProvider } from 'react-helmet-async';
// import 'fontsource-noto-sans-jp/500.css';

// import { Spinner } from '@/components/spinner';
import {
  WordContextProvider,
  FileContextProvider,
  DateContextProvider,
  DataContextProvider,
  AnalysisContextProvider
} from '@/stores';
import { AuthProvider } from '@/hooks/use-auth';

// export const theme = createTheme({
//   typography: {
//     fontFamily: ['Noto Sans JP', 'sans-serif'].join(',')
//   }
// });
// const ErrorFallback = () => {
//   return (
//     <div
//       className="text-red-500 w-screen h-screen flex flex-col justify-center items-center"
//       role="alert"
//     >
//       <h2 className="text-lg font-semibold">Ooops, something went wrong :( </h2>
//       {/* <Button className="mt-4" onClick={() => window.location.assign(window.location.origin)}>
//         Refresh
//       </Button> */}
//     </div>
//   );
// };

type AppProviderProps = {
  children: React.ReactNode;
};

export const AppProvider = ({ children }: AppProviderProps) => {
  return (
    <React.Suspense
      fallback={
        <Grid
          container
          spacing={0}
          direction="column"
          alignItems="center"
          justifyContent="center"
          style={{ minHeight: '100vh' }}
        >
          <Grid item xs={3}>
            {/* <Spinner size="xl" /> */}
          </Grid>
        </Grid>
      }
    >
      {/* <ThemeProvider theme={theme}> */}
      <HelmetProvider>
        <AuthProvider>
          <AnalysisContextProvider>
            <DataContextProvider>
              <DateContextProvider>
                <FileContextProvider>
                  <WordContextProvider>
                    <Router>{children}</Router>
                  </WordContextProvider>
                </FileContextProvider>
              </DateContextProvider>
            </DataContextProvider>
          </AnalysisContextProvider>
        </AuthProvider>
      </HelmetProvider>
      {/* </ThemeProvider> */}
    </React.Suspense>
  );
};
