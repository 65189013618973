import * as XLSX from 'xlsx';

export function writeArrayToExcel(rows: any) {
  const worksheet = XLSX.utils.json_to_sheet(rows);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'Dates');
  XLSX.writeFile(workbook, 'Presidents.xlsx', { compression: true });
}

export function getColumnHeaders(sheet_name_list: any, workbook: any) {
  let columnHeaders: any = [];
  for (var sheetIndex = 0; sheetIndex < sheet_name_list.length; sheetIndex++) {
    var worksheet = workbook.Sheets[sheet_name_list[sheetIndex]];
    for (let key in worksheet) {
      let regEx = new RegExp('^(\\w)(1){1}$');
      if (regEx.test(key) === true) {
        columnHeaders.push(worksheet[key].v);
      }
    }
  }
  return columnHeaders;
}
