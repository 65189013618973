const requestComments: any = [
  'してほしい',
  'して欲しい',
  'してくれ',
  'してください',
  'すべき',
  'れるべき',
  'がベストだと思う',
  'が必要',
  'てほしい',
  'て欲しい',
  'が欲しい',
  'やるといいと思う',
  'するべき',
  'した方がいい',
  'した方が良い',
  'したほうが良い',
  'したほうがいい',
  'したほうがよい',
  'がいい'
];

export default requestComments;
