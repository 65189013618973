import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Avatar from '@mui/material/Avatar';
import Typography from '@mui/material/Typography';

const FunctionCard = (props: any) => {
  return (
    <Card
      elevation={0}
      sx={{
        minWidth: 275,
        minHeight: 275,
        maxHeight: 275,
        border: 1,
        borderColor: '#3874CB'
      }}
    >
      <CardContent>
        <Avatar sx={{ width: 56, height: 56, mt: 3, background: props.color }}>
          {props.icon}
        </Avatar>
        <Typography variant="h5" component="div" sx={{ mt: 2 }}>
          {props.lead}
        </Typography>
        <Typography variant="body1" sx={{ mt: 2 }}>
          {props.body}
        </Typography>
      </CardContent>
      {/* <CardActions>
        <Button size="small">Learn More</Button>
      </CardActions> */}
    </Card>
  );
};

export default FunctionCard;
