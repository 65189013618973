import sentimentsWords from './sentiment-words';
import requestComments from './request-comments';
import stopWords from './stop-words';

const TinySegmenter = require('tiny-segmenter');
const tinySegmenter = new TinySegmenter();

export function segment(text: string) {
  let segmented = tinySegmenter.segment(text);
  let difference = segmented.filter((x: any) => !stopWords.includes(x));
  return difference;
}

export function countWords(array: any) {
  var count: any = {};
  array.forEach((val: any) => (count[val] = (count[val] || 0) + 1));
  return count;
}

export function getSentiments(words: any) {
  let sentimentSum = words.reduce(
    (partialSentiment: any, word: any) =>
      partialSentiment + (sentimentsWords[word] || 0),
    0
  );
  return sentimentSum;
}

export function isRequestComment(text: string) {
  const result = requestComments.some((el: any) => text.includes(el));
  return result;
}
