import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const Section = (props: any) => {
  const explanation = (
    <>
      <Typography
        component="h1"
        variant="subtitle1"
        align="left"
        color="primary"
        gutterBottom
      >
        <b>{props.subtitle}</b>
      </Typography>
      <Typography
        component="h1"
        variant="h4"
        align="left"
        color="text.primary"
        gutterBottom
      >
        <b>{props.title}</b>
      </Typography>
      <Typography variant="h6" align="left" color="text.secondary" paragraph>
        {props.content}
      </Typography>
    </>
  );

  return (
    <Grid container>
      {props.flip ? (
        <>
          <Grid item xs={6}>
            <Box m={3}>
              <img
                src={props.image}
                width="100%"
                style={{ mixBlendMode: 'multiply' }}
                alt="explanation 1"
              />
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box m={3}>{explanation}</Box>
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={6}>
            <Box m={3}>{explanation}</Box>
          </Grid>
          <Grid item xs={6}>
            <Box m={3}>
              <img
                src={props.image}
                width="100%"
                style={{ mixBlendMode: 'multiply' }}
                alt="explanation 1"
              />
            </Box>
          </Grid>
        </>
      )}
    </Grid>
  );
};
