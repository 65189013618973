import { createContext, useContext, useState } from 'react';

const AnalysisContext = createContext(
  {} as {
    sheet: any;
    setSheet: React.Dispatch<React.SetStateAction<any>>;
    currentSheet: string;
    setCurrentSheet: React.Dispatch<React.SetStateAction<string>>;
    clusters: any;
    setClusters: React.Dispatch<React.SetStateAction<any>>;
    lb: number;
    setLb: React.Dispatch<React.SetStateAction<number>>;
    ub: number;
    setUb: React.Dispatch<React.SetStateAction<number>>;
  }
);

export const AnalysisContextProvider = (props: {
  children: React.ReactNode;
}) => {
  const [sheet, setSheet] = useState();
  const [clusters, setClusters] = useState();
  const [ub, setUb] = useState(0.5);
  const [currentSheet, setCurrentSheet] = useState('');
  const [lb, setLb] = useState(-0.5);
  return (
    <AnalysisContext.Provider
      value={{
        sheet,
        setSheet,
        currentSheet,
        setCurrentSheet,
        clusters,
        setClusters,
        ub,
        setUb,
        lb,
        setLb
      }}
    >
      {props.children}
    </AnalysisContext.Provider>
  );
};

export function useAnalysis() {
  return useContext(AnalysisContext);
}
