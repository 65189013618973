import { createContext, useContext, useState } from 'react';

const FileContext = createContext(
  {} as {
    filePath: string;
    setFilePath: React.Dispatch<React.SetStateAction<string>>;
    fileName: string;
    setFileName: React.Dispatch<React.SetStateAction<string>>;
    currentFile: any;
    setCurrentFile: React.Dispatch<React.SetStateAction<any>>;
    acceptedFiles: any;
    setAcceptedFiles: React.Dispatch<React.SetStateAction<any>>;
  }
);

export const FileContextProvider = (props: { children: React.ReactNode }) => {
  const [filePath, setFilePath] = useState('');
  const [fileName, setFileName] = useState('');
  const [currentFile, setCurrentFile] = useState('');
  const [acceptedFiles, setAcceptedFiles] = useState([]);

  return (
    <FileContext.Provider
      value={{
        filePath,
        setFilePath,
        fileName,
        setFileName,
        currentFile,
        setCurrentFile,
        acceptedFiles,
        setAcceptedFiles
      }}
    >
      {props.children}
    </FileContext.Provider>
  );
};

export function useFile() {
  return useContext(FileContext);
}
