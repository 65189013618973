import { Suspense } from 'react';
import { Navigate, Outlet } from 'react-router-dom';

// import { Spinner } from '@/components/elements';
import Grid from '@mui/material/Grid';
import { MainLayout } from '@/components/layout';
import { lazyImport } from '@/utils/lazyImport';

const { WordClouds } = lazyImport(
  () => import('@/features/wordcloud'),
  'WordClouds'
);
const { SentimentsOverTime } = lazyImport(
  () => import('@/features/graph'),
  'SentimentsOverTime'
);
const { Comments } = lazyImport(
  () => import('@/features/comments'),
  'Comments'
);
const { CommentDetails } = lazyImport(
  () => import('@/features/comments'),
  'CommentDetails'
);
const { Dashboard } = lazyImport(
  () => import('@/features/dashboard'),
  'Dashboard'
);

const App = () => {
  return (
    <MainLayout>
      <Suspense
        fallback={
          <Grid
            container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
            style={{ minHeight: '100vh' }}
          >
            <Grid item xs={3}>
              {/* <Spinner size="xl" /> */}
            </Grid>
          </Grid>
        }
      >
        <Outlet />
      </Suspense>
    </MainLayout>
  );
};

export const protectedRoutes = [
  {
    path: '/app',
    element: <App />,
    children: [
      { path: '/app/dashboard', element: <Dashboard /> },
      {
        path: '/app/positive_comments',
        element: (
          <Comments
            width={12}
            filter={'positive'}
            type={'ポジティブなコメント'}
          />
        )
      },
      {
        path: '/app/negative_comments',
        element: (
          <Comments
            width={12}
            filter={'negative'}
            type={'ネガティブなコメント'}
          />
        )
      },
      {
        path: '/app/request_comments',
        element: (
          <Comments width={12} filter={'request'} type={'要望を含むコメント'} />
        )
      },
      {
        path: '/app/normal_comments',
        element: (
          <Comments width={12} filter={'normal'} type={'よくあるコメント'} />
        )
      },
      {
        path: '/app/rare_comments',
        element: <Comments width={12} filter={'rare'} type={'珍しいコメント'} />
      },
      {
        path: '/app/comment_stats',
        element: <CommentDetails />
      },
      { path: '/app/wordcloud', element: <WordClouds width={12} /> },
      { path: '/app/timeseries', element: <SentimentsOverTime width={12} /> },
      { path: '/app/*', element: <Navigate to="/app/dashboard" /> }
    ]
  }
];
