import Grid from '@mui/material/Grid';
import { SideNavigator, Header } from '@/components/layout';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import './Main.css';

type MainLayoutProps = {
  children: React.ReactNode;
};

export const MainLayout = ({ children }: MainLayoutProps) => {
  console.log('MainLayout is rendered');
  return (
    <Grid container sx={{ minWidth: '1280px' }}>
      <Grid item xs={12}>
        <Header />
        <Toolbar />
      </Grid>
      <Grid
        item
        style={{ width: '320px' }}
        sx={{
          borderRight: 1,
          borderColor: 'rgba(240,240,240,1)',
          height: '100vh'
        }}
      >
        <SideNavigator />
      </Grid>
      <Grid
        item
        xs
        sx={{
          backgroundColor: 'rgba(245, 247, 250, 1)'
        }}
      >
        <Box m={2}>
          <Grid container spacing={2}>
            {children}
          </Grid>
        </Box>
      </Grid>
    </Grid>
  );
};
