import { createContext, useContext, useState } from 'react';

const DataContext = createContext(
  {} as {
    data: any;
    setData: React.Dispatch<React.SetStateAction<any>>;
    columns: any;
    setColumns: React.Dispatch<React.SetStateAction<any>>;
    xColumns: any;
    setXColumns: React.Dispatch<React.SetStateAction<any>>;
    currentColumn: string;
    setCurrentColumn: React.Dispatch<React.SetStateAction<string>>;
  }
);

export const DataContextProvider = (props: { children: React.ReactNode }) => {
  const [data, setData] = useState();
  const [columns, setColumns] = useState();
  const [xColumns, setXColumns] = useState();
  const [currentColumn, setCurrentColumn] = useState('');
  return (
    <DataContext.Provider
      value={{
        data,
        setData,
        columns,
        setColumns,
        xColumns,
        setXColumns,
        currentColumn,
        setCurrentColumn
      }}
    >
      {props.children}
    </DataContext.Provider>
  );
};

export function useData() {
  return useContext(DataContext);
}
