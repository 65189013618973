import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { Link } from 'react-router-dom';

export const Cta = () => {
  return (
    <Box
      sx={{
        // bgcolor: 'background.paper',
        pt: 3,
        pb: 6
        // backgroundColor: 'grey'
      }}
    >
      <Typography
        component="h1"
        variant="h4"
        align="center"
        color="text.primary"
        gutterBottom
      >
        <b>CommentScopeを使ってみませんか？</b>
      </Typography>
      <br />
      <Container maxWidth="sm">
        <Typography
          variant="h6"
          align="center"
          color="text.secondary"
          paragraph
        >
          登録不要で料金はかかりません。
        </Typography>
        <Stack
          sx={{ pt: 2 }}
          direction="row"
          spacing={2}
          justifyContent="center"
        >
          <Button
            component={Link}
            to={'/app/dashboard'}
            size="large"
            variant="contained"
            color="warning"
            sx={{ borderRadius: 20, height: 50, width: 250, fontSize: 18 }}
            disableElevation
          >
            使ってみる
          </Button>
        </Stack>
        <Box m={8} />
      </Container>
    </Box>
  );
};
