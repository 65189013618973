export class CountVectorizer {
  vectorizer: any;
  bow: any;
  dict: any;
  voc: any;

  constructor() {
    this.vectorizer = require('./bow');
    this.bow = this.vectorizer.bow;
    this.dict = this.vectorizer.dict;
  }

  async fit(texts: any) {
    this.voc = this.dict(texts);
  }

  async predict(texts: any) {
    const result = texts.map((text: any) => this.bow(text, this.voc));
    return result;
  }
}
