import { createContext, useContext, useState } from 'react';

const DateContext = createContext(
  {} as {
    currentDateColumn: string;
    setCurrentDateColumn: React.Dispatch<React.SetStateAction<string>>;
  }
);

export const DateContextProvider = (props: { children: React.ReactNode }) => {
  const [currentDateColumn, setCurrentDateColumn] = useState('');
  return (
    <DateContext.Provider
      value={{
        currentDateColumn,
        setCurrentDateColumn
      }}
    >
      {props.children}
    </DateContext.Provider>
  );
};

export function useDate() {
  return useContext(DateContext);
}
