import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

export const EmptyPlaceHolder = (props: any) => {
  console.log('EmptyPlaceHolder is rendered');
  return (
    <Grid item xs={12}>
      <Box m={6} />
      <center>
        <Typography variant="subtitle1">データがありません</Typography>
      </center>
    </Grid>
  );
};
