export const iconColor: string = 'rgba(179,187,196,1)';
export const labelColor: string = 'rgba(121,121,121,1)';
export const veryLightSuccessColor: string = 'rgba(234,247,239,1)';
export const veryLightWarningColor: string = 'rgba(254,247,231,1)';
export const veryLightErrorColor: string = 'rgba(253,244,244,1)';
export const veryLightPrimaryColor: string = 'rgba(245,250,254,1)';
export const lightSuccessColor: string = 'rgba(213,238,223,1)';
export const lightWarningColor: string = 'rgba(250,235,201,1)';
export const lightErrorColor: string = 'rgba(248,230,231,1)';
export const lightPrimaryColor: string = 'rgba(216,234,253,1)';
