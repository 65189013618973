const sentimentWords: any = {
  '２，３日': 0,
  '10%': 0,
  '100%': 0,
  '25%': 0,
  '80%': 0,
  '１０カ月': 0,
  '１０時間': 0,
  '１０数年': 0,
  '１０年以上': 0,
  '１０年近く': 0,
  '１１ヶ月': 0,
  '１１年': 0,
  '１２年': 0,
  '１３年': 0,
  '１４年': 0,
  '１５秒': 0,
  '１６年': 0,
  '１７年': 0,
  '１９年': 0,
  '１バイト': 0,
  '１位': 1,
  '１割': 0,
  '１時間以上': 0,
  '１時間以内': 0,
  '１時間半': 0,
  '１周年記念': 1,
  '１週間以上': 0,
  '１勝': 1,
  '１体': 0,
  '１通': 0,
  '１日分': 0,
  '１年近く': 0,
  '１番': 1,
  '１品': 0,
  '１文': 0,
  '２０年以上': 0,
  '２０年近く': 0,
  '２３時間': 0,
  '２か月': 0,
  '２ヵ月': 0,
  '２位': 0,
  '２階席': 0,
  '２件目': 0,
  '２時間': 0,
  '２時間以上': 0,
  '２時間半': 0,
  '２次感染': -1,
  '２失点': -1,
  '２勝１敗': 0,
  '２択': 0,
  '２日': 0,
  '２年近く': 0,
  '２年半': 0,
  '３０分位': 0,
  '３０分程度': 0,
  '３４年': 0,
  '３５年': 0,
  '３９歳': 0,
  '３Ｒ': 0,
  '３か月': 0,
  '３タテ': 0,
  '３チーム': 0,
  '３位': 0,
  '３回連続': 0,
  '３割負担': 0,
  '３週間': 0,
  '３酔い': 0,
  '３連打': 0,
  '３連敗': -1,
  '４か月': 0,
  '４回目': 0,
  '４時間': 0,
  '４日': 0,
  '４年': 0,
  '５０日': 0,
  '５０年以上': 0,
  '６００円': 0,
  '６年': 0,
  '７ヶ月': 0,
  '７年': 0,
  '７番': 0,
  '８カ月': 0,
  '８ヶ月': 0,
  '８割': 0,
  '９ヶ月': 0,
  '９年': 0,
  '９番': 0,
  ＡＤ: 0,
  ＡＩＤＳ: -1,
  ＡＴフィールド: 1,
  Ａｍａｚｏｎ: 0,
  Ａ型肝炎: -1,
  ＢＢＣ: 0,
  ＢＩＧ: 1,
  ＢＯＴ: -1,
  ＢＳＥ: -1,
  ＣＤ: 0,
  ＣＮＮ: 0,
  Ｃ型肝炎: -1,
  ＤＶ: -1,
  ＤＶＤ化: 0,
  ＤｏＳ: 0,
  ＥＤ: -1,
  ＥＳ: 0,
  ＦＡＱ: 0,
  ＨＩＶ: -1,
  ＨＩＶ感染: -1,
  ＨＰ: 0,
  ＨＴＭＬ: 0,
  ＩＴ化: 0,
  ＩＴ投資: 0,
  ＫＯ: 1,
  ＭＲＳＡ: 0,
  ＮＧ: -1,
  ＯＫ: 0,
  ＯＬ: 0,
  ＯＰＡＣ: 0,
  ＰＭＳ: -1,
  ＰＲ: 0,
  ＰＴＳＤ: -1,
  'Ｑ＆Ａ': 0,
  ＲＡＭ: 0,
  ＲＦＣ: 0,
  ＲＳＳ: 0,
  ＳＡＲＳ: -1,
  ＳＥ: 0,
  ＳＰＡＭ: 0,
  ＳＱＬ: 0,
  ＳＳ: 0,
  ＴＫＯ: -1,
  Ｖｅｃｔｏｒ: 0,
  ＷＷＥ: 0,
  Ｗｅｂ検索: 0,
  Ｗｉｎｎｙ: 0,
  ｃｏｒｅ: 0,
  ｅｎｄ: 0,
  ｈｔｍｌ: 0,
  ｈｔｔｐ: 0,
  ｔｘｔ: 0,
  あいさつ: 0,
  あいだ: 0,
  あいつら: 0,
  あいまい: -1,
  あいまいさ: 0,
  あえぎ: 0,
  あかし: 0,
  あからさま: -1,
  あきらめ: -1,
  あく: 0,
  あく抜け: 1,
  あこがれ: 1,
  あご: 0,
  あざやか: 1,
  あし: 0,
  あせも: -1,
  あたしたち: 0,
  あたたかさ: 1,
  あだ: -1,
  あだおろそか: -1,
  'あだおろそか・あだやおろそか': -1,
  あだやおろそか: -1,
  あちら: 0,
  あっさり: 0,
  あっし: 0,
  あっぱれ: 1,
  あでやか: 1,
  あどけなさ: 1,
  あなたがた: 0,
  あなたらしさ: 1,
  ありがたさ: 1,
  ありがたみ: 1,
  ありがた迷惑: -1,
  ありのまま: 0,
  あるがまま: 0,
  あわ: 0,
  あんばい: 0,
  いい加減: 0,
  いい気持ち: 1,
  'いい気持ち／気分': 1,
  いい気分: 1,
  いかがわしさ: -1,
  いかさま: -1,
  いさかい: -1,
  いざこざ: -1,
  いざとい: 0,
  いじめ: -1,
  いじらしさ: 1,
  いたずら: -1,
  いたずら電話: -1,
  いたちごっこ: 0,
  いたみ: -1,
  いたわり: 1,
  いっさい: 0,
  いっぱい: 0,
  いとしさ: 1,
  いのち: 1,
  いばらの道: -1,
  いびき: -1,
  いびり: -1,
  いぼ: -1,
  いやおう: 0,
  いやらしさ: -1,
  いらいら: -1,
  いらだち: -1,
  いろんなの: 0,
  いわれ: 0,
  うかうか: -1,
  うかつ: -1,
  うがい: 0,
  うさ: -1,
  うさんくささ: -1,
  うずき: -1,
  うそ: -1,
  うそっぱち: -1,
  うそ偽り: -1,
  うそ八百: -1,
  うたぐり: -1,
  うたた寝: 0,
  うだうだ: -1,
  うっかり: -1,
  うっちゃり: 0,
  うってつけ: 1,
  うっぷん: -1,
  うっ血: -1,
  うつ: -1,
  うつ状態: -1,
  うつ病: -1,
  うつ伏せ: 0,
  うなじ: 0,
  うねり: 0,
  うぶ: 0,
  うまさ: 1,
  うまみ: 1,
  うま味: 1,
  うらみ: -1,
  うらやみ: -1,
  うるささ: -1,
  うれしがらせ: 0,
  うれしさ: 1,
  うろ覚え: 0,
  うわさ: 0,
  えぐみ: -1,
  えびす顔: 0,
  えんま顔: 0,
  えん罪: -1,
  おいしさ: 1,
  おいてきぼり: -1,
  おいてけぼり: -1,
  おおまか: 0,
  おかげ: 1,
  おかしさ: 0,
  おかぼれ: 1,
  おかめ: 0,
  おけら: 0,
  おこづかい: 1,
  おざなり: -1,
  おしさ: -1,
  おしゃれ: 1,
  おじけ: -1,
  おせっかい: -1,
  おそれ: -1,
  おぞましさ: -1,
  おたふく: -1,
  おたふく風邪: -1,
  おだて: 0,
  おだぶつ: -1,
  おだやか: 1,
  おちおち: -1,
  おっくう: -1,
  おっとり: 0,
  おっぱい: 0,
  おでき: -1,
  おなら: -1,
  おねしょ: -1,
  おばけ: 0,
  おぼこ: 0,
  おまけ: 1,
  おまんこ: 0,
  おみやげ: 0,
  おむつ: 0,
  おめでた: 1,
  おもしろさ: 1,
  おもてなし: 0,
  おもらし: -1,
  おろそか: -1,
  おカネ: 1,
  おニュー: 1,
  お安い御用: 0,
  お陰: 1,
  お陰さま: 1,
  お陰様: 1,
  お隠れ: -1,
  お化け: -1,
  お家騒動: -1,
  お花: 0,
  お花畑: 0,
  お楽しみ: 1,
  お気持ち: 0,
  お客: 0,
  お客さま: 0,
  お客様: 1,
  お客様ご自身: 0,
  お給料: 1,
  お許し: 0,
  お近付き: 0,
  お金: 1,
  お決まり: 0,
  お見舞い: 0,
  お言葉: 0,
  お祭り気分: 1,
  お札: 0,
  お仕置き: -1,
  お使い: 0,
  お姉ちゃん: 0,
  お子さま: 0,
  お持ち: 0,
  お時間: 0,
  お邪魔: -1,
  お手ごろ価格: 1,
  お手頃価格: 1,
  お手上げ: -1,
  お手伝い: 0,
  お宿: 0,
  お祝い: 0,
  お出かけ: 0,
  お小遣い: 1,
  お笑い: 1,
  お上手: 1,
  お色気: 1,
  お人好し: 0,
  お人柄: 1,
  お世辞抜き: 0,
  お世話: 0,
  お説教: -1,
  お体: 0,
  お題目: 0,
  お知恵: 1,
  お定まり: 0,
  お店: 0,
  お電話: 0,
  お土産: 0,
  お湯: 0,
  お答え: 0,
  お得: 1,
  お得感: 1,
  お年玉: 1,
  お疲れ: -1,
  お付き合い: 0,
  お布施: 1,
  お風邪: 0,
  お風呂場: 0,
  お別れ会: 0,
  お返し: 1,
  お返事: 0,
  お便り: 1,
  お墓: 0,
  お墨付き: 1,
  お面: 0,
  お門違い: -1,
  お役所仕事: -1,
  お友達: 0,
  お遊び: 0,
  お預け: 0,
  お力: 0,
  お力添え: 0,
  お礼: 0,
  お話: 0,
  お咎め: -1,
  お洒落: 1,
  かい性: 0,
  かかと: 0,
  かぎり: 0,
  かぎ裂き: -1,
  かげり: -1,
  かさ: 0,
  かさぶた: 0,
  かさ上げ: -1,
  かすり傷: -1,
  かぜ: -1,
  かた: 0,
  かたじけなさ: 0,
  かっこよさ: 1,
  かっぱらい: -1,
  かつら: 0,
  かなしみ: -1,
  かぶれ: -1,
  かもねぎ: 0,
  かゆさ: -1,
  かゆみ: -1,
  かわいさ: 1,
  かわいそう: -1,
  かわいらしさ: 1,
  がけ崩れ: 0,
  がた: -1,
  がた落ち: -1,
  がっしり: 0,
  がっちり: 0,
  がまん: 0,
  がらがら: 0,
  がん: -1,
  がん細胞: -1,
  きずな: 1,
  きっかけ: 0,
  きつさ: -1,
  きびしさ: 0,
  きまり: 0,
  きめ細かさ: 1,
  きゃしゃ: -1,
  きらびやか: 1,
  きりん児: 1,
  きれい: 1,
  きれい事: -1,
  きわまり: 0,
  ぎこちなさ: -1,
  ぎっくり腰: -1,
  ぎりぎり: -1,
  ぎんぎん: 0,
  くしゃみ: 0,
  くじ運: 1,
  くせ毛: 0,
  くそ力: 1,
  くたくた: -1,
  くたびれ: -1,
  くたびれもうけ: -1,
  くだらなさ: -1,
  くちびる: 0,
  くつろぎ: 1,
  くどさ: -1,
  くも膜下出血: -1,
  くやしさ: -1,
  くる病: -1,
  ぐうたら: 0,
  ぐちゃぐちゃ: -1,
  ぐりぐり: 0,
  けが: -1,
  けがれ: -1,
  けが人: -1,
  けだるさ: -1,
  けち: -1,
  けったい: -1,
  けなげ: 1,
  けり: 0,
  けんか: -1,
  けんか腰: -1,
  げき: 0,
  げた: 0,
  げんなり: -1,
  こいつら: 0,
  こく: 1,
  ここ: 0,
  ここら: 0,
  こそ泥: -1,
  こだわり: 0,
  こちこち: -1,
  このまま: 0,
  この世: 0,
  こび: -1,
  こまめ: 1,
  こらえ性: 1,
  こわさ: -1,
  こんとん: -1,
  ごあいさつ: 0,
  ごたごた: -1,
  ごたまぜ: -1,
  ごちゃまぜ: -1,
  ごまかし: -1,
  ごまのはえ: -1,
  ごみ: -1,
  ごみ減量: 0,
  ごろごろ: 0,
  ご愛顧: 0,
  ご挨拶: 0,
  ご案内: 0,
  ご意見ご要望: 0,
  ご縁: 1,
  ご家庭: 0,
  ご活躍: 1,
  ご感想: 0,
  ご希望: 0,
  ご機嫌: 1,
  ご協力: 0,
  ご教示: 0,
  ご興味: 1,
  ご苦労: -1,
  ご好評: 1,
  ご講演: 0,
  ご賛同: 0,
  ご指示: 0,
  ご指導: 0,
  ご指導ご鞭撻: 0,
  ご支援: 0,
  ご支援ご協力: 0,
  ご支持: 0,
  ご主人様: 0,
  ご祝辞: 0,
  ご助言: 0,
  ご紹介: 0,
  ご心配: -1,
  ご尽力: 0,
  ご声援: 0,
  ご先祖様: 0,
  ご提案: 0,
  ご都合主義: -1,
  ご入金: 0,
  ご配慮: 0,
  ご病気: -1,
  ご不便: -1,
  ご返事: 0,
  ご鞭撻: 0,
  ご褒美: 1,
  ご満足: 1,
  ご迷惑: -1,
  ご予約: 0,
  ご要望: 0,
  ご利益: -1,
  ご了解: 0,
  ご了承: 0,
  ご連絡: 0,
  さっぱり: 1,
  さばさば: 1,
  さび: -1,
  さびしさ: -1,
  さみしさ: -1,
  さら: 0,
  さわやか: 1,
  さわやかさ: 1,
  ざらつき: -1,
  ざわめき: -1,
  しあわせ: 1,
  しがらみ: -1,
  しくじり: -1,
  しこめ: -1,
  しこり: -1,
  したい放題: -1,
  したたか: -1,
  しっかり: 1,
  しっぺ返し: -1,
  しつこさ: -1,
  しとやか: 1,
  しなやか: 1,
  しなやかさ: 1,
  しびれ: -1,
  しみ: -1,
  しゃがれ声: 0,
  しゃっくり: -1,
  しゃれ男: 1,
  しゃんと: 0,
  しょっぱさ: -1,
  しらふ: -1,
  しり押し: 0,
  しり切れ: -1,
  しるし: 0,
  しるべ: 0,
  しわ: -1,
  しわ寄せ: -1,
  しんどさ: -1,
  し損ない: -1,
  じかん: 0,
  じっくり: 0,
  じゅもん: 0,
  じり高: -1,
  じり貧: -1,
  すうっと: 0,
  すかっと: 1,
  すかんぴん: -1,
  すきだらけ: -1,
  すきっと: 1,
  すさまじさ: 0,
  すっきり: 1,
  すったもんだ: -1,
  すってんてん: -1,
  すっと: 1,
  すっぽかし: -1,
  すてき: 1,
  すばやさ: 1,
  すばらしさ: 1,
  すみれ: 0,
  すり: -1,
  ずぶ濡れ: -1,
  ずぼら: -1,
  ずる: -1,
  ずれ: 0,
  せかせか: -1,
  せき: -1,
  せき払い: -1,
  せせら笑い: -1,
  せつなさ: -1,
  せり: 0,
  せり上げ: 0,
  ぜんそく: -1,
  ぜん息: -1,
  そいつ: 0,
  そいつら: 0,
  そういうの: 0,
  そしり: -1,
  そそくさ: 0,
  そっと: 0,
  そつ: 0,
  そねみ: -1,
  その間: 0,
  その場しのぎ: -1,
  その場逃れ: -1,
  その他: 0,
  その道: 0,
  そり: 0,
  そり味: 0,
  それきり: -1,
  'それきり・それっきり': -1,
  それっきり: -1,
  それなり: 1,
  ぞうり: 0,
  たおやか: 1,
  たくさん: 0,
  たくましさ: 1,
  たしなみ: 1,
  たたずまい: 1,
  ただれ: -1,
  ただれ目: -1,
  ただ事: 0,
  ただ者: 0,
  たっぷり: 1,
  たね: 0,
  ため: 1,
  ためらい傷: -1,
  ため息: -1,
  たらい回し: -1,
  たるみ: -1,
  たわし: 0,
  だみ声: -1,
  だらしなさ: -1,
  だらだら: -1,
  だらっと: -1,
  だらりと: -1,
  だらんと: -1,
  だるさ: -1,
  だれ気味: -1,
  ちゃめっ気: 1,
  ちゃらんぽらん: -1,
  ちょい役: -1,
  ちょっかい: -1,
  ちょんぼ: -1,
  つき: 0,
  つぐみ: 0,
  つじ強盗: -1,
  つっけんどん: -1,
  つつましやか: 1,
  つながり: 1,
  つばさ: 0,
  つまずき: -1,
  つまらなさ: -1,
  つまり: 0,
  つもり: 0,
  つや: 1,
  つらさ: -1,
  つわもの: 1,
  つわり: -1,
  ていねい: 1,
  てんかん: -1,
  てんこ盛り: 1,
  てんやわんや: -1,
  できもの: -1,
  でこぼこ: -1,
  でたらめ: -1,
  でっち上げ: -1,
  でんぷん: 0,
  というわけ: 0,
  とがめ: -1,
  とがめ立て: 0,
  とがり声: -1,
  ときめき: 1,
  とどめ: -1,
  とばっちり: -1,
  とまどい: -1,
  ともえ投げ: 0,
  どきどき: 0,
  どぎまぎ: -1,
  どさくさ: -1,
  どたキャン: -1,
  どら声: -1,
  どんぐり: 0,
  どんでん返し: -1,
  どん底: -1,
  ど根性: 1,
  なくて七癖: 0,
  なぞ: 0,
  なだらか: 1,
  なつかしさ: 1,
  なぶり殺し: -1,
  なまくら: -1,
  なまず: 0,
  なめらか: 1,
  なめらかさ: 1,
  ならず者: -1,
  におい: 0,
  にきび: -1,
  にぎやか: 1,
  にぎわい: 1,
  にこにこ顔: 1,
  ぬかるみ: -1,
  ぬか喜び: -1,
  ぬくもり: 1,
  ぬめり: -1,
  ぬるさ: 0,
  ねぎらい: 1,
  ねじれ: 0,
  ねたましさ: -1,
  ねたみ: -1,
  ねんね: 0,
  のぞみ: 1,
  のど自慢: 0,
  のど輪: 0,
  ののしり: -1,
  のみの心臓: -1,
  のらくら: -1,
  のろし: 0,
  のんべんだらり: -1,
  はげ: 0,
  はしか: -1,
  はずみ: -1,
  はたき込み: 0,
  はたけ: 0,
  はたらき: 1,
  はた迷惑: -1,
  はちゃめちゃ: -1,
  はっきり: 0,
  はつらつ: 1,
  はにかみ: 0,
  はまり役: 1,
  はやり風邪: -1,
  はやり目: -1,
  はらはら: -1,
  はれ: 0,
  はれ物: -1,
  ばい菌: -1,
  ばか: -1,
  ばかばかしさ: -1,
  ばか笑い: 0,
  ばか力: 1,
  ばつ: -1,
  ばらつき: -1,
  ばらばら: 0,
  ひがみ: -1,
  ひき逃げ: -1,
  ひさし: 0,
  ひさしぶり: 1,
  ひじ: 0,
  ひじり: 0,
  ひったくり: -1,
  ひととき: 0,
  ひとりよがり: -1,
  ひとり相撲: -1,
  ひとり立ち: 1,
  ひと月: 0,
  ひと事: -1,
  ひと時: 0,
  ひどさ: -1,
  ひび割れ: -1,
  ひま: 0,
  ひもじさ: -1,
  ひょう疽: -1,
  ひょっとこ: -1,
  ひ弱: -1,
  びくびく: -1,
  びんた: -1,
  ぴか一: 1,
  ふがいなさ: -1,
  ふくよか: 1,
  ふさぎの虫: -1,
  ふしだら: -1,
  ふたり: 0,
  ふち: 0,
  ふるさと: 0,
  ふん: -1,
  ぶおとこ: -1,
  ぶおんな: -1,
  ぶきっちょ: -1,
  ぶす: -1,
  ぶっきらぼう: -1,
  ぶらぶら: 0,
  ぶり返し: 0,
  へそ: 0,
  へったくれ: -1,
  へっぽこ: -1,
  へなちょこ: -1,
  へのかっぱ: 0,
  へぼ: -1,
  へま: -1,
  へんちくりん: -1,
  へんてこ: -1,
  へんとう炎: -1,
  べたぼれ: 1,
  べた褒め: 1,
  べっぴん: 1,
  ぺしゃんこ: -1,
  ほお笑み: 0,
  ほか: 0,
  ほくほく顔: 1,
  ほこり: -1,
  ほころび: -1,
  ほっぺた: 0,
  ほとぼり: -1,
  ほとんど: 0,
  ほの字: 1,
  ほほ笑み: 1,
  ほら貝: 0,
  ほり: 0,
  ほろ酔い気分: 1,
  ほんま: 0,
  ぼくたち: 0,
  ぼけ: -1,
  ぽか: -1,
  まくら探し: 0,
  まぐれ: 0,
  まごころ: 1,
  まじめ: 1,
  まじり: -1,
  まずさ: -1,
  まぜこぜ: 0,
  まちがい: -1,
  まちづくり: 0,
  まっさら: 1,
  まとも: 1,
  まどらか: 1,
  まどろみ: 1,
  まね: 0,
  まめ: 1,
  まやかし: -1,
  まゆつば物: -1,
  まるまる: 0,
  まれ: 0,
  まろやか: 1,
  まんざら: 0,
  まんま: 0,
  まん延: -1,
  みずから: 0,
  みずみずしさ: 1,
  みみずばれ: -1,
  むくみ: -1,
  むし歯: -1,
  むずかしさ: -1,
  むだ: -1,
  むちゃくちゃ: -1,
  むち打ち: -1,
  むなしさ: -1,
  むやみ: -1,
  むやみやたら: -1,
  めぐり: 0,
  めし: 0,
  めちゃ: -1,
  めちゃくちゃ: -1,
  めちゃめちゃ: -1,
  めったやたら: -1,
  めった打ち: 0,
  めでたさ: 1,
  めど: 0,
  めまい: -1,
  めんどう: -1,
  もうけ口: 1,
  もうけ仕事: 1,
  もうろく: -1,
  もだえ: -1,
  もちつき: 0,
  もてなし: 0,
  もどかしさ: -1,
  ものぐさ: -1,
  ものづくり: 0,
  もの悲しさ: -1,
  もみ: 0,
  もめ事: -1,
  もやもや: -1,
  もろさ: -1,
  もろ差し: 0,
  やくざ: -1,
  やけ: -1,
  やけくそ: -1,
  やけっぱち: -1,
  やけっ腹: -1,
  やけど: -1,
  やさしさ: 1,
  やたけ心: 0,
  やたら: 0,
  やっつけ仕事: -1,
  やつら: 0,
  やぶ蛇: -1,
  やみくも: 0,
  やりかた: 0,
  やりがい: 1,
  やりきれなさ: -1,
  やりたい放題: -1,
  やり過ぎ: -1,
  やり直し: 0,
  やるせなさ: -1,
  やる気: 1,
  やわらかさ: 1,
  やんちゃ: 0,
  ゆがみ: -1,
  ゆとり: 1,
  ゆるやか: 0,
  よがり声: 0,
  よくよく: 0,
  よこしま: -1,
  よさ: 1,
  よしみ: 0,
  よそ事: -1,
  よそ者: -1,
  よどみ: -1,
  よりどころ: 0,
  よろめき: 0,
  らい: -1,
  らしさ: 1,
  ろくすっぽ: -1,
  ろくろく: -1,
  わいせつ行為: -1,
  わかりやすさ: 1,
  わがまま: -1,
  わき: 0,
  わき腹: 0,
  わき役: -1,
  わざ: 1,
  わずらわしさ: -1,
  わだかまり: -1,
  わび: 1,
  わびしさ: 0,
  わらじ銭: 0,
  アーカイブ: 0,
  アーチ: 0,
  アーチスト: 0,
  'アーチスト・アーティスト': 0,
  アーティスト: 0,
  アイス: 0,
  アイスホッケー: 0,
  アイデア: 1,
  アイディア: 1,
  アイデンティティ: 1,
  アイデンティティー: 1,
  アイドリングストップ: 0,
  アイマスク: 0,
  アイルランド: 0,
  アウト: -1,
  アウトコース: 0,
  アウトドア: 0,
  アウトライン: 0,
  アオコ: -1,
  アオリ: -1,
  アカ: -1,
  アクシデント: -1,
  アクセスログ: 0,
  アクセス数: 0,
  アクセス制御機能: 1,
  アクティブ: 1,
  アジ: 0,
  アセトアルデヒド: 0,
  アソコ: 0,
  アタシ: 0,
  アタック: 0,
  アタリ: 0,
  アットホーム: 1,
  アッパーカット: 0,
  アップ: 0,
  アップグレード: 1,
  アップダウン: 0,
  アデノイド: -1,
  アトピー: -1,
  アトピー性皮膚炎: -1,
  アド: 0,
  アドバイザー: 0,
  アドバイス: 0,
  アドレナリン: 0,
  アナクロ: -1,
  アバウト: -1,
  アパルトヘイト: -1,
  アピール: 0,
  アフガニスタン: 0,
  アフガン: 0,
  アフガン戦争: -1,
  アフターケア: 0,
  アフターサービス: 1,
  アブノーマル: -1,
  アホ: -1,
  アマ: 0,
  アマゾン: 0,
  アマチュア: 0,
  アミューズメント: 1,
  アミロイド: 0,
  アメリカ: 0,
  アユ: 0,
  アラート: 0,
  アラーム: 0,
  アラーム音: 0,
  アラビア: 0,
  アリバイ: 0,
  アルコール依存症: -1,
  アルコール中毒: -1,
  アルコール問題: 0,
  アルジェリア: 0,
  アルゼンチン: 0,
  アルツハイマー: -1,
  アルツハイマー病: -1,
  アル中: -1,
  アレルギー: -1,
  アレルギー疾患: -1,
  アレルギー性疾患: -1,
  アレルギー性鼻炎: -1,
  アレルギー体質: -1,
  アレルゲン: -1,
  アンダースロー: 0,
  アンチ: 0,
  アンチテーゼ: 0,
  アンティーク: 1,
  アンノウン: 0,
  アンバランス: -1,
  アンフェア: -1,
  イージー: 0,
  イージーミス: -1,
  イエスキリスト: 0,
  イエローカード: -1,
  イカサマ: -1,
  イギリス: 0,
  イジメ: -1,
  イスパニア: 0,
  イスラエル: 0,
  イタズラ: -1,
  イタリア: 0,
  イナダ: 0,
  イノシシ: 0,
  イビキ: -1,
  イベント: 0,
  イボ: -1,
  イマイチ: -1,
  イマジネーション: 1,
  イメージ: 0,
  イメージアップ: 1,
  イメージダウン: -1,
  イヤ: -1,
  イラク: 0,
  イラク攻撃: -1,
  イラク侵攻: -1,
  イラク戦争: -1,
  イラン: 0,
  インカ帝国: 0,
  インコース: 0,
  インストラクター: 0,
  インスピレーション: 1,
  インスリン分泌: 0,
  インセンティブ: 1,
  インターネットオークション: 0,
  インターネットサービス: 0,
  インターフォン: 0,
  インチキ: -1,
  インテリ: -1,
  インテリア: 0,
  インテリジェンス: 1,
  インド: 0,
  インドネシア: 0,
  インド映画: 0,
  インパクト: 1,
  インパルス: 0,
  'インフォームド・コンセント': 1,
  インフォームドコンセント: 1,
  インフォメーション: 0,
  インフラ: 0,
  インフルエンザ: -1,
  インフルエンザウイルス: -1,
  インフレ: -1,
  インフレ期待: 0,
  インポ: -1,
  インポテンツ: -1,
  イヴ: 0,
  ウィークポイント: -1,
  ウィルス: -1,
  ウィルスメール: -1,
  ウイークポイント: -1,
  ウイグル: 0,
  ウイット: 1,
  ウイルス: -1,
  ウイルスメール: -1,
  ウイルス感染: -1,
  ウイルス対策: 0,
  ウイルス病: -1,
  ウェールズ: 0,
  ウェブログ: 0,
  ウエイト: 0,
  ウエストボール: 0,
  ウォーミングアップ: 0,
  ウソ: -1,
  ウツ: -1,
  ウデ: 1,
  ウナギ: 0,
  ウルトラＣ: 1,
  ウワサ: -1,
  ウンチ: 0,
  エール: 1,
  エア: 0,
  エイズ: -1,
  エイリアン: 0,
  エキストラ: 0,
  エキスパート: 1,
  エクスタシー: 0,
  エコー: 0,
  エコロジー: 0,
  エゴ: -1,
  エゴイズム: -1,
  エサ取り: 0,
  エジプト: 0,
  エチュード: 0,
  エッジ: 0,
  エディタ: 0,
  エネルギー: 1,
  エネルギー不足: -1,
  エネルギッシュ: 1,
  エマージェンシー: -1,
  エラー: -1,
  エラーメッセージ: -1,
  エラーログ: -1,
  エリート: 1,
  エリート意識: -1,
  エルボー: -1,
  エルボースマッシュ: 0,
  エレガンス: 1,
  エレガント: 1,
  エレガントさ: 1,
  エロス: 0,
  エンジニア: 0,
  エンジンルーム: 0,
  エンスト: -1,
  エントランス: 0,
  エントリーシート: 0,
  オーストラリア: 0,
  オーソドックス: 0,
  オーディション: 0,
  オーバー: 0,
  オーバースロー: 0,
  オーバーフロー: -1,
  オーバーヘッド: 0,
  オーバーワーク: 0,
  オーバヘッド: 0,
  オープン: 0,
  オーラ: 1,
  オールマイティー: 1,
  オイルショック: -1,
  オイル漏れ: -1,
  オウム: 0,
  オオカミ: 0,
  オカルト: -1,
  オケ: 0,
  オシャレ: 1,
  オタク: 0,
  オデコ: 0,
  オフサイド: 0,
  オペ: 0,
  オペレーター: 0,
  オマージュ: 0,
  オマンコ: 0,
  オムツ: 0,
  オムニバス: 0,
  オランダ: 0,
  オリエンテーション: 0,
  オリジナリティ: 1,
  オリジナリティー: 1,
  オリジナル: 0,
  オルガン奏者: 0,
  オンナ: 0,
  オンラインショップ: 0,
  カーブ: 0,
  カウンセラー: 0,
  カウンセリング: 0,
  カウンターパンチ: -1,
  カオス: -1,
  カサカサ: -1,
  カサブタ: -1,
  カジュアル: 0,
  カジュアル感: 0,
  カス: -1,
  カゼ: -1,
  カタルシス: 1,
  カッコよさ: 1,
  カッコ良さ: 1,
  カッパ: 0,
  カツラ: 0,
  カド: 0,
  カナダ: 0,
  カビ: -1,
  カメムシ: 0,
  カメラマン: 0,
  カユミ: -1,
  カラ: 0,
  カラーコピー: 0,
  カラフル: 1,
  カリエス: -1,
  カリスマ: 1,
  カルシウム不足: -1,
  カルチャーショック: -1,
  カルマ: -1,
  カレッジ: 0,
  カン: 0,
  カンタン: 1,
  カンニング: -1,
  カンパ: 0,
  カンボジア: 0,
  ガーゴイル: -1,
  ガーデニング: 0,
  ガード: 0,
  ガードル: 0,
  ガイダンス: 0,
  ガイド: 0,
  ガイドライン: 0,
  ガシラ: 0,
  ガス: 0,
  ガス室: 0,
  ガス抜き: 0,
  ガス漏れ: -1,
  ガソリン代: -1,
  ガッツ: 1,
  ガレージキット: 0,
  ガン: -1,
  ガン細胞: -1,
  キーノート: 0,
  キープ: 0,
  キス: 0,
  キスシーン: 0,
  キズ: -1,
  キツイ言葉: -1,
  キャッシュバック: 1,
  キャッチ: 0,
  キャッチー: 1,
  キャッチボール: 0,
  キャッチャー: 0,
  キャパシティー: 0,
  キャラクタープレイ: 0,
  キャリア: 1,
  キャンセル: -1,
  キュート: 1,
  キューバ: 0,
  キリ: 0,
  キリスト: 0,
  キレ: 1,
  キレイ: 1,
  ギックリ腰: -1,
  ギブス: -1,
  ギャグ: -1,
  ギャップ: 0,
  ギャラ: 1,
  ギャング: -1,
  ギリシア: 0,
  クーデター: -1,
  クーリングオフ: 0,
  クール: 1,
  クールダウン: 0,
  クイズ: 0,
  クエン酸: 0,
  クォータリー: 0,
  クォリティー: 1,
  クオリティ: 1,
  クセ毛: 0,
  クツ: 0,
  クビ: -1,
  クモ: 0,
  クモ膜下出血: -1,
  クラクション: 0,
  クラシカル: 0,
  クラッシュ: -1,
  クラミジア: -1,
  クランケ: 0,
  クリーン: 1,
  クリア: 1,
  クリエーター: 0,
  クリエイター: 0,
  クリエイティビティ: 1,
  クリエイティブ: 1,
  クリケット: 0,
  クリスチャン: 0,
  クリスマス: 0,
  クリスマスイブ: 0,
  クリスマスソング: 0,
  クリスマスプレゼント: 1,
  クリスマス気分: 0,
  クリンチ: 0,
  クレーム: 0,
  'クロイツフェルト・ヤコブ病': -1,
  クロスカウンター: 0,
  クロッケー: 0,
  グチ: -1,
  グッズ: 0,
  グラマー: 1,
  グランドスラム: 1,
  グリップ: 0,
  グリップ力: 0,
  グルメ: 0,
  グレー: 0,
  グローバル: 0,
  ケーキ作り: 0,
  ケア: 0,
  ケアレスミス: -1,
  ケガ: -1,
  ケガレ: -1,
  ケガ人: -1,
  ケタ違い: 0,
  ケチ: -1,
  ケンカ: -1,
  ゲーム: 0,
  ゲーム感覚: 0,
  ゲーム性: 1,
  ゲスト: 0,
  ゲストブック: 0,
  ゲッツー: 0,
  ゲット: 1,
  ゲットー: -1,
  ゲリラ: -1,
  ゲロ: -1,
  コースアウト: -1,
  コーチ: 0,
  コーナーワーク: 0,
  コイン: 1,
  コウモリ: 0,
  コク: 1,
  ココア: 0,
  コスト: -1,
  コストアップ: -1,
  コストダウン: 0,
  コストパフォーマンス: 0,
  コスト高: -1,
  コスト面: -1,
  コップの中のあらし: -1,
  コツ: 1,
  コピペ: 0,
  コブラ: 0,
  コマ落ち: -1,
  コミニュケーション: 1,
  コミュニケーション: 1,
  コミュニケーションスキル: 1,
  コミュニケーション能力: 1,
  コミュニケーション力: 1,
  コミュニティビジネス: 0,
  コリ: -1,
  コレステロール: -1,
  コレラ: -1,
  コンサルティング: 0,
  コンセプト: 1,
  コンテンツビジネス: 0,
  コンディション: 1,
  コントロール: 0,
  コンパイルエラー: -1,
  コンパクト: 1,
  コンパクトさ: 1,
  コンビニエンス: 1,
  コンピューターウィルス: -1,
  コンピュータウイルス: -1,
  コンフリクト: -1,
  コンプライアンス: 1,
  コンプレックス: -1,
  ゴーサイン: 0,
  ゴースト: 0,
  ゴーレム: 0,
  ゴキブリ: -1,
  ゴジラ: -1,
  ゴブリン: -1,
  ゴミ: -1,
  ゴミ捨て場: 0,
  ゴロ: 0,
  ゴング: 0,
  サーチ: 0,
  サーバ: 0,
  サーバーエラー: -1,
  サーバーダウン: -1,
  サーバダウン: -1,
  サーバ側: 0,
  サービス: 1,
  サービス残業: -1,
  サービス水準: 1,
  サービス提供: 1,
  サービス量: 0,
  サイクルヒット: 0,
  サイドスロー: 0,
  サウジアラビア: 0,
  サウスポー: 0,
  サウンド: 0,
  サクセス: 1,
  サス: 0,
  サスペンス: -1,
  サタン: 0,
  サディズム: -1,
  サバイバル: 0,
  サポーター: 0,
  サポート: 0,
  サメ: 0,
  サヨナラ勝ち: 1,
  サラセン: 0,
  サラ金: -1,
  サルベージ: 0,
  サンダーバード: 0,
  サンダル: 0,
  ザーメン: 0,
  シアワセ: 1,
  シェア: 0,
  シェイプアップ: 1,
  システム: 0,
  システムリソース: 0,
  システム作り: 0,
  シック: 1,
  シックハウス: -1,
  シックハウス症候群: -1,
  シビア: -1,
  シフト: 0,
  シベリア: 0,
  シミ: -1,
  シミソバカス: -1,
  シャープ: 1,
  シャープさ: 1,
  シャウト: 0,
  シャム: 0,
  シュート: 0,
  ショート: 0,
  ショートカット: 0,
  ショック: -1,
  シラバス: 0,
  シロアリ: 0,
  シロクマ: 0,
  シワ: -1,
  シンガー: 0,
  シンクロ: 0,
  シンナー: -1,
  シンプル: 0,
  シンプルさ: 1,
  ジェットコースター: 0,
  ジェラシー: -1,
  ジェンダー: 0,
  ジハード: 0,
  ジフテリア: -1,
  ジャブ: 0,
  ジャマイカ: 0,
  ジャミング: -1,
  ジレンマ: -1,
  スイート: 0,
  スイス: 0,
  スイングアウト: -1,
  スウェーデン: 0,
  スカ: -1,
  スカパー: 0,
  スカンジナビア: 0,
  スキ: -1,
  スキー靴: 0,
  スキャンダル: -1,
  スキル: 1,
  スキルアップ: 1,
  スキンシップ: 0,
  スギ花粉症: -1,
  スクーリング: 0,
  スクウェア: 0,
  スケート: 0,
  スケール: 1,
  スケール感: 1,
  スケッチ: 0,
  スコア: 0,
  スコットランド: 0,
  スコンク: -1,
  スス: 0,
  スター: 1,
  スター選手: 1,
  スタイリッシュ: 1,
  スタミナ: 1,
  スタンドイン: 0,
  ステータス: 1,
  ステータス異常: -1,
  ステイタス: 1,
  ステキ: 1,
  ステッキ: 0,
  ステレオタイプ: -1,
  スト: -1,
  ストーカー: -1,
  ストーカー行為: -1,
  ストーカー被害: -1,
  ストイック: -1,
  ストップ: -1,
  ストライク: 0,
  ストレート: 0,
  ストレス: -1,
  ストレス解消: 1,
  ストレス発散: 1,
  スニーカー: 0,
  スノーシュー: 0,
  スパイ: -1,
  スパイ容疑: -1,
  スパム: 0,
  スパムメール: -1,
  スパルタ: 0,
  スピーディー: 1,
  スピード: 0,
  スピードアップ: 0,
  スピード感: 1,
  スピルバーグ: 0,
  スピロヘータ: 0,
  スプレー: 0,
  スプロール: 0,
  スペース: 0,
  スペースバー: 0,
  スペイン: 0,
  スペクタクル: 1,
  スペシャリスト: 1,
  スペシャル: 1,
  スペック: 1,
  スポーツ: 0,
  スポーツマンシップ: 1,
  スポーツ活動: 0,
  スポーツ障害: -1,
  スポーティー: 1,
  スマート: 1,
  スマートさ: 1,
  スマイル: 1,
  スムース: 1,
  スムーズ: 1,
  スモーク: 0,
  スモッグ: -1,
  スライダー: 0,
  スラム: -1,
  スラム街: -1,
  スランプ: -1,
  スリーアウト: 0,
  スリーストライク: 0,
  スリーバント: 0,
  スリップ: -1,
  スリップ事故: -1,
  スリム: 1,
  スリム化: 0,
  スリランカ: 0,
  スリル: 1,
  スループット: 0,
  スローライフ: 0,
  スロバキア: 0,
  ズレ: -1,
  セーフ: 1,
  セーフティー: 1,
  セーフティーバント: 0,
  セーブ: 0,
  セールス: 0,
  セールスポイント: 1,
  セイロン: 0,
  セキュリティ: 1,
  セキュリティー: 1,
  セキュリティホール: -1,
  セクシー: 1,
  セクシーさ: 1,
  セクシュアルハラスメント: -1,
  セクハラ: -1,
  セックスレス: 0,
  セットアップ: 0,
  セットポイント: 0,
  セミプロ: 1,
  セミプロフェッショナル: 1,
  セリエＡ: 0,
  セルフケア: 0,
  セルフコントロール: 0,
  セルライト: -1,
  センス: 1,
  センセーション: 0,
  センタリング: 0,
  ゼロ敗: 1,
  ソーダ: 0,
  ソッコー: 0,
  ソバカス: 0,
  ソフト: 1,
  ソフトボール: 0,
  ソフマップ: 0,
  ソプラノ: 0,
  ソラ: 0,
  ソリスト: 0,
  ソ連: 0,
  ゾンビ: -1,
  ターボ: 0,
  タイ: 0,
  タイツ: 0,
  タイトル数: 0,
  タイピング: 0,
  タイプミス: -1,
  タイムアウト: 0,
  タイムラグ: -1,
  タイムロス: -1,
  タコ: 0,
  タスク: 0,
  タックル: -1,
  タッグ: 0,
  タッチ: 0,
  タッチアウト: -1,
  タヌキ: 0,
  タネ: 0,
  タフ: 1,
  タフさ: 1,
  タブー: -1,
  タルミ: -1,
  タレント: 0,
  タワーレコード: 0,
  タンザニア: 0,
  ダイエット: 0,
  ダイオキシン: -1,
  ダイナミズム: 1,
  ダイナミック: 1,
  ダイナミックレンジ: 1,
  ダウン: -1,
  ダッシュ: 0,
  ダニ: -1,
  ダブり: -1,
  ダブルパンチ: -1,
  ダブルプレー: 0,
  ダミ声: -1,
  ダメ: -1,
  ダメージ: -1,
  ダメだし: -1,
  ダメ出し: -1,
  ダメ人間: -1,
  ダントツ: 1,
  ダンプ: 0,
  チープさ: -1,
  チームプレー: 0,
  チームワーク: 1,
  チェーンメール: -1,
  チェコ: 0,
  チェック機能: 1,
  チェリスト: 0,
  チェンジアップ: 0,
  チケット: 0,
  チヌ: 0,
  チフス: -1,
  チフス菌: 0,
  チベット: 0,
  チャームポイント: 1,
  チャイナ: 0,
  チャイム: 0,
  チャクラ: 0,
  チャリティー: 0,
  チャレンジ: 0,
  チャレンジ精神: 1,
  チャンス: 1,
  チャンピオン: 1,
  チョーク: 0,
  チョップ: -1,
  チリ: 0,
  チンピラ: -1,
  チンポ: 0,
  ツーアウト: 0,
  ツーダウン: 0,
  ツケ: -1,
  ツッコミ: 0,
  ツバ: 0,
  ツバメ: 0,
  ツブシ: 0,
  ツヤ: 1,
  ツレ: 0,
  ヅラ: -1,
  ティペット: 0,
  テイスト: 1,
  テキサスヒット: 1,
  テク: 1,
  テクニカルノックアウト: -1,
  テクニシャン: 1,
  テクニック: 1,
  テスト: -1,
  テナー: 0,
  テノール: 0,
  テレパシー: 0,
  テロ: -1,
  テロリスト: -1,
  テロリズム: -1,
  テロル: -1,
  テロ攻撃: -1,
  テロ行為: -1,
  テロ事件: -1,
  テンション: 1,
  データ: 0,
  データフォーマット: 0,
  デート: 1,
  デーモン: -1,
  ディスク容量: 1,
  ディベート: 0,
  デコピン: -1,
  デコボコ: -1,
  デザイナー: 0,
  デザイン画: 0,
  デザイン性: 1,
  デッドボール: -1,
  デッドロック: -1,
  デビル: 0,
  デフォルト値: 0,
  デフレ: -1,
  デフレ不況: -1,
  デブ: -1,
  デマ: -1,
  デマゴギー: -1,
  デメリット: -1,
  デリ: 0,
  デリカシー: 1,
  デリケート: 0,
  デンプン: 0,
  デンマーク: 0,
  トータル: 0,
  トーンダウン: -1,
  トイザラス: 0,
  トイレ休憩: 0,
  トスバッティング: 0,
  トップクラス: 1,
  トップライト: 0,
  トップランナー: 1,
  トマトジュース: 0,
  トライ: 1,
  トラウマ: -1,
  トラコーマ: -1,
  トラブル: -1,
  トラブル等: -1,
  トラホーム: -1,
  トランペッター: 0,
  トリプルプレー: 0,
  トルク: 1,
  トルコ: 0,
  ドア: 0,
  ドイツ: 0,
  ドキドキ: 0,
  ドキドキ感: 1,
  ドクターストップ: 0,
  ドジ: -1,
  ドス: 0,
  ドタキャン: -1,
  ドラえもん: 0,
  ドライアイ: -1,
  ドライバ: 0,
  ドラゴン: 0,
  ドラッグドロップ: 0,
  ドラッグバント: 0,
  ドラマティック: 1,
  ドル: 0,
  ドレスアップ: 1,
  ドロ: -1,
  ドロップ: -1,
  ドロップアウト: -1,
  ドンキホーテ型: 0,
  ナイーブ: 0,
  ナイス: 1,
  ナショナリズム: -1,
  ナチュラル: 0,
  ナミ: 0,
  ナミダ: 0,
  ナメクジ: 0,
  ナンセンス: -1,
  ナンパ: 0,
  ニーズ: 1,
  ニードロップ: 0,
  ニオイ: 0,
  ニキビ: -1,
  ニキビ跡: -1,
  ニコチン中毒: -1,
  ニュージーランド: 0,
  ニュートン力学: 0,
  ニュアンス: 1,
  ニンフ: 0,
  ヌメリ: -1,
  ネーム: 0,
  ネズミ: 0,
  ネタ: 0,
  ネタバレ: -1,
  ネタ切れ: -1,
  ネック: -1,
  ネットショップ: 0,
  ネット販売: 0,
  ノー: 0,
  ノーコントロール: -1,
  ノースモーキング: 0,
  ノーヒットノーラン: 1,
  ノーフィッシュ: -1,
  ノーベル賞: 1,
  ノーマル: 0,
  ノイズ: -1,
  ノイローゼ: -1,
  ノウハウ: 0,
  ノスタルジア: 0,
  ノスタルジック: 1,
  ノック: 0,
  ノックアウト: 'o',
  ノックダウン: -1,
  ノルウェー: 0,
  ノルマ: -1,
  ノンプロ: 0,
  ノンプロフェッショナル: 0,
  ハードボイルド: 1,
  ハードル: -1,
  ハード面: 0,
  ハーレム: 0,
  ハイウェイ: 0,
  ハイキック: 0,
  ハイキング気分: 0,
  ハイスピード: 1,
  ハイソックス: 0,
  ハイテンション: 0,
  ハイパワー: 1,
  ハイヒール: 0,
  ハゲ: 0,
  ハズレ: -1,
  ハゼ: 0,
  ハッピー: 1,
  ハネ: 0,
  ハネムーン: 1,
  ハプニング: 0,
  ハマリ: 0,
  ハムレット型: 0,
  ハリ: 1,
  ハレ: 0,
  ハングリー精神: 1,
  ハンセン病: -1,
  ハンダ付け: 0,
  ハンデ: -1,
  ハンディ: -1,
  ハンディー: 0,
  ハンディキャップ: -1,
  ハンデキャップ: -1,
  ハンド: 0,
  ハンマー: 0,
  バージョンアップ: 1,
  バーバリズム: -1,
  バーボン: 0,
  バイオマス: 0,
  バイオリズム: 0,
  バイオリニスト: 0,
  バイオレンス: -1,
  バイク事故: -1,
  バイタリティー: 1,
  バイプレーヤー: 0,
  バインド: 0,
  バカ: -1,
  バカバカしさ: -1,
  バクテリア: 0,
  バグ: -1,
  バグダッド: 0,
  バケモノ: -1,
  バスタイム: 0,
  バセドー病: -1,
  バチルス: 0,
  バックアップ: 0,
  バックドロップ: 0,
  バッシング: -1,
  バッティング: 0,
  バッファオーバーフロー: -1,
  バツ: -1,
  バナー: 0,
  バブル景気: 1,
  バラード: 0,
  バラツキ: -1,
  バランス感覚: 1,
  バリア: 0,
  バリアフリー: 0,
  バリアフリー化: 0,
  バリトン: 0,
  バンダイ: 0,
  バント: 0,
  バンパイア: -1,
  バンプ: -1,
  パーキンソン病: -1,
  パージ: -1,
  パーフェクト: 1,
  パーマ: 0,
  パイオニア: 1,
  パイルドライバー: 0,
  パイロット: 0,
  パクリ: -1,
  パケットロス: -1,
  パスミス: -1,
  パッション: 1,
  パッチ: 0,
  パトカー: 0,
  パナマ: 0,
  パニック: -1,
  パニック障害: -1,
  パフォーマンス: 0,
  パラドックス: -1,
  パラノイア: -1,
  パルプ: 0,
  パレスチナ: 0,
  パワー: 1,
  パワーダウン: -1,
  パワー不足: -1,
  パワフル: 1,
  パンク: -1,
  パンスト: 0,
  パンチ: -1,
  パンチラ: 0,
  パンチ力: 1,
  パンフレット: 0,
  パンプス: 0,
  パン作り: 0,
  ヒートシンク: 0,
  ヒール: -1,
  ヒーロー: 1,
  ヒステリー: -1,
  ヒスノイズ: -1,
  ヒット: 1,
  ヒットエンドラン: 0,
  ヒット曲: 1,
  ヒビ: -1,
  ヒポコンデリア: -1,
  ヒューマニティー: 0,
  ヒヨドリ: 0,
  ヒロイン: 0,
  ヒント: 1,
  ビギナー: 0,
  ビギナーズラック: 1,
  ビジネスシーン: 0,
  ビジネスチャンス: 0,
  ビジネスマン: 0,
  ビジョン: 0,
  ビス: 0,
  ビタミン不足: -1,
  ビックカメラ: 0,
  ビッグ: 1,
  ビッグチャンス: 1,
  ビフィズス菌: 0,
  ビブリオ: 0,
  ビルマ: 0,
  ビンゴ: 1,
  ビンタ: -1,
  ピーク: 0,
  ピアニスト: 0,
  ピクニック: 0,
  ピッキング: -1,
  ピッチング: 0,
  ピロリ菌: 0,
  ピン: 0,
  ピンぼけ: -1,
  ピンチ: -1,
  ファイアウォール: 0,
  ファイト: 1,
  ファウル: -1,
  ファジー: -1,
  ファッション: 0,
  ファッション性: 1,
  ファン: 1,
  ファンキー: 1,
  ファンダメンタルズ: 0,
  ファンド: 0,
  ファンブル: 0,
  フィードバック: 1,
  フィールダーズチョイス: 0,
  フィールディング: 0,
  フィット感: 0,
  フィラリア: -1,
  フィンランド: 0,
  フェア: 1,
  フェアプレー: 1,
  フェアリー: 0,
  フェミニン: 0,
  フェロモン: 1,
  フォークボール: 0,
  フォースアウト: 0,
  フォール: 0,
  フォアボール: 0,
  フォロー: 0,
  フクロウ: 0,
  フセイン大統領: 0,
  フック: 0,
  フットワーク: 1,
  フライ: 0,
  フラグ: 0,
  フラストレーション: -1,
  フランス: 0,
  フリーズ: -1,
  フリーター: -1,
  フルカウント: 0,
  フルスピード: 1,
  フルネーム: 0,
  フレームレート: 0,
  フレキシブル: 0,
  フレッシュ: 1,
  フレッシュさ: 1,
  フレンドリー: 1,
  フン: -1,
  ブーイング: -1,
  ブースト: 0,
  ブーム: 1,
  ブラ: 0,
  ブラジル: 0,
  ブラジル人: 0,
  ブラック: -1,
  ブラックホール: 0,
  ブランク: -1,
  ブランド: 0,
  ブランドイメージ: 1,
  ブランド志向: 0,
  ブランド品: 1,
  ブリザード: -1,
  ブルー: 0,
  ブレ: -1,
  ブレーカー: 0,
  ブレーキング: 0,
  ブレード: 0,
  ブレス: 0,
  プライド: 0,
  プライバシー: 1,
  プライバシー侵害: -1,
  プライバシー保護: 0,
  プラクティス: 0,
  プラス: 1,
  プラスアルファ: 1,
  プリンス: 1,
  プレー: 0,
  プレーヤー: 0,
  プレーン: 0,
  プレス: 0,
  プレゼンス: 0,
  プレゼント: 1,
  プレゼン資料: 0,
  プレッシャー: -1,
  プロ: 1,
  プログラミング: 0,
  プロット: 0,
  プロテクション: 0,
  プロテクト: 0,
  プロデューサー: 0,
  プロフェッサー: 0,
  プロフェッショナル: 1,
  プロ意識: 1,
  プロ野球: 0,
  ヘソ: 0,
  ヘタ: -1,
  ヘタレ: -1,
  ヘッドロック: 0,
  ヘドロ: -1,
  ヘビ: 0,
  ヘビー: 0,
  ヘマ: -1,
  ヘルシー: 1,
  ヘルニア: -1,
  ヘルプ: 0,
  ヘルペス: -1,
  ヘン: -1,
  ベースアップ: 1,
  ベースボール: 0,
  ベア: 0,
  ベスト: 1,
  ベスト１６: 1,
  ベストセラー: 1,
  ベタ: -1,
  ベテラン: 1,
  ベトナム: 0,
  ベトナム戦争: -1,
  ベネズエラ: 0,
  ベルギー: 0,
  ベンチャー: 0,
  ベンチャー企業: 0,
  ページ数: 0,
  ペーソス: -1,
  ペーパークラフト: 0,
  ペケ: -1,
  ペスト: -1,
  ペチコート: 0,
  ペット: 0,
  ペナルティ: -1,
  ペナルティー: -1,
  ペルー: 0,
  ペルシア: 0,
  ペンキ: 0,
  ペン入れ: 0,
  ホームシック: -1,
  ホームスチール: 0,
  ホームページ: 0,
  ホームラン: 0,
  ホイッスル: 0,
  ホコリ: -1,
  ホタルイカ: 0,
  ホッケー: 0,
  ホメオスタシス: 1,
  ホモ: -1,
  ボーイ: 0,
  ボーカリスト: 0,
  ボーク: -1,
  ボーズ: 0,
  ボーナス: 1,
  ボール: 0,
  ボウズ: 0,
  ボケ: -1,
  ボケ防止: 0,
  ボスキャラ: 0,
  ボタン: 0,
  ボツ: -1,
  ボディースラム: 0,
  ボディーブロー: -1,
  ボディブロー: -1,
  ボヤ: -1,
  ボラ: 0,
  ボランティア: 0,
  ボリューム: '?p?e',
  ボリューム感: 1,
  ボルテージ: 0,
  ボロボロ: -1,
  ポーランド: 0,
  ポイント: 1,
  ポイ捨て: -1,
  ポエジー: 0,
  ポジション争い: 0,
  ポップ: 1,
  ポテンシャル: 1,
  ポリープ: -1,
  ポリシー: 0,
  ポリ袋: 0,
  ポルトガル: 0,
  ポルノ: -1,
  ポロ: 0,
  マイナー: -1,
  マイナス: -1,
  マイナスイメージ: -1,
  マイナス点: -1,
  マイナス幅: -1,
  マイナス面: -1,
  マイナス要因: -1,
  マイペース: 1,
  マイルド: 1,
  マキシマム: 1,
  マグマ: -1,
  マシンパワー: 1,
  マジ: 0,
  マジレス: 0,
  マスカラ: 0,
  マゾヒズム: -1,
  マヂ: 0,
  マッチポイント: 0,
  マドリッド: 0,
  マドンナ: 1,
  マナー: 0,
  マニア: 0,
  マネ: -1,
  マネー: 1,
  マネジメント能力: 1,
  マヒ: -1,
  マフィア: -1,
  マラヤ連邦: 0,
  マラリア: -1,
  マレーシア: 0,
  マンネリ: -1,
  マンネリズム: -1,
  マンネリ化: -1,
  マンパワー: 1,
  ミート: 0,
  ミサイル: -1,
  ミス: -1,
  ミステーク: -1,
  ミステリー: 0,
  ミスマッチ: -1,
  ミニマム: -1,
  ミネラル: 1,
  ミャンマー: 0,
  ミュージシャン: 1,
  ミラクル: 1,
  ムード: 0,
  ムカデ: 0,
  ムダ: -1,
  ムチャ: -1,
  ムラ: -1,
  ムリ: -1,
  メーカー保証: 1,
  メイドさん: 0,
  メガヒット: 1,
  メキシコ: 0,
  メダル: 1,
  メッタ打ち: -1,
  メディアリテラシー: 1,
  メニュー: 0,
  メバル: 0,
  メモリー: 0,
  メモリリーク: -1,
  メモリ不足: -1,
  メランコリー: -1,
  メリット: 1,
  メリハリ: 1,
  メルマガ: 0,
  メンツ: 0,
  メンテ: 0,
  メンバーチェンジ: 0,
  モグラ: 0,
  モジュラーケーブル: 0,
  モダン: 1,
  モチベーション: 1,
  モチモチ感: 1,
  モデルチェンジ: 0,
  モノトーン: 0,
  モノマニア: -1,
  モラル: 1,
  モラルハザード: -1,
  モロッコ: 0,
  モンゴル: 0,
  モンスター: 0,
  ヤケ: -1,
  ヤニ: -1,
  ヤバさ: -1,
  ヤフオク: 0,
  ヤマメ: 0,
  ヤモリ: 0,
  ヤル気: 1,
  ユーザビリティ: 1,
  ユーロ: 0,
  ユダヤ人: 0,
  ユニークさ: 1,
  ライセンス: 0,
  ライトセーバー: 0,
  ライナー: 0,
  ライフ: 1,
  ライフサイクル: 0,
  ライフスタイル: 0,
  ライフセービング: 0,
  ライフワーク: 0,
  ラクロス: 0,
  ラグ: 0,
  ラストスパート: 0,
  ラッキー: 1,
  ラッシュ: -1,
  ラフ: 0,
  ラブ: 1,
  ラリアット: 0,
  リーズナブル: 1,
  リーダーシップ: 1,
  リアリズム: 1,
  リアリティ: 1,
  リアリティー: 0,
  リアル: 1,
  リアルさ: 0,
  リアルタイム性: 1,
  リウマチ: -1,
  リコール: -1,
  リサーチ: 0,
  リサイクル: 0,
  リスク: -1,
  リストラ: -1,
  リスナー: 0,
  リスペクト: 1,
  リズム感: 1,
  リセット: 0,
  リセットボタン: 0,
  リゾート: 1,
  リゾート気分: 1,
  リターンキー: 0,
  リタイア: 0,
  リッチ: 1,
  リテイク: 0,
  リテラシー: 1,
  リニューアル: 0,
  リハビリ: 0,
  リハビリテーション: 0,
  リバイバル: 0,
  リバウンド: -1,
  リフレッシュ: 1,
  リベンジ: 1,
  リミッター: 0,
  リモートアクセス: 0,
  リューマチ: -1,
  リューマチ熱: -1,
  リュウマチ: -1,
  リラクゼーション: 0,
  リラックス: 1,
  リラックスムード: 1,
  リラックス感: 1,
  リリース: 0,
  リリーフ: 0,
  リンク元: 0,
  リンク集: 0,
  リンク切れ: -1,
  リンク先: 0,
  リング上: 0,
  リンチ: -1,
  リンパ液: 0,
  リンパ球: 0,
  ルーズ: -1,
  ルーズソックス: 0,
  ルーツ: 0,
  ループ: -1,
  ルシード: 0,
  レーザー光線: 0,
  レーダー: 1,
  レート: 0,
  レイプ: -1,
  レクリエーション: 1,
  レジオネラ症: -1,
  レジスタンス: -1,
  レジャー: 1,
  レジュメ: 0,
  レスキュー: 1,
  レゾンデートル: 0,
  レッテル: -1,
  レッドパージ: -1,
  レトロ: 1,
  レパートリー: 1,
  レベルアップ: 1,
  レポート課題: 0,
  レリーフ: 0,
  レヴェル: 1,
  ローカルネタ: 0,
  ローカルルール: 0,
  ローファー: 0,
  ローマ帝国: 0,
  ローリング: 0,
  ロールプレイ: 0,
  ローン: -1,
  ロゴマーク: 0,
  ロシア: 0,
  ロシア語: 0,
  ロス: -1,
  ロフト: 0,
  ロマン: 1,
  ロマンス: 1,
  ロマンティック: 1,
  ワーク: 0,
  ワースト: -1,
  ワーム: -1,
  ワイルドさ: 1,
  ワイルドピッチ: -1,
  ワイン造り: 0,
  ワガママ: -1,
  ワキ: 0,
  ワキガ: -1,
  ワキヅレ: 0,
  ワナ: -1,
  ワラジ: 0,
  ワンクッション: 0,
  ワンダフル: 1,
  ワンナウト: 0,
  ワンパターン: -1,
  ワンポイントリリーフ: 0,
  ヲタ: 0,
  ヴィジョン: 1,
  亜聖: 1,
  哀しさ: -1,
  哀しみ: -1,
  哀れ: -1,
  哀れさ: -1,
  哀れみ: -1,
  哀れむべき: -1,
  哀楽: 0,
  哀感: -1,
  哀歓: -1,
  哀愁: 0,
  哀傷: -1,
  哀切: -1,
  哀調: -1,
  愛: 1,
  愛くるしさ: 1,
  愛らしさ: 1,
  愛郷: 1,
  愛顧: 0,
  愛護: 0,
  愛国心: 1,
  愛妻: 1,
  愛情: 1,
  愛想笑い: -1,
  愛想尽かし: -1,
  愛憎: -1,
  愛着: 1,
  愛別離苦: -1,
  愛欲: 0,
  愛憐: 1,
  愛嬌: 1,
  挨拶: 0,
  挨拶回り: 0,
  逢瀬: 1,
  悪: -1,
  悪さ: -1,
  悪意: -1,
  悪因悪果: 0,
  悪運: -1,
  悪影響: -1,
  悪疫: -1,
  悪化: -1,
  悪寒: -1,
  悪感情: -1,
  悪漢: -1,
  悪気: -1,
  悪鬼: -1,
  悪戯: -1,
  悪逆: -1,
  悪業: -1,
  悪玉: -1,
  悪玉コレステロール: -1,
  悪玉菌: -1,
  悪口: -1,
  悪口雑言: -1,
  悪巧み: -1,
  悪行: -1,
  悪事: -1,
  悪疾: -1,
  悪質: -1,
  悪者: -1,
  悪趣: -1,
  悪趣味: -1,
  悪習: -1,
  悪習慣: -1,
  悪臭: -1,
  悪循環: -1,
  悪女: -1,
  悪神: -1,
  悪人: -1,
  悪酔い: -1,
  悪性: -1,
  悪性リンパ腫: -1,
  悪性腫瘍: -1,
  悪政: -1,
  悪声: -1,
  悪戦: 0,
  悪戦苦闘: -1,
  悪相: -1,
  悪態: -1,
  悪代官: -1,
  悪知恵: -1,
  悪党: -1,
  悪道: -1,
  悪徳: -1,
  悪徳業者: -1,
  悪徳商法: -1,
  悪罵: -1,
  悪評: -1,
  悪病: -1,
  悪癖: -1,
  悪法: -1,
  悪魔: -1,
  悪夢: -1,
  悪名: -1,
  悪役: 0,
  悪霊: 0,
  握力: 1,
  圧勝: 1,
  圧制: -1,
  圧政: -1,
  圧倒: 0,
  圧倒的: 0,
  圧迫: 0,
  圧迫感: -1,
  圧伏: -1,
  圧服: -1,
  '圧服・圧伏': -1,
  姐さん: 0,
  飴: 1,
  安さ: 1,
  安っぽさ: -1,
  安め: 0,
  安らか: 1,
  安らかさ: 1,
  安らぎ: 1,
  安易: -1,
  安逸: -1,
  安穏: 1,
  安価: 1,
  安楽: 1,
  安楽死: -1,
  安手: 0,
  安上がり: 1,
  安心: 1,
  安心安全: 1,
  安心感: 1,
  安請け合い: -1,
  安全: 1,
  安全安心: 1,
  安全運転: 0,
  安全確保: 0,
  安全管理: 0,
  安全性: 1,
  安全対策: 0,
  安全第一: 1,
  安全保障: 0,
  安息: 1,
  安打: 0,
  安泰: 1,
  安直: -1,
  安定: 1,
  安定化: 1,
  安定感: 1,
  安定供給: 0,
  安定性: 1,
  安堵: 1,
  安堵感: 1,
  安寧: 1,
  安眠: 1,
  暗さ: 0,
  暗闇: 0,
  暗雲: -1,
  暗号: 0,
  暗黒面: -1,
  暗殺: -1,
  暗示: 0,
  暗礁: -1,
  暗証: 0,
  暗中模索: -1,
  暗部: 0,
  暗躍: -1,
  案件: 0,
  案内所: 0,
  闇: 0,
  伊達: 1,
  位相: 0,
  依存: -1,
  依存症: -1,
  偉観: 1,
  偉業: 1,
  偉効: 1,
  偉才: 1,
  偉材: 1,
  '偉材・異材': 1,
  偉人: 1,
  偉大: 1,
  偉大さ: 1,
  偉物: 1,
  囲い: 0,
  囲み: 0,
  威圧: -1,
  威圧感: -1,
  威嚇: -1,
  威厳: 1,
  威信: 1,
  威容: 1,
  威力: 1,
  意外性: 0,
  意気: 1,
  意気込み: 1,
  意気消沈: -1,
  意気阻喪: -1,
  意気地: 0,
  意義: 1,
  意向: 0,
  意志: 1,
  意志疎通: 1,
  意思: 0,
  意思決定: 0,
  意思疎通: 1,
  意思統一: 1,
  意思能力: 1,
  意識: 1,
  意識障害: -1,
  意識不明: -1,
  意趣: 0,
  意匠: 1,
  意匠性: 1,
  意図: 0,
  意地: -1,
  意地悪: -1,
  意表: 0,
  意味: 1,
  意味合い: 0,
  意欲: 1,
  意力: 0,
  慰め: 1,
  慰安: 0,
  慰撫: 0,
  慰霊: 0,
  慰労: 0,
  慰藉: 0,
  易さ: 1,
  為替: 1,
  為替差損: -1,
  畏敬: 1,
  畏縮: -1,
  畏怖: -1,
  異音: -1,
  異化: 0,
  異観: -1,
  異義: -1,
  異議: -1,
  異議申し立て: 0,
  異空間: 0,
  異見: 0,
  異国情緒: 1,
  異材: 0,
  異質: -1,
  異臭: -1,
  異常: -1,
  異常さ: -1,
  異常プリオン: 0,
  異常行動: -1,
  異常事態: -1,
  異常心理: 0,
  異常性: -1,
  異常性欲: -1,
  異常値: -1,
  異心: 0,
  異端: -1,
  異物: -1,
  異物混入: -1,
  異変: -1,
  異様: -1,
  異例: 0,
  異論: -1,
  移り変わり: 0,
  移植: 0,
  移植性: 0,
  移民: 0,
  維持: 0,
  維新: 0,
  胃かいよう: -1,
  胃がん: -1,
  胃けいれん: -1,
  胃カタル: -1,
  胃ガン: -1,
  胃液: 0,
  胃炎: -1,
  胃下垂: -1,
  胃拡張: -1,
  胃癌: -1,
  胃弱: -1,
  胃腸炎: -1,
  胃腸病: -1,
  胃痛: -1,
  胃潰瘍: -1,
  胃病: -1,
  萎縮: -1,
  萎靡: -1,
  違背: -1,
  違反: -1,
  違法: -1,
  違法コピー: -1,
  違法行為: -1,
  違約: -1,
  違約金: 0,
  違和: -1,
  違和感: -1,
  遺愛: 1,
  遺憾: -1,
  遺恨: -1,
  遺産: 1,
  遺志: 0,
  遺族基礎年金: 0,
  遺体: -1,
  遺徳: 1,
  遺髪: -1,
  遺品: -1,
  遺物: 0,
  遺留分: 0,
  遺漏: -1,
  医師: 0,
  医療: 0,
  医療サービス: 1,
  医療ミス: -1,
  医療現場: 0,
  医療費: 0,
  医療費助成: 1,
  医療不信: -1,
  域: 0,
  育児休暇: 0,
  育成: 0,
  一か月: 0,
  一つ一つ: 1,
  一カ月: 0,
  一ヵ月: 0,
  一ヶ月: 0,
  一ヶ月以上: 0,
  一安心: 1,
  一因: -1,
  一押し: 1,
  '一押し・一推し': 1,
  一過性: -1,
  一喝: -1,
  一貫性: 1,
  一喜一憂: 0,
  一騎当千: 1,
  一騎討ち: -1,
  一級: 1,
  一撃: -1,
  一撃必殺: 1,
  一元化: 0,
  一言感想: 0,
  一酸化炭素中毒: -1,
  一仕事: 0,
  一死: -1,
  一視同仁: 0,
  一時しのぎ: -1,
  一時間: 0,
  一時逃れ: -1,
  一緒: 1,
  一緒くた: -1,
  一助: 1,
  一笑: 0,
  一寝入り: 0,
  一新: 0,
  一人物: 0,
  一推し: 1,
  一睡: 0,
  一生: 1,
  一生懸命: 1,
  一声: 0,
  一斉射撃: -1,
  一線: 0,
  一線級: 1,
  一打ち: 0,
  一体化: 0,
  一体感: 1,
  一体性: 0,
  一台: 0,
  一大事: -1,
  一団: 0,
  一致: 0,
  一長一短: 0,
  一途: 1,
  一働き: 0,
  一同: 0,
  一難: -1,
  一年以上: 0,
  一敗: -1,
  一杯: 1,
  一杯機嫌: 1,
  一般: 0,
  一般車: 0,
  一番: 1,
  一匹狼: 0,
  一物: 0,
  一文なし: -1,
  一癖: 0,
  一辺倒: -1,
  一報: 0,
  一方ならず: 0,
  一本調子: -1,
  一本立ち: 0,
  一眠り: 0,
  一命: 1,
  一目ぼれ: 0,
  一翼: 0,
  一利: 1,
  一律: 0,
  一流: 1,
  一揆: -1,
  一臂の力: 1,
  溢美: 1,
  逸材: 1,
  逸足: 1,
  逸脱: -1,
  逸品: 1,
  逸物: 1,
  稲刈り: 0,
  稲妻: 0,
  印: 0,
  印鑑証明: 0,
  印鑑登録証明書: 0,
  印行: 0,
  因縁: -1,
  因果: -1,
  因果応報: 0,
  因果関係: 0,
  因業: -1,
  因習: 0,
  引かれ者の小唄: -1,
  引き合い: 0,
  引き受け: 1,
  引き締め: 0,
  引き当て: 0,
  引き立て: 1,
  引き立て役: -1,
  引け目: -1,
  引ったくり: -1,
  引っ掛かり: 0,
  引越し: 0,
  引退: 0,
  飲みすぎ: -1,
  飲み過ぎ: -1,
  飲み料: -1,
  淫ら: -1,
  淫行: -1,
  淫売: -1,
  淫欲: -1,
  淫乱: -1,
  院内: 0,
  院内感染: -1,
  陰: 0,
  陰り: -1,
  陰萎: -1,
  陰影: 0,
  陰気: -1,
  陰金: -1,
  陰口: -1,
  陰性: 0,
  陰謀: -1,
  陰陽: 0,
  隠し球: 0,
  隠し事: -1,
  隠し味: 0,
  隠れ家: 0,
  隠退: 0,
  隠蔽: -1,
  右四つ: 0,
  右打ち: 0,
  右投げ: 0,
  宇宙人: -1,
  羽子板: 1,
  羽翼: 1,
  迂回: 0,
  雨: 0,
  雨音: 0,
  雨下: 0,
  雨降り: -1,
  雨水: 0,
  雨足: 0,
  雨滴: 0,
  雨天: 0,
  雨風: -1,
  雨漏り: -1,
  渦: -1,
  渦中: -1,
  嘘: -1,
  嘘つき: -1,
  噂: 0,
  運: 1,
  運の尽き: -1,
  運気: 1,
  運休: -1,
  運行: 0,
  運転技術: 1,
  運転手: 0,
  運動: 0,
  運動障害: -1,
  運動神経: 1,
  運動性: 1,
  運動性能: 1,
  運動能力: 1,
  運動不足: -1,
  運命: 0,
  運用: 0,
  餌食: -1,
  叡智: 1,
  営み: 0,
  営業活動: 0,
  営業停止: -1,
  影: -1,
  影響: 0,
  影響下: 0,
  影響力: 1,
  栄: 1,
  栄え: 1,
  栄華: 1,
  栄冠: 1,
  栄光: 1,
  栄辱: 0,
  栄誉: 1,
  栄養: 1,
  栄養価: 1,
  栄養失調: -1,
  栄養障害: -1,
  栄養素: 1,
  栄養不足: -1,
  栄養分: 1,
  栄養補給: 0,
  永遠: 1,
  永住権: 1,
  永眠: 0,
  泳力: 1,
  英気: 1,
  英傑: 1,
  英語嫌い: 0,
  英語能力: 1,
  英語力: 1,
  英国: 0,
  英俊: 1,
  英知: 1,
  英哲: 1,
  英名: 1,
  英雄: 1,
  英霊: 1,
  衛生: 0,
  衛生面: 0,
  詠嘆: 1,
  鋭さ: 1,
  鋭気: 1,
  鋭敏: 1,
  鋭利: 0,
  液: 0,
  液漏れ: -1,
  疫: -1,
  疫病: -1,
  疫病神: -1,
  駅構内: 0,
  悦: 1,
  悦び: 1,
  悦楽: 1,
  厭悪: -1,
  厭軍: -1,
  厭世: -1,
  厭戦: -1,
  厭戦気分: -1,
  円滑: 1,
  円滑化: 1,
  円形脱毛症: -1,
  円転滑脱: 1,
  円満: 1,
  宴: 0,
  延び延び: -1,
  延引: -1,
  延焼: -1,
  延性: 1,
  延長保育: 0,
  延命: 0,
  怨み: -1,
  怨恨: -1,
  怨敵: -1,
  怨念: -1,
  怨霊: -1,
  怨嗟: -1,
  援護: 0,
  援助: 0,
  演技派: 0,
  演技力: 1,
  演者: 0,
  演奏: 0,
  炎: -1,
  炎症: -1,
  炎上: -1,
  焔: 0,
  煙: -1,
  煙害: -1,
  猿知恵: -1,
  縁: 1,
  艶: 1,
  艶聞: -1,
  遠回り: -1,
  遠近感: 0,
  遠心力: 0,
  遠足気分: 1,
  遠島: -1,
  遠目: 0,
  遠慮: 0,
  遠慮がち: 0,
  遠慮気味: -1,
  鉛: 0,
  鉛中毒: -1,
  塩害: -1,
  塩気: 0,
  塩辛さ: -1,
  塩素臭: -1,
  塩蔵: 1,
  汚さ: -1,
  汚れ: -1,
  汚れ役: -1,
  汚職: -1,
  汚職事件: -1,
  汚水: -1,
  汚染: -1,
  汚染源: -1,
  汚損: -1,
  汚濁: -1,
  汚泥: -1,
  汚点: -1,
  汚物: -1,
  汚名: -1,
  凹凸: -1,
  奥ゆかしさ: 1,
  奥義: 1,
  奥許し: 0,
  奥行き: 1,
  奥深さ: 1,
  往診: 0,
  往生: -1,
  往復: 0,
  往復ビンタ: -1,
  往来: 0,
  応え: 0,
  応援: 0,
  応援歌: 0,
  応諾: 0,
  応分: 0,
  応募: 0,
  応募者: 1,
  応報: 0,
  応用: 0,
  応用力: 1,
  応力: 1,
  押さえ: 0,
  押さえ込み: 0,
  押し: 0,
  押しつけ: -1,
  押し花: 0,
  押し込み強盗: -1,
  押し出し: 0,
  押し目買い: 0,
  押せ押せ: -1,
  押印: 0,
  旺盛: 1,
  横やり: -1,
  横レス: -1,
  横殴り: -1,
  横行: -1,
  横着: -1,
  横波: 0,
  横風: -1,
  横暴: -1,
  横揺れ: -1,
  横領: -1,
  横恋慕: -1,
  殴打: -1,
  王権: 1,
  王子様: 0,
  王者: 1,
  王手: 0,
  王族: 1,
  王朝: 0,
  王道: 1,
  翁: 0,
  黄: 0,
  黄禍: 0,
  黄金時代: 1,
  黄砂: -1,
  黄濁: -1,
  黄土色: 0,
  黄緑: 0,
  屋号: 1,
  憶測: 0,
  臆見: -1,
  臆病: -1,
  牡馬: 0,
  乙女: 0,
  俺様: -1,
  恩: 1,
  恩愛: 1,
  恩義: 1,
  恩恵: 1,
  恩情: 1,
  恩沢: 1,
  温かさ: 1,
  温かみ: 1,
  温もり: 1,
  温覚: 0,
  温厚: 1,
  温柔: 1,
  温順: 1,
  温情: 1,
  温泉: 0,
  温存: 0,
  温暖化: -1,
  温度差: -1,
  温度上昇: 0,
  温度変化: 0,
  温良: 1,
  温和: 1,
  穏やか: 1,
  穏やかさ: 1,
  穏健: 1,
  穏当: 1,
  穏便: 1,
  穏和: 1,
  音楽家: 0,
  音楽性: 1,
  音感: 1,
  音質: 1,
  音質劣化: -1,
  音声信号: 0,
  音痴: -1,
  音波: 0,
  音漏れ: -1,
  下げ: -1,
  下げ渋り: -1,
  下の下: -1,
  下り坂: -1,
  下ネタ: -1,
  下火: -1,
  下絵: 0,
  下血: -1,
  下限: 0,
  下作: 0,
  下仕事: 0,
  下手: -1,
  下手くそ: -1,
  下手投げ: 0,
  下唇: 0,
  下心: -1,
  下調べ: 0,
  下熱: 0,
  下馬評: 0,
  下品: -1,
  下方修正: -1,
  下味: 0,
  下野: -1,
  下落: -1,
  下痢: -1,
  化け物: -1,
  化学物質過敏症: -1,
  化粧品: 0,
  化石: 0,
  化膿: -1,
  仮構: -1,
  仮作: 0,
  仮柵: 0,
  仮歯: 0,
  仮寝: 0,
  仮睡: 0,
  仮病: -1,
  仮眠: 0,
  仮免: 0,
  仮免許: 0,
  何ヶ月: 0,
  何者: 0,
  何日: 0,
  価格: -1,
  価格下落: -1,
  価格競争: 0,
  価格競争力: 1,
  価格差: 0,
  価格調査: 0,
  価格低下: 0,
  価値: 1,
  価値判断: 0,
  佳: 1,
  佳景: 1,
  佳作: 1,
  佳什: 1,
  佳人: 1,
  佳編: 1,
  佳味: 1,
  加害: -1,
  加害者: -1,
  加虐: -1,
  加護: 1,
  加工性: 1,
  加工品: 0,
  加算: 0,
  加勢: 1,
  加速: 0,
  加速性能: 1,
  加担: 0,
  '加担・荷担': -1,
  加入者: 0,
  可: 1,
  可哀想: -1,
  可愛さ: 1,
  可愛らしさ: 1,
  可決: 1,
  可塑性: 0,
  可動: 0,
  可読: 0,
  可読性: 0,
  可燃性: 0,
  可能: 0,
  可能性: 1,
  可否: 0,
  可不可: 0,
  可溶性: 0,
  可用性: 1,
  可憐: 1,
  嘉賞: 1,
  夏ばて: -1,
  夏バテ: -1,
  夏休み: 0,
  夏風邪: -1,
  嫁いびり: -1,
  家づくり: 0,
  家運: 0,
  家事援助: 1,
  家賃: -1,
  家庭: 1,
  家庭教師: 0,
  家庭生活: 0,
  家庭内暴力: -1,
  寡黙: 1,
  科学性: 1,
  科研費: 1,
  暇: 1,
  果: 0,
  果たし合い: -1,
  果敢: 1,
  果報: 1,
  歌: 0,
  歌い手: 0,
  歌歌い: 0,
  歌境: 0,
  歌手: 1,
  歌唱力: 1,
  歌心: 1,
  歌姫: 0,
  火の玉: -1,
  火の粉: -1,
  火急: -1,
  火球: -1,
  火災: -1,
  火災事故: -1,
  火砕流: -1,
  火事: -1,
  火事場泥棒: -1,
  火傷: -1,
  火柱: -1,
  火難: -1,
  火膨れ: -1,
  火力: 1,
  禍: -1,
  禍害: -1,
  禍福: 0,
  稼働: 0,
  稼働時間: 0,
  花: 0,
  花園: 1,
  花芽: 1,
  花見: 0,
  花束: 0,
  花盗人: -1,
  花粉: 0,
  花粉症: -1,
  花弁: 0,
  荷担: -1,
  荷抜き: -1,
  荷厄介: -1,
  華: 1,
  華やか: 1,
  華やかさ: 1,
  華美: 1,
  華麗: 1,
  華麗さ: 1,
  華奢: 0,
  課題: -1,
  過ぎ者: 0,
  過ち: -1,
  過去: -1,
  過激: -1,
  過呼吸: -1,
  過誤: -1,
  過失: -1,
  過失致死: -1,
  過小: -1,
  過賞: -1,
  過剰: -1,
  過剰反応: -1,
  過食: -1,
  過食症: -1,
  過信: -1,
  過疎: -1,
  過疎化: -1,
  過怠: -1,
  過大: -1,
  過渡: 0,
  過度: -1,
  過当競争: -1,
  過熱: 0,
  過熱感: -1,
  過敏: -1,
  過敏症: -1,
  過負荷: -1,
  過分: 0,
  過褒: -1,
  過密: -1,
  過労: -1,
  過労死: -1,
  霞: 0,
  蚊の鳴くような声: -1,
  我: 0,
  我執: -1,
  我侭: -1,
  我慢: -1,
  我儘: -1,
  画家: 0,
  画質: 1,
  画質劣化: -1,
  画力: 1,
  芽: 1,
  蛾: -1,
  賀: 1,
  賀寿: 1,
  雅: 1,
  雅味: 1,
  餓え: -1,
  餓鬼: -1,
  餓死: -1,
  介: 0,
  介護: 0,
  介護サービス: 1,
  介護報酬: 1,
  介助: 1,
  介抱: 1,
  会員資格: 0,
  会社名: 0,
  会心: 1,
  会心の作: 1,
  会費: 0,
  解決: 1,
  解決策: 1,
  解雇: -1,
  解散: -1,
  解除: 0,
  解消: 1,
  解説文: 0,
  解体屋: 0,
  解脱: 1,
  解答: 0,
  解任: 0,
  解放感: 1,
  解剖: 0,
  解明: 0,
  解約: 0,
  回し: 0,
  回り道: -1,
  回帰: 0,
  回収: 0,
  回天の力: 1,
  回答: 0,
  回避: 0,
  回復: 1,
  回復基調: 1,
  回復傾向: 1,
  回復力: 1,
  回礼: 0,
  塊: 0,
  塊状: 0,
  壊血病: -1,
  壊死: -1,
  壊滅: -1,
  壊滅的打撃: -1,
  壊乱: -1,
  壊疽: -1,
  快楽: 1,
  快活: 1,
  快感: 1,
  快気祝い: 1,
  快事: 1,
  快勝: 1,
  快笑: 0,
  快晴: 1,
  快速: 1,
  快打: 1,
  快諾: 0,
  快調: 1,
  快適: 1,
  快適さ: 1,
  快適性: 1,
  快投: 1,
  快報: 1,
  快眠: 1,
  怪しさ: -1,
  怪我: -1,
  怪我人: -1,
  怪漢: -1,
  怪気炎: -1,
  怪傑: 1,
  怪光線: 0,
  怪事件: -1,
  怪人: -1,
  怪盗: -1,
  怪物: -1,
  怪文書: -1,
  怪聞: -1,
  怪力: 1,
  悔い: -1,
  悔しさ: -1,
  悔し涙: 0,
  懐かしさ: 1,
  懐疑: 0,
  懐具合: 0,
  戒厳令: -1,
  戒告: -1,
  戒慎: -1,
  戒名: 0,
  戒飭: -1,
  拐帯: -1,
  改ざん: -1,
  改悪: -1,
  改易: -1,
  改革: 0,
  改悟: 0,
  改作: 0,
  改心: 1,
  改新: 0,
  改進: 0,
  改正: 1,
  改善: 1,
  改造: 0,
  改定: 0,
  改訂: 0,
  改変: 0,
  改編: 0,
  改良: 1,
  改竄: -1,
  晦渋: -1,
  海原: 0,
  海神: 0,
  海水: 0,
  海水浴: 0,
  海賊: 0,
  海賊行為: -1,
  海賊版: -1,
  海難: -1,
  海難救助: 0,
  海難事故: -1,
  灰: 0,
  灰褐色: 0,
  灰色: 0,
  皆: 0,
  皆勤賞: 1,
  皆様: 0,
  絵: 1,
  絵画: 0,
  絵空事: -1,
  絵手紙: 0,
  絵馬: 0,
  絵筆: 0,
  開き: 0,
  開け方: 0,
  開運: 1,
  開花: 1,
  開花時期: 0,
  開業: 0,
  開墾: 1,
  開催: 0,
  開示: 0,
  開拓: 0,
  開拓者: 1,
  開発期間: 0,
  開票: 0,
  開腹: 0,
  開放: 1,
  開放感: 1,
  開放性: 1,
  貝殻: 0,
  外れ: -1,
  外圧: 0,
  外掛け: 0,
  外患: 0,
  外観: 1,
  外見: 0,
  外交: 0,
  外耳炎: -1,
  外傷: -1,
  外敵: -1,
  外道: -1,
  外反母趾: -1,
  外務: 0,
  外面如菩薩内心如夜叉: -1,
  外枠: 0,
  外寇: 0,
  咳: -1,
  害: -1,
  害悪: -1,
  害虫: -1,
  害毒: -1,
  崖崩れ: -1,
  慨世: -1,
  慨嘆: -1,
  該当者: 0,
  鎧袖一触: 1,
  蛙: 0,
  垣根: 0,
  各パーツ: 0,
  拡充: 1,
  拡大: 0,
  拡張: 0,
  拡張性: 1,
  拡幅: 0,
  格: 1,
  格安: 1,
  格下げ: -1,
  格好よさ: 1,
  格好良さ: 1,
  格差: -1,
  格式: 1,
  格調: 1,
  核戦争: -1,
  核反応: 0,
  核兵器: -1,
  核兵器廃絶: 1,
  殻: 0,
  獲得: 1,
  確か: 0,
  確実: 0,
  確実さ: 1,
  確実性: 1,
  確証: 0,
  確信: 1,
  確然: 1,
  確定: 0,
  確定的: 0,
  確認: 0,
  確保: 1,
  確報: 0,
  確立: 0,
  覚醒剤: -1,
  角栓: 0,
  較差: 0,
  隔たり: -1,
  隔意: -1,
  隔世の感: 0,
  隔離: -1,
  革: 0,
  革新: 0,
  革命: 0,
  革命児: 1,
  学: 1,
  学位: 1,
  学級崩壊: -1,
  学芸員: 0,
  学識: 1,
  学者肌: 0,
  学習意欲: 1,
  学習機会: 1,
  学習効果: 1,
  学習障害: -1,
  学習能力: 1,
  学殖: 0,
  学生たち: 0,
  学生生活: 0,
  学生達: 0,
  学徳: 1,
  学費: 0,
  学力: 1,
  学力低下: -1,
  学歴: 1,
  学歴詐称: -1,
  楽: 1,
  楽しさ: 1,
  楽しみ: 1,
  楽園: 1,
  楽楽: 1,
  楽観: 0,
  楽士: 0,
  楽師: 0,
  楽勝: 1,
  楽人: 0,
  楽天: 0,
  楽天市場: 0,
  顎: 0,
  顎関節症: -1,
  掛け声倒れ: -1,
  掛け倒れ: -1,
  割り増し: 0,
  割れ目: 0,
  割安: 1,
  割高: -1,
  割高感: -1,
  喝采: 0,
  活: 1,
  活気: 1,
  活況: 1,
  活劇: 0,
  活字離れ: -1,
  活性: 1,
  活性化: 1,
  活動: 1,
  活動家: 0,
  活動資金: 1,
  活動力: 1,
  活発: 1,
  活発化: 1,
  活躍: 1,
  活用: 0,
  活力: 1,
  渇: -1,
  渇き: -1,
  渇仰: 1,
  渇水: -1,
  滑らか: 1,
  滑らかさ: 1,
  滑稽: -1,
  滑降: -1,
  滑落: -1,
  葛藤: -1,
  株: 1,
  株式: 0,
  株主資本: 0,
  株数: 1,
  鎌倉幕府: 0,
  瓦礫: -1,
  乾き目: -1,
  乾性: 0,
  乾燥肌: -1,
  乾杯: 1,
  乾癬: -1,
  冠婚葬祭: 0,
  冠水: -1,
  寒さ: -1,
  寒気: -1,
  寒心: -1,
  寒村: -1,
  刊: 0,
  刊行: 0,
  勘: 1,
  勘ぐり: -1,
  勘違い: -1,
  勘気: -1,
  勘当: -1,
  勧め: 0,
  勧業: 0,
  勧奨: 0,
  勧進: 0,
  勧善: 1,
  勧誘: 0,
  巻き舌: 0,
  巻き添え: -1,
  巻き返し: 1,
  喚起: 0,
  堪忍: -1,
  堪能: 1,
  姦淫: -1,
  完ぺき: 1,
  完治: 1,
  完勝: 1,
  完遂: 0,
  完成: 1,
  完成度: 1,
  完全: 1,
  完全失業率: -1,
  完全性: 1,
  完全無欠: 1,
  完走: 1,
  完投: 1,
  完敗: -1,
  完売: 0,
  完備: 0,
  完封: 1,
  完封負け: -1,
  完了: 0,
  完璧: 1,
  官許: 0,
  官能: 1,
  官僚主義: -1,
  寛厚: 1,
  寛容: 1,
  寛容さ: 1,
  干ばつ: -1,
  干ぼし: -1,
  干渉: 0,
  干犯: -1,
  幹部: 0,
  患い: -1,
  患者: -1,
  患部: -1,
  感じ: 0,
  感悦: 1,
  感応: 0,
  感慨: 1,
  感慨無量: 1,
  感覚: 0,
  感激: 1,
  感謝: 1,
  感謝状: 1,
  感受性: 1,
  感傷: -1,
  感情: 0,
  感触: 0,
  感心: 1,
  感性: 1,
  感染: -1,
  感染ルート: -1,
  感染経路: -1,
  感染者: -1,
  感染症: -1,
  感想: 0,
  感想メール: 0,
  感嘆: 1,
  感電: -1,
  感度: 1,
  感動: 1,
  感服: 1,
  感冒: -1,
  感銘: 1,
  '感銘・肝銘': 1,
  感涙: 1,
  慣性: 0,
  換気: 0,
  換金: 0,
  敢闘: 1,
  歓び: 1,
  歓喜: 1,
  歓迎: 1,
  歓呼: 0,
  歓心: 1,
  歓声: 1,
  汗: 0,
  汗だく: -1,
  漢方: 0,
  環境: 0,
  環境悪化: -1,
  環境影響: 0,
  環境汚染: -1,
  環境破壊: -1,
  環境配慮: 0,
  環境変数: 0,
  環境保護: 0,
  環境保全: 0,
  甘え: 1,
  甘えん坊: -1,
  甘さ: 0,
  甘み: 0,
  甘口: 0,
  甘辛: 0,
  甘味: 0,
  監禁: -1,
  監護: 0,
  監獄: -1,
  監査: 0,
  監視: 0,
  監督: 0,
  監督責任: -1,
  看護: 0,
  看板倒れ: -1,
  管見: -1,
  簡易: 1,
  簡潔: 1,
  簡捷: 1,
  簡素: 1,
  簡素化: 0,
  簡単: 1,
  簡単操作: 0,
  簡便: 1,
  簡明: 1,
  簡約: 0,
  簡略: 0,
  緩み: 0,
  緩やか: 0,
  緩急: 0,
  緩衝: 1,
  緩速: 0,
  緩慢: -1,
  緩和: 0,
  肝がん: -1,
  肝炎: -1,
  肝癌: -1,
  肝機能: 0,
  肝機能障害: -1,
  肝硬変: -1,
  肝細胞癌: -1,
  肝試し: 0,
  肝臓がん: -1,
  肝臓ガン: -1,
  肝臓癌: -1,
  肝臓障害: -1,
  肝臓病: -1,
  肝不全: -1,
  肝銘: 1,
  艦砲射撃: -1,
  観: 0,
  観客動員数: 1,
  観光: 1,
  観光案内所: 0,
  観光客: 1,
  観察: 0,
  観察眼: 1,
  観察力: 1,
  観衆: 1,
  観測: 0,
  還付: 0,
  間違い: -1,
  '間違い・間違え': -1,
  間違え: -1,
  間隙: -1,
  間質性肺炎: -1,
  間取り: 0,
  間抜け: -1,
  関が原の戦い: 0,
  関の山: 0,
  関わり: 0,
  関越自動車道: 0,
  関心: 1,
  関税: -1,
  関節炎: -1,
  関節痛: -1,
  関東大震災: -1,
  関門: 0,
  陥没: -1,
  陥落: -1,
  韓国: 0,
  韓国人: 0,
  丸見え: -1,
  丸出し: -1,
  丸損: -1,
  含み笑い: -1,
  含み声: -1,
  含み損: -1,
  含むところ: -1,
  含羞: -1,
  癌: -1,
  癌細胞: -1,
  眼疾: -1,
  眼精疲労: -1,
  眼病: -1,
  岩手: 0,
  頑健: 1,
  頑固: -1,
  頑丈: 1,
  頑張り: 1,
  願い: 0,
  願い事: 1,
  願ったり叶ったり: 1,
  願望: 1,
  企画力: 1,
  企業イメージ: 1,
  企業家: 0,
  企業間競争: 0,
  危うさ: -1,
  危なげ: -1,
  危害: -1,
  危機: -1,
  危機感: -1,
  危機管理: 0,
  危急: -1,
  危険: -1,
  危険箇所: -1,
  危険信号: 0,
  危険性: -1,
  危地: -1,
  危篤: -1,
  危篤状態: -1,
  危難: -1,
  危殆: -1,
  危惧: 0,
  喜び: 1,
  喜悦: 1,
  喜捨: 1,
  喜色: 1,
  喜怒: 0,
  喜怒哀楽: 0,
  器用: 1,
  器用さ: 1,
  器用貧乏: -1,
  器量よし: 1,
  器量人: 1,
  基準: 0,
  基準財政需要額: 0,
  基礎: 1,
  基礎データ: 0,
  基礎学力: 1,
  基礎基本: 1,
  基礎体力: 1,
  基礎代謝: 1,
  基礎知識: 1,
  基地: 0,
  基地外: 0,
  基地問題: 0,
  基調: 0,
  基盤: 1,
  基本: 0,
  基本機能: 1,
  基本合意: 0,
  奇: -1,
  奇異: -1,
  奇禍: -1,
  奇怪: -1,
  奇観: -1,
  奇形: -1,
  奇効: -1,
  奇才: 0,
  奇襲: -1,
  奇人: -1,
  奇声: -1,
  奇跡: 1,
  '奇跡・奇蹟': 1,
  奇蹟: 1,
  奇想: 0,
  奇特: -1,
  奇抜: 0,
  奇病: -1,
  奇癖: 0,
  奇妙: -1,
  奇問: -1,
  奇麗: 1,
  嬉しさ: 1,
  嬉し涙: 1,
  寄り切り: 0,
  寄り倒し: 0,
  寄進: 0,
  寄生虫: 0,
  寄付: 0,
  '寄付・寄附': 0,
  寄附: 1,
  寄与: 0,
  希少: 1,
  希少価値: 1,
  希代: 1,
  希望: 1,
  希望価格: 0,
  希覯: 0,
  忌避: -1,
  忌諱: -1,
  旗手: 0,
  既刊: 0,
  既視感: 0,
  既成概念: -1,
  既成事実: -1,
  既得権益: 1,
  既報: 0,
  期限: 0,
  期待: 1,
  期待外れ: -1,
  期待感: 1,
  期日: 0,
  機会: 1,
  機会均等: 0,
  機会損失: -1,
  機嫌: 0,
  機動性: 1,
  機動的: 1,
  機動力: 1,
  機能: 1,
  機能強化: 1,
  機能訓練: 0,
  機能障害: -1,
  機能性: 1,
  機能低下: -1,
  機能美: 1,
  機能不全: -1,
  機敏: 1,
  機密費: 0,
  帰らぬ旅: -1,
  帰依: 0,
  帰巣性: 0,
  気: 0,
  気さく: 1,
  気の張り: 0,
  気の毒: -1,
  気ふさぎ: -1,
  気ぶっせい: -1,
  気まずさ: -1,
  気まま: 0,
  気運: 0,
  気炎: 1,
  気温差: 0,
  気概: 1,
  気楽: 1,
  気掛かり: -1,
  気管支炎: -1,
  気管支喘息: -1,
  気詰まり: -1,
  気胸: -1,
  気苦労: -1,
  気軽: 1,
  気遣い: 0,
  気遣わしさ: -1,
  気後れ: -1,
  気構え: 1,
  気合: 1,
  気合い: 1,
  気合い負け: -1,
  気骨: 1,
  気持ち: 1,
  気持ちよさ: 1,
  気持ち悪さ: -1,
  気持ち良さ: 1,
  気質: 0,
  気重: -1,
  気丈: 1,
  気乗り: 0,
  気色: 0,
  気随: -1,
  気随気まま: -1,
  気勢: 1,
  気絶: -1,
  気息: 0,
  気息奄奄: -1,
  気任せ: -1,
  気配: 0,
  気配り: 0,
  気迫: 1,
  気疲れ: -1,
  気品: 1,
  気付き: 0,
  気付け: 0,
  気負い: 0,
  気風: 1,
  気分: 0,
  気分一新: 1,
  気分転換: 1,
  気泡: -1,
  気密性: 1,
  気落ち: -1,
  気力: 1,
  気鬱: -1,
  季刊: 0,
  季節: 0,
  季節感: 1,
  稀: 0,
  紀律: 1,
  規制: 0,
  規制強化: 0,
  規整: 0,
  規正: 0,
  '規正・規整': 0,
  規則: 0,
  規模: 1,
  規約: 0,
  規律: 0,
  記憶障害: -1,
  記憶喪失: -1,
  記憶容量: 1,
  記憶力: 1,
  記載事項: 0,
  記入ミス: -1,
  記念: 0,
  記念講演: 0,
  記念品: 1,
  記録: 1,
  貴意: 1,
  貴重: 1,
  貴女: 0,
  起業: 0,
  起業家: 0,
  起業家精神: 0,
  起源: 1,
  起死回生: 1,
  起点: 0,
  起伏: 0,
  輝き: 1,
  飢え: -1,
  飢餓: -1,
  飢餓感: -1,
  飢渇: -1,
  飢饉: -1,
  鬼: 0,
  鬼に金棒: 1,
  鬼ばば: -1,
  鬼気: -1,
  鬼才: 1,
  鬼女: -1,
  鬼神: 0,
  鬼畜: -1,
  亀レス: -1,
  亀裂: -1,
  偽り: -1,
  偽言: -1,
  偽者: -1,
  偽証: -1,
  偽善: -1,
  偽装: -1,
  偽造: -1,
  偽物: -1,
  戯言: -1,
  戯作: 0,
  技: 1,
  技あり: 1,
  技芸: 0,
  技巧: 1,
  技巧派: 0,
  技術: 1,
  技術開発: 0,
  技術向上: 1,
  技術指導: 0,
  技術者: 1,
  技術面: 0,
  技術力: 1,
  技能: 1,
  技量: 1,
  欺瞞: -1,
  犠牲: -1,
  犠牲者: -1,
  犠打: 0,
  犠飛: 0,
  疑い: -1,
  疑義: -1,
  疑似体験: 0,
  疑心: -1,
  疑心暗鬼: -1,
  疑点: -1,
  疑念: -1,
  疑問: 0,
  疑問視: -1,
  疑惑: -1,
  義援: 1,
  義賊: 1,
  義父: 0,
  義務: 0,
  義勇: 1,
  義理: 0,
  義理人情: 1,
  議員: 0,
  議員辞職: -1,
  議論: 0,
  吉: 1,
  吉凶: -1,
  吉左右: 0,
  吉事: 1,
  吉祥: 1,
  吉相: 1,
  吉兆: 1,
  吉日: 1,
  吉報: 1,
  吃音: -1,
  喫煙: -1,
  喫煙者: -1,
  喫緊: -1,
  詰まり: -1,
  詰み: -1,
  却下: -1,
  客観性: 0,
  客気: 0,
  客先: 0,
  客体: 0,
  脚気: -1,
  脚線美: 1,
  脚本家: 0,
  脚力: 1,
  虐殺: -1,
  虐待: -1,
  逆コース: 0,
  逆境: -1,
  逆光: -1,
  逆効果: -1,
  逆恨み: -1,
  逆子: -1,
  逆襲: 0,
  逆心: -1,
  逆転: 0,
  逆転劇: 0,
  逆転勝利: 1,
  逆転負け: -1,
  逆風: -1,
  逆流: 0,
  久しぶり: 0,
  久し振り: 0,
  仇敵: -1,
  休み: 0,
  休暇: 0,
  休学: -1,
  休刊: -1,
  休憩: 1,
  休止: 0,
  休心: 0,
  '休心・休神': 0,
  休神: 0,
  休戚: 0,
  休息: 0,
  休電: -1,
  休日: 0,
  休日出勤: -1,
  吸い殻: -1,
  吸引: 0,
  吸殻: 0,
  吸血鬼: -1,
  吸湿性: 0,
  吸収: 0,
  吸収性: 1,
  吸入: 0,
  宮刑: -1,
  急: -1,
  急ぎ: -1,
  急患: -1,
  急死: -1,
  急上昇: 0,
  急場: 0,
  急場しのぎ: -1,
  急性: 0,
  急性アルコール中毒: -1,
  急性肝炎: -1,
  急性期: -1,
  急性心筋梗塞: -1,
  急性心不全: -1,
  急逝: -1,
  急増: 0,
  急騰: 0,
  急難: -1,
  急迫: -1,
  急病: -1,
  急変: -1,
  急報: 0,
  急落: -1,
  救い: 1,
  救援: 0,
  救急: -1,
  救護: 0,
  救済: 0,
  救済措置: 1,
  救出: 1,
  救助: 1,
  救命: 0,
  朽廃: -1,
  求愛: 0,
  求刑: -1,
  求心力: 1,
  求人: 0,
  泣きどころ: -1,
  泣き言: -1,
  泣き声: -1,
  球威: 1,
  球菌: 0,
  球状: 0,
  球速: 1,
  究極: 0,
  究明: 0,
  窮まり: -1,
  窮屈: -1,
  窮屈さ: -1,
  窮状: -1,
  窮地: -1,
  窮迫: -1,
  窮乏: -1,
  給付: 0,
  給与: 0,
  旧詠: 0,
  旧怨: -1,
  旧恩: 1,
  旧交: 0,
  旧作: 0,
  旧情報: 0,
  旧聞: -1,
  居住性: 1,
  居直り強盗: -1,
  居眠り: -1,
  巨匠: 1,
  拒食: -1,
  拒食症: -1,
  拒絶反応: -1,
  拒否: -1,
  拒否反応: -1,
  拠り所: 1,
  拠出: 0,
  '拠出・醵出': 0,
  虚: 0,
  虚しさ: -1,
  虚偽: -1,
  虚血性心疾患: -1,
  虚言: -1,
  虚言癖: -1,
  虚構: -1,
  虚弱: -1,
  虚弱体質: -1,
  虚心: -1,
  虚勢: -1,
  虚像: -1,
  虚脱感: -1,
  虚報: -1,
  虚無感: -1,
  虚名: -1,
  虚妄: -1,
  許し: 0,
  許可: 0,
  許諾: 0,
  許認可: 0,
  許否: 0,
  許容: 1,
  距離感: 0,
  漁獲: 0,
  漁獲量: 1,
  漁況: 1,
  漁業被害: -1,
  漁村: 0,
  魚油: 0,
  享受: 1,
  供給: 0,
  供給不足: -1,
  供与: 0,
  供養: 0,
  侠盗: 0,
  競り合い: 0,
  競合: 0,
  競作: 0,
  競争心: 0,
  競争相手: 0,
  競争率: 0,
  競争力: 1,
  共感: 1,
  共存: 0,
  共存共栄: 1,
  共通点: 0,
  共通認識: 1,
  共通理解: 1,
  共倒れ: -1,
  共闘: 0,
  共同募金: 0,
  共犯: -1,
  共謀: -1,
  共鳴: 0,
  共有化: 0,
  凶: -1,
  凶悪: -1,
  凶悪事件: -1,
  凶悪犯罪: -1,
  凶器: -1,
  凶荒: -1,
  凶行: -1,
  凶作: -1,
  凶事: -1,
  凶賊: -1,
  凶弾: -1,
  凶変: -1,
  凶報: -1,
  協賛: 0,
  協調: 0,
  協調性: 1,
  協定: 0,
  協力: 1,
  協力関係: 0,
  協力者: 1,
  協和: 0,
  叫び: 0,
  境遇: 0,
  強: 0,
  強がり: -1,
  強さ: 1,
  強み: 1,
  強圧: -1,
  強引: -1,
  強引さ: -1,
  強運: 1,
  強化: 0,
  強姦: -1,
  強気: 0,
  強記: 0,
  強健: 1,
  強固: 1,
  強攻: 0,
  強硬: 0,
  強行軍: -1,
  強剛: 1,
  強豪: 1,
  '強豪・強剛': 1,
  強者: 1,
  強襲: -1,
  強情: -1,
  強制: -1,
  強制使用: -1,
  強制終了: -1,
  強制送還: -1,
  強制労働: -1,
  強壮: 1,
  強打: -1,
  強大: 1,
  強奪: -1,
  強調: 0,
  強敵: -1,
  強度: 1,
  強盗: -1,
  強盗事件: -1,
  強迫観念: 0,
  強風: -1,
  強力: 1,
  強力犯: -1,
  強靱: 1,
  恐さ: -1,
  恐れ: -1,
  恐れなし: 1,
  恐ろしさ: -1,
  恐喝: -1,
  恐慌: -1,
  恐縮: 0,
  恐怖: -1,
  恐怖感: -1,
  恐怖症: -1,
  恐怖心: -1,
  恐怖政治: -1,
  恐竜: -1,
  恐惶: -1,
  恭賀: 1,
  恭敬: 1,
  恭順: 1,
  教え: 0,
  教育: 0,
  教育家: 0,
  教育改革: 1,
  教育活動: 0,
  教育者: 0,
  教育条件: 0,
  教員: 0,
  教官: 0,
  教護: 0,
  教師: 0,
  教授: 0,
  教祖: 0,
  教諭: 0,
  教養: 1,
  狂喜: 1,
  狂気: -1,
  狂牛病: -1,
  狂犬: -1,
  狂犬病: -1,
  狂言: -1,
  狂言回し: 0,
  狂信: -1,
  狭さ: -1,
  狭め: 0,
  狭間: -1,
  狭窄: -1,
  狭心症: -1,
  胸のつかえ: -1,
  胸焼け: -1,
  胸騒ぎ: -1,
  脅威: 0,
  脅迫: -1,
  脅迫電話: -1,
  興行: 0,
  興国: 1,
  興奮: 0,
  興味: 1,
  興味関心: 1,
  興隆: 1,
  郷愁: 1,
  郷土愛: 1,
  鏡開き: 0,
  驚き: 1,
  驚くべき: 0,
  驚喜: 1,
  驚怖: -1,
  仰望: 0,
  凝り: 0,
  凝り性: 0,
  凝滞: -1,
  業: -1,
  業苦: -1,
  業績: 1,
  業病: -1,
  曲線美: 1,
  曲直: 0,
  極: 0,
  極まり: 0,
  極み: 0,
  極め付き: 0,
  極悪: -1,
  極意: 1,
  極楽往生: 1,
  極寒: -1,
  極刑: -1,
  極限: 0,
  極彩色: 0,
  極上: 1,
  極端: -1,
  極致: 0,
  極点: 0,
  極度: 0,
  極論: -1,
  玉にきず: -1,
  玉の緒: 1,
  玉砕: -1,
  玉石: 1,
  僅か: -1,
  勤め: 0,
  勤勉: 1,
  勤務: 0,
  勤労: 0,
  勤労意欲: 1,
  均一性: 0,
  均衡: 0,
  欣快: 1,
  欣喜: 1,
  欣喜雀躍: 1,
  欣幸: 1,
  禁圧: 0,
  禁煙: 1,
  禁忌: -1,
  禁漁: 0,
  禁固: -1,
  禁固刑: -1,
  禁止: 0,
  禁酒: 0,
  禁制: -1,
  禁足: 0,
  禁帯出: 0,
  禁断: 0,
  禁断症状: -1,
  禁転載: 0,
  禁物: -1,
  禁輸: 0,
  禁欲: 0,
  禁欲生活: 0,
  禁猟: 0,
  禁令: 0,
  筋腫: -1,
  筋肉: 1,
  筋肉痛: -1,
  筋肉疲労: -1,
  筋力: 1,
  緊急: -1,
  緊急事態: -1,
  緊急性: -1,
  緊張: -1,
  緊迫: -1,
  緊迫感: 1,
  緊縛: -1,
  菌: -1,
  菌糸: 0,
  謹賀: 1,
  謹慎: -1,
  謹慎処分: -1,
  近衛: 0,
  近詠: 0,
  近刊: 0,
  近業: 0,
  近作: 0,
  近視: -1,
  近所付き合い: 0,
  近所迷惑: -1,
  近代化: 1,
  金: 1,
  金の卵: 1,
  金メダル: 1,
  金一封: 1,
  金運: 1,
  金回り: 1,
  金額: -1,
  金気: 0,
  金詰まり: -1,
  金銀: 1,
  金欠: -1,
  金遣い: 0,
  金庫破り: -1,
  金剛力: 1,
  金食い虫: -1,
  金切り声: -1,
  金銭力: 1,
  金属アレルギー: -1,
  金箔: 0,
  金融危機: -1,
  金融不安: -1,
  金離れ: 0,
  金力: 0,
  金甌無欠: 1,
  吟醸香: 0,
  吟味: 0,
  銀行強盗: -1,
  銀行融資: 0,
  苦: -1,
  苦さ: -1,
  苦しさ: -1,
  苦しみ: -1,
  苦み: -1,
  苦境: -1,
  苦言: 0,
  苦行: -1,
  苦手: -1,
  苦汁: -1,
  苦渋: -1,
  苦笑: -1,
  苦笑い: -1,
  苦情: -1,
  苦心: 0,
  苦節: -1,
  苦戦: -1,
  苦虫: -1,
  苦衷: -1,
  苦痛: -1,
  苦闘: -1,
  苦難: -1,
  苦悩: -1,
  苦杯: -1,
  苦味: -1,
  苦悶: -1,
  苦役: -1,
  苦労: -1,
  苦労性: 0,
  駆け引き: 0,
  駆け出し: 0,
  駆除: 0,
  駆動力: 1,
  具: 0,
  具合: 0,
  具体性: 1,
  愚: -1,
  愚かさ: -1,
  愚挙: -1,
  愚見: -1,
  愚考: 0,
  愚行: -1,
  愚作: -1,
  愚痴: -1,
  愚問: -1,
  虞: -1,
  虞犯: -1,
  空き: 1,
  空き時間: 0,
  空き巣: -1,
  空き巣ねらい: -1,
  空き容量: 0,
  空しさ: -1,
  空っけつ: -1,
  空下手: -1,
  空間: 1,
  空気: 0,
  空虚: -1,
  空隙: -1,
  空言: -1,
  空室: 0,
  空手チョップ: 0,
  空襲: -1,
  空笑い: -1,
  空振り: -1,
  空席: 0,
  空前: 0,
  空前絶後: 0,
  空想: 1,
  空中楼閣: -1,
  空転: -1,
  空洞化: -1,
  空念仏: -1,
  空売り: -1,
  空爆: 0,
  空費: -1,
  空腹: -1,
  空腹感: -1,
  空名: -1,
  空欄: 0,
  偶作: 0,
  偶然性: 0,
  偶像崇拝: 0,
  偶力: 0,
  櫛風沐雨: 0,
  屑: -1,
  屈強: 1,
  屈指: 1,
  屈辱: -1,
  屈辱感: -1,
  屈託: -1,
  掘り出し物: 1,
  勲章: 1,
  君恩: 1,
  君子: 0,
  君主: 0,
  薫り: 1,
  訓導: 0,
  群: 0,
  群れ: 0,
  群集心理: 0,
  群盗: -1,
  群雄: 0,
  軍事攻撃: -1,
  軍事力: 1,
  軍神: 1,
  軍勢: 0,
  軍費: -1,
  軍備: 0,
  係争: -1,
  傾国: -1,
  刑: -1,
  刑事事件: -1,
  刑事罰: -1,
  刑罰: -1,
  刑務: -1,
  刑務所: -1,
  啓発: 0,
  啓蒙: 0,
  珪肺: -1,
  型破り: -1,
  契丹: 0,
  契約者: 0,
  契約内容: 0,
  形式: -1,
  形式美: 1,
  形質: 1,
  形成: 1,
  恵み: 1,
  慶賀: 1,
  慶事: 1,
  慶祝: 1,
  掲載: 0,
  掲載許可: 0,
  携帯性: 1,
  敬意: 1,
  敬遠: 0,
  敬仰: 1,
  敬神: 1,
  敬老: 0,
  景観: 1,
  景気: 1,
  景気回復: 1,
  景気後退: -1,
  景気循環: 0,
  景気対策: 1,
  景況: 0,
  景仰: 1,
  景勝: 1,
  景色: 1,
  景品: 1,
  稽古: 0,
  経営危機: -1,
  経営難: -1,
  経営能力: 1,
  経営破綻: -1,
  経験: 0,
  経験不足: -1,
  経済援助: 0,
  経済活性化: 1,
  経済活動: 0,
  経済活力: 1,
  経済危機: -1,
  経済協力: 0,
  経済効果: 1,
  経済効率: 1,
  経済合理性: 1,
  経済支援: 0,
  経済制裁: -1,
  経済性: 1,
  経済成長: 1,
  経済政策: 1,
  経済的損失: -1,
  経済的利益: 1,
  経済的理由: 0,
  経済発展: 1,
  経済不況: -1,
  経済封鎖: -1,
  経済力: 1,
  経常費: 0,
  経費: -1,
  継ぎ足し: 0,
  継承: 0,
  継続性: 1,
  継続的改善: 1,
  継投: 0,
  繋がり: 1,
  荊棘: -1,
  計画的: 0,
  計画変更: 0,
  計算: 0,
  計算量: 0,
  計算力: 1,
  警衛: 0,
  警戒: -1,
  警戒心: -1,
  警固: 0,
  警護: 0,
  '警護・警固': 0,
  警告: 0,
  警告音: -1,
  警察: 0,
  警鐘: -1,
  警笛: -1,
  警備: 0,
  警備員: 0,
  警報: 0,
  警防: 0,
  軽さ: 1,
  軽め: 1,
  軽やか: 1,
  軽易: 1,
  軽快: 1,
  軽快さ: 1,
  軽軽: 1,
  軽減: 1,
  軽減措置: 0,
  軽傷: -1,
  軽症: -1,
  軽信: -1,
  軽打: 0,
  軽度: 1,
  軽薄: -1,
  軽犯罪: -1,
  軽便: 1,
  軽妙: 1,
  軽率: -1,
  軽量: 1,
  軽量化: 0,
  軽労働: 1,
  芸域: 1,
  芸術: 1,
  芸術家: 0,
  芸術性: 1,
  芸達者: 1,
  芸能: 1,
  劇化: 0,
  劇痛: -1,
  撃墜: -1,
  激しさ: 0,
  激安: 1,
  激化: 0,
  '激化・劇化': 0,
  激賞: 0,
  激情: 1,
  激辛: 0,
  激震: -1,
  激甚: 1,
  激戦: 0,
  激痛: -1,
  '激痛・劇痛': -1,
  激怒: -1,
  激闘: 1,
  激動: -1,
  激務: -1,
  激励: 1,
  隙: -1,
  隙間: 0,
  隙間風: -1,
  傑作: 1,
  傑人: 1,
  傑物: 1,
  欠: -1,
  欠課: -1,
  欠格: -1,
  欠陥: -1,
  欠航: -1,
  欠講: -1,
  欠場: -1,
  欠席: -1,
  欠損: -1,
  欠点: -1,
  欠如: -1,
  欠品: -1,
  欠乏: -1,
  欠落: -1,
  欠礼: -1,
  決まり: 0,
  決まり事: 0,
  決まり手: 1,
  決まり文句: 0,
  決め手: 1,
  決壊: '　',
  決勝: 0,
  決勝ゴール: 1,
  決勝点: 1,
  決心: 0,
  決戦場: 0,
  決断力: 1,
  決定: 0,
  決定力: 1,
  決定力不足: -1,
  決闘: -1,
  潔さ: 1,
  潔白: 1,
  穴: 0,
  結びつき: 0,
  結果オーライ: 1,
  結界: 0,
  結核: -1,
  結核患者: -1,
  結核菌: -1,
  結構: 1,
  結構ずくめ: 1,
  結婚: 1,
  結婚相手: 0,
  結婚披露宴: 1,
  結石: -1,
  結束: 0,
  結滞: 0,
  結膜炎: -1,
  血: 0,
  血しぶき: -1,
  血だらけ: -1,
  血と汗: 0,
  血の気: 0,
  血の道: -1,
  血まみれ: -1,
  血液検査: 0,
  血液循環: 0,
  血気: 0,
  血気盛ん: 1,
  血行: 1,
  血行障害: -1,
  血行不良: -1,
  血祭り: -1,
  血止め: 0,
  血戦: 0,
  血栓: -1,
  血栓症: -1,
  血糖: -1,
  血糖値: -1,
  血反吐: -1,
  血目: -1,
  血友病: -1,
  血流: 0,
  血流量: 0,
  血痰: -1,
  月刊: 0,
  月経痛: -1,
  月光: 0,
  月日: 0,
  月報: 0,
  月明かり: 0,
  月曜病: -1,
  倹約: 0,
  倦怠: -1,
  倦怠感: -1,
  健やか: 1,
  健康: 1,
  健康管理: 0,
  健康障害: -1,
  健康長寿: 1,
  健康被害: -1,
  健康美: 1,
  健康保険: 0,
  健康問題: -1,
  健在: 1,
  健在ぶり: 1,
  健勝: 1,
  健全: 1,
  健全育成: 1,
  健全化: 1,
  健全性: 1,
  健闘: 1,
  健筆: 1,
  健忘症: -1,
  剣が峰: 0,
  剣客: 0,
  剣戟: -1,
  剣豪: 1,
  剣士: 0,
  剣術: 0,
  喧嘩: -1,
  喧騒: 0,
  '喧騒・喧噪': -1,
  喧噪: -1,
  堅固: 1,
  堅持: 0,
  堅実: 1,
  堅調: 1,
  堅牢: 1,
  堅牢性: 1,
  嫌: -1,
  嫌い: -1,
  嫌がらせ: -1,
  嫌悪: -1,
  嫌悪感: -1,
  嫌煙: -1,
  嫌忌: -1,
  嫌気: -1,
  嫌疑: -1,
  嫌味: -1,
  建設業: 0,
  憲章: 0,
  懸賞: 1,
  懸濁: -1,
  懸念: -1,
  捲土重来: 0,
  検閲: 0,
  検挙: -1,
  検査: 0,
  検索結果: 0,
  検証: 0,
  検診: 0,
  検討: 0,
  検問: 0,
  権威: 1,
  権益: 1,
  権限: 1,
  権勢: 1,
  権利: 1,
  権利行使: 0,
  権利侵害: -1,
  権力: 1,
  権力争い: -1,
  権力闘争: -1,
  牽制: -1,
  牽制球: 0,
  献金: 0,
  献血: 0,
  献身: 0,
  研究: 0,
  研究開発: 0,
  研究者: 0,
  研究心: 0,
  研究成果: 1,
  研究能力: 1,
  研究費: 1,
  研修: 0,
  研修生: 0,
  研鑽: 0,
  肩こり: -1,
  肩凝り: -1,
  肩透かし: -1,
  肩入れ: 0,
  見え見え: -1,
  見せしめ: -1,
  見どころ: 1,
  見やすさ: 1,
  見易さ: 1,
  見応え: 1,
  見学者: 0,
  見掛け倒し: -1,
  見巧者: 1,
  見事: 1,
  見識: 1,
  見所: 1,
  見送り: 0,
  見当違い: -1,
  見舞金: 0,
  見物: 1,
  見返り: 1,
  見劣り: -1,
  謙虚: 1,
  謙虚さ: 1,
  賢さ: 1,
  賢愚: 0,
  賢者: 1,
  賢俊: 1,
  賢女: 1,
  賢人: 1,
  賢哲: 1,
  賢明: 1,
  鍵穴: 0,
  険: -1,
  険しさ: -1,
  験: 1,
  元: 0,
  元ネタ: 0,
  元気: 1,
  元気さ: 1,
  原因: -1,
  原拠: 0,
  原罪: -1,
  原作: 0,
  原石: 0,
  原虫: 0,
  原爆症: -1,
  原爆投下: -1,
  原発: 0,
  原油: 0,
  厳しさ: -1,
  厳格: -1,
  厳寒: -1,
  厳禁: -1,
  厳刑: -1,
  厳正: 0,
  厳選: 0,
  厳罰: -1,
  厳罰化: 0,
  厳密さ: 1,
  幻影: -1,
  幻覚: -1,
  幻想: 0,
  幻聴: -1,
  幻滅: -1,
  減塩: 0,
  減価: -1,
  減額: 0,
  減刑: 0,
  減産: 0,
  減収: -1,
  減少傾向: 0,
  減衰: -1,
  減速: 0,
  減退: -1,
  減点: -1,
  減農薬: 0,
  減免措置: 0,
  減量: 0,
  減量化: 0,
  源: 0,
  源泉: 1,
  玄人: 1,
  玄米菜食: 0,
  現金: 1,
  現金収入: 1,
  現行制度: 0,
  現行犯: -1,
  現実: 0,
  現実感: 1,
  現実感覚: 1,
  現実性: 1,
  現実味: 1,
  現場検証: 0,
  現場主義: 0,
  現状: 0,
  現状維持: 0,
  現世利益: 1,
  現代人: 0,
  現代病: -1,
  現段階: 0,
  現地調査: 0,
  現役世代: 0,
  言いなり: -1,
  言い過ぎ: -1,
  言い争い: -1,
  言い直し: 0,
  言い分: 1,
  言い方: 0,
  言い訳: -1,
  言い立て: -1,
  言うに及ばず: 0,
  言うもおろか: 1,
  言わずもがな: 1,
  言語感覚: 1,
  言語障害: -1,
  言語能力: 1,
  言説: 0,
  言動: -1,
  言葉とがめ: 0,
  言葉足らず: -1,
  言霊: 0,
  限り: -1,
  限界: -1,
  限界点: 0,
  限度: -1,
  個々人: 0,
  個我: 0,
  個室: 0,
  個人差: 0,
  個人指導: 0,
  個人情報: 0,
  個人的意見: 0,
  個人的感想: 0,
  個性: 1,
  個性化: 0,
  個別指導: 0,
  古さ: 0,
  古つわもの: 1,
  古豪: 1,
  古今未曾有: 0,
  古臭さ: -1,
  古書: 0,
  古拙: -1,
  古代遺跡: 0,
  古風: 0,
  古本: 0,
  呼び出し: 0,
  呼び声: 0,
  呼び鈴: 0,
  呼吸: 0,
  呼吸音: 0,
  呼吸器疾患: -1,
  呼吸器病: -1,
  呼吸停止: -1,
  呼吸不全: -1,
  呼出音: 0,
  固まり: 0,
  固め打ち: 0,
  固持: -1,
  固執: -1,
  固守: 0,
  固定観念: -1,
  固定客: 0,
  固定資産税: 0,
  固有: 0,
  姑: 0,
  孤軍奮闘: -1,
  孤独: -1,
  孤独感: -1,
  孤立: -1,
  孤立化: -1,
  孤立感: -1,
  戸惑い: -1,
  故意: 0,
  故事: 0,
  故障: -1,
  故障者: 0,
  故人: -1,
  枯れ枝: 0,
  枯れ葉: 0,
  枯死: -1,
  狐: -1,
  狐疑: -1,
  糊: 0,
  糊塗: -1,
  袴: 0,
  股: 0,
  胡散臭さ: -1,
  虎口: 0,
  虎口の難: -1,
  誇り: 1,
  誇大妄想: -1,
  誇張: -1,
  雇用: 0,
  雇用機会: 0,
  雇用契約: 0,
  雇用情勢: 0,
  雇用状況: 0,
  雇用創出: 1,
  雇用不安: -1,
  顧客: 1,
  顧客満足: 1,
  鼓吹: 0,
  鼓舞: 0,
  五感: 0,
  五月病: -1,
  五胡十六国: 0,
  五穀豊穣: 1,
  五代十国: 0,
  五分: 0,
  互換性: 1,
  午睡: 0,
  呉: 0,
  娯楽: 1,
  娯楽性: 1,
  後れ: -1,
  後ろめたさ: -1,
  後ろ押し: 0,
  後ろ盾: 1,
  後遺症: -1,
  後援: 1,
  後押し: 1,
  後回し: -1,
  後悔: -1,
  後継者: 0,
  後継者不足: -1,
  後見: 0,
  後顧: -1,
  後顧の憂い: -1,
  後攻: 0,
  後攻め: 0,
  後座: 0,
  後手: -1,
  後進: 0,
  後退: -1,
  後知恵: -1,
  後天性: 0,
  後難: -1,
  後腐れ: -1,
  後味: 0,
  御の字: 1,
  御機嫌: 1,
  御協力: 0,
  御言葉: 1,
  御指摘: 0,
  御指導: 0,
  御支援: 1,
  御神火: 1,
  御難: -1,
  御報: 0,
  御免: -1,
  御利益: 1,
  御理解: 0,
  御了解: 0,
  御了承: 0,
  悟り: 1,
  悟性: 1,
  語学力: 1,
  語句: 0,
  語弊: -1,
  語彙力: 1,
  誤り: -1,
  誤解: -1,
  誤記: -1,
  誤差: -1,
  誤算: -1,
  誤字: -1,
  誤字脱字: -1,
  誤植: -1,
  誤信: -1,
  誤審: -1,
  誤断: -1,
  誤認: -1,
  誤爆: -1,
  誤謬: -1,
  誤報: -1,
  誤訳: -1,
  誤用: -1,
  護衛: 0,
  護憲: 0,
  護持: 0,
  護身: 0,
  護法: 0,
  護摩の灰: -1,
  乞食: -1,
  交じらい: 1,
  交じり: 0,
  交わり: 1,
  交易: 0,
  交感: 0,
  交歓: 0,
  交誼: 1,
  交際: 0,
  交際費: 0,
  交渉: 0,
  交情: 0,
  交替: 0,
  交通安全: 1,
  交通規制: 0,
  交通混雑: -1,
  交通死亡事故: -1,
  交通事故: -1,
  交通渋滞: -1,
  交通戦争: -1,
  交通費: 0,
  交通麻痺: -1,
  交通量: 0,
  交付: 0,
  交友: 1,
  交遊: 1,
  交流: 1,
  候補者: 0,
  光: 1,
  光栄: 1,
  光化学スモッグ: -1,
  光害: -1,
  光輝: 0,
  光景: 0,
  光合成: 0,
  光信号: 0,
  光線: 0,
  光沢: 1,
  光熱費: -1,
  光明: 1,
  光量: 1,
  公: 0,
  公安: 0,
  公益: 1,
  公益性: 1,
  公害: -1,
  公害問題: -1,
  公刊: 0,
  公許: 0,
  公共工事: 0,
  公共事業: 0,
  公共心: 1,
  公共性: 1,
  公差: 0,
  公衆衛生: 1,
  公傷: -1,
  公証: 0,
  公職追放: 0,
  公正: 1,
  公正さ: 1,
  公徳: 1,
  公認: 0,
  公認会計士: 0,
  公表: 0,
  公平: 1,
  公平さ: 1,
  公平性: 1,
  公報: 0,
  公明: 1,
  公明正大: 1,
  功: 1,
  功績: 1,
  功徳: 1,
  効き目: 1,
  効果: 1,
  効果的: 1,
  効験: 1,
  効能: 1,
  効用: 1,
  効率: 1,
  効率化: 0,
  効率性: 1,
  効力: 1,
  厚み: 0,
  厚意: 1,
  厚誼: 1,
  厚志: 1,
  厚紙: 0,
  厚情: 1,
  厚生: 1,
  厚生年金: 1,
  厚底ブーツ: 0,
  口げんか: -1,
  口なめずり: 0,
  口喧嘩: -1,
  口呼吸: -1,
  口語: 0,
  口腔ケア: 0,
  口実: 0,
  口臭: -1,
  口上: 0,
  口中: 0,
  口蹄疫: -1,
  口頭: 0,
  口頭試問: 0,
  口内炎: -1,
  口腹: 1,
  口癖: -1,
  口論: -1,
  向こう意気: -1,
  向寒: 0,
  向上: 1,
  向上心: 1,
  向心力: 1,
  向性: 0,
  向日性: 0,
  垢: -1,
  好き: 1,
  好き嫌い: -1,
  好き勝手: -1,
  好き放題: -1,
  好み: 1,
  好意: 1,
  好印象: 1,
  好運: 1,
  好塩菌: 0,
  好学: 1,
  好感: 1,
  好感度: 1,
  好奇: 1,
  好奇心: 1,
  好機: 1,
  好誼: 1,
  好況: 1,
  好景気: 1,
  好結果: 1,
  好個: 1,
  好事: 1,
  好人物: 1,
  好成績: 1,
  好打: 1,
  好対照: 0,
  好男子: 1,
  好調: 1,
  好調さ: 1,
  好敵手: 1,
  好転: 1,
  好都合: 1,
  好投: 1,
  好評: 1,
  好不調: 0,
  好捕: 1,
  好味: 1,
  孔子: 0,
  孝行: 1,
  孝養: 1,
  工具: 0,
  工作: 0,
  工事: 0,
  工事中: 0,
  工賃: 1,
  工費: 0,
  巧み: 1,
  巧者: 1,
  巧打: 1,
  巧妙: 1,
  幸: 1,
  幸い: 1,
  幸せ: 1,
  '幸せ・仕合わせ': 1,
  幸せ感: 1,
  幸運: 1,
  '幸運・好運': 1,
  幸甚: 1,
  幸不幸: 0,
  幸福: 1,
  幸福感: 1,
  広がり: 1,
  広さ: 1,
  広め: 1,
  広告: 0,
  弘法大師: 0,
  恒常性: 0,
  恒例: 0,
  慌ただしさ: -1,
  抗議: -1,
  抗議行動: 0,
  抗告: 0,
  抗戦: -1,
  抗争: -1,
  抗張力: 1,
  抗日: 0,
  抗弁: 0,
  抗力: 0,
  拘縮: 0,
  拘束: -1,
  拘束具: 0,
  拘泥: -1,
  拘留: -1,
  控えめ: 0,
  控え目: 1,
  控訴: -1,
  攻め: 1,
  攻撃: 0,
  攻撃力: 1,
  攻守: 0,
  攻勢: -1,
  攻防: 0,
  攻略: 0,
  更新: 0,
  更新意欲: 1,
  更正: 0,
  更生: 1,
  更迭: -1,
  更年期: -1,
  更年期障害: -1,
  校長会: 0,
  校内: 0,
  校風: 0,
  構成力: 1,
  構想: 0,
  構想力: 1,
  構造改革: 0,
  構築: 0,
  江戸幕府: 0,
  洪恩: 1,
  洪水: -1,
  甲子園出場: 0,
  皇国史観: 0,
  皇帝: 1,
  硬化: 0,
  硬式野球: 0,
  硬調: 0,
  硬直: -1,
  硬派: 1,
  稿: -1,
  紅一点: 0,
  紅葉狩り: 0,
  綱引き: 0,
  考え違い: -1,
  考え物: 0,
  考え方: 0,
  考証: 0,
  考慮: 0,
  肯定: 0,
  航空機事故: -1,
  荒々しさ: -1,
  荒さ: -1,
  荒れ野: -1,
  荒仕事: -1,
  荒神: 0,
  荒唐無稽: -1,
  荒波: -1,
  荒廃: -1,
  荒蕪: -1,
  荒野: 0,
  行い: 0,
  行き過ぎ: -1,
  行き詰まり: -1,
  行き悩み: -1,
  行き来: 0,
  行く手: 0,
  行刑: -1,
  行進: 0,
  行政: 0,
  行政サービス: 0,
  行政運営: 0,
  行動力: 1,
  行方不明者: -1,
  行旅病者: -1,
  行路病者: -1,
  講師: 1,
  講話: 0,
  貢献: 1,
  購入意欲: 1,
  購入者: 0,
  購買意欲: 1,
  購買力: 1,
  酵素: 1,
  鉱物: 0,
  降り: 0,
  降雨: 0,
  降灰: -1,
  降格: -1,
  降雪: 0,
  降板: -1,
  香り: 1,
  香味: 0,
  高め: 0,
  高コスト構造: 0,
  高域: 0,
  高恩: 1,
  '高恩・洪恩': 1,
  高音質: 1,
  高価: 1,
  高可用性: 1,
  高画質: 1,
  高機能: 1,
  高誼: 1,
  高級: 1,
  高級感: 1,
  高給: 1,
  高句麗: 0,
  高血圧: -1,
  高血圧症: -1,
  高効率化: 1,
  高校生: 0,
  高校生活: 1,
  高校野球: 0,
  高山病: -1,
  高脂血症: -1,
  高笑い: 0,
  高信号: 1,
  高性能: 1,
  高精度: 1,
  高声: 0,
  高僧: 1,
  高速: 1,
  高速性: 1,
  高値: 0,
  高潮: 0,
  高低: 0,
  高点: 1,
  高電圧: 0,
  高度: 1,
  高度医療: 1,
  高度差: 0,
  高島屋: 0,
  高等: 1,
  高騰: -1,
  高得点: 1,
  高徳: 1,
  高熱: -1,
  高濃度: 0,
  高配: 0,
  高庇: 1,
  高評: 1,
  高品質: 1,
  高揚: 0,
  高率: 1,
  高麗: 0,
  高齢: 0,
  剛: 1,
  剛の者: 1,
  剛球: 1,
  '剛球・豪球': 1,
  剛強: 1,
  剛性: 1,
  剛直: 1,
  号令: 0,
  合わせ技: 0,
  合意形成: 0,
  合格: 1,
  合格者: 1,
  合格者数: 1,
  合間: 0,
  合衆国: 0,
  合図: 0,
  合成洗剤: 0,
  合同練習: 0,
  合併症: -1,
  合法性: 0,
  合理: 1,
  合理化: 0,
  合理性: 1,
  合理的: 1,
  合力: 0,
  壕: 0,
  拷問: -1,
  豪雨: 0,
  豪華: 1,
  豪華さ: 1,
  豪華キャスト: 1,
  豪華賞品: 1,
  豪快: 1,
  豪球: 1,
  豪傑: 1,
  豪州: 0,
  豪速球: 1,
  克己: -1,
  克服: 1,
  克明: 0,
  刻印: 0,
  告白: 0,
  告発: -1,
  国つ神: 0,
  国運: 1,
  国益: 1,
  国家主権: -1,
  国外追放: -1,
  国禁: -1,
  国交: 1,
  国債: 0,
  国際感覚: 0,
  国際競争: 0,
  国際競争力: 1,
  国際協調: 0,
  国際交流: 1,
  国際人: 1,
  国際性: 1,
  国際的視野: 1,
  国事犯: -1,
  国難: -1,
  国民的議論: 0,
  国務: 0,
  国力: 1,
  酷寒: -1,
  酷刑: -1,
  酷使: -1,
  酷暑: -1,
  酷評: -1,
  黒ずみ: -1,
  黒煙: -1,
  黒字: 1,
  黒星: -1,
  黒船: 0,
  黒潮: 0,
  黒内障: -1,
  黒変: -1,
  黒魔法: 0,
  黒幕: -1,
  獄: -1,
  獄中: -1,
  腰骨: 0,
  腰痛: -1,
  骨: -1,
  骨髄移植: 0,
  骨惜しみ: -1,
  骨折: -1,
  骨粗鬆症: -1,
  骨董: 1,
  骨肉の争い: -1,
  今: 0,
  今昔の感: 0,
  今年: 0,
  困却: -1,
  困窮: -1,
  困苦: -1,
  困難: -1,
  困難さ: -1,
  困難性: -1,
  困惑: -1,
  困憊: -1,
  婚約: 1,
  恨: -1,
  恨み: -1,
  恨みっこ: -1,
  恨みつらみ: -1,
  恨めしさ: -1,
  恨事: -1,
  懇意: 1,
  懇願: -1,
  懇志: 1,
  懇情: 1,
  懇親: 1,
  昏倒: -1,
  昆虫: 0,
  昆虫類: 0,
  根なし: -1,
  根気: 1,
  根拠: 1,
  根強さ: 1,
  根性: 1,
  根腐れ: -1,
  根本原因: -1,
  混じり: 1,
  混じり気: -1,
  混雑: -1,
  混色: 0,
  混信: -1,
  混戦: -1,
  混濁: -1,
  混同: 0,
  混沌: -1,
  混入: -1,
  混乱: -1,
  痕跡: 0,
  魂: 1,
  魂胆: 0,
  魂魄: 0,
  些事: -1,
  左うちわ: 1,
  左四つ: 0,
  左打ち: 0,
  左投げ: 0,
  左利き: -1,
  差: -1,
  差し押さえ: 0,
  差し込み: -1,
  差し支え: -1,
  差し手: 0,
  差し障り: -1,
  差し替え: 0,
  差し入れ: 0,
  差し戻し: -1,
  差異: 0,
  差違: 0,
  差押: -1,
  差押え: 0,
  差別: -1,
  差別意識: -1,
  査証: 0,
  砂: 0,
  砂金: 1,
  砂利: 0,
  砂埃: 0,
  詐欺: -1,
  鎖国: -1,
  坐骨神経痛: -1,
  座礁: -1,
  座頭市: 0,
  挫折: -1,
  挫折感: -1,
  債券: -1,
  債権: -1,
  債権回収: 0,
  債権放棄: -1,
  債務: -1,
  債務超過: -1,
  催し物: 0,
  再インストール: 0,
  再会: 0,
  再開: 0,
  再刊: 0,
  再起: 1,
  再興: 1,
  再建: 0,
  再検証: 0,
  再現: 0,
  再現性: 1,
  再交付: 0,
  再出発: 0,
  再生: 1,
  再生能力: 1,
  再生不良性貧血: -1,
  再戦: 0,
  再選: 1,
  再提出: 0,
  再登場: 0,
  再燃: 1,
  再発: -1,
  再発防止: 0,
  再発率: -1,
  再犯: -1,
  再利用: 0,
  最愛: 1,
  最悪: -1,
  最期: -1,
  最強: 1,
  最高: 1,
  最高記録: 1,
  最高得点: 1,
  最終需要: 0,
  最終調整: 0,
  最小化: 0,
  最小限: -1,
  最新: 1,
  最新情報: 0,
  最盛: 1,
  最善: 1,
  最大限: 1,
  最低: -1,
  最適: 1,
  最適化: 1,
  最良: 1,
  彩り: 1,
  彩色: 0,
  才: 1,
  才気: 1,
  才子: 1,
  才俊: 1,
  才女: 1,
  才色兼備: 1,
  才人: 1,
  才知: 1,
  才能: 1,
  才媛: 1,
  才物: 1,
  才力: 1,
  採血: 0,
  採光: 0,
  採用: 1,
  採用数: 1,
  採用枠: 1,
  歳月: 0,
  済度: 1,
  災: -1,
  災い: -1,
  災禍: -1,
  災害: -1,
  災難: -1,
  災厄: -1,
  祭神: 0,
  祭日: 1,
  細やか: 1,
  細菌: -1,
  細菌感染: -1,
  細菌類: 0,
  細工: 0,
  細事: -1,
  細身: 1,
  菜食: 0,
  裁可: 0,
  裁許: 0,
  裁定: 0,
  際限: 0,
  在庫: 0,
  在庫切れ: -1,
  在宅医療: 1,
  在日: 0,
  罪: -1,
  罪悪: -1,
  罪悪感: -1,
  罪科: -1,
  罪過: -1,
  罪業: -1,
  罪障: -1,
  罪状: -1,
  罪深さ: -1,
  財源: 1,
  財産刑: -1,
  財産権: 0,
  財政: 0,
  財政危機: -1,
  財政再建: 1,
  財政支援: 1,
  財政赤字: -1,
  財政措置: 1,
  財政難: -1,
  財政力: 1,
  財宝: 1,
  財務: 0,
  財務状況: 0,
  財務体質: 0,
  財務内容: 0,
  財力: 1,
  冴え: 1,
  阪神ファン: 0,
  阪神淡路大震災: -1,
  作り: 1,
  作り事: -1,
  作り笑い: -1,
  作り声: -1,
  作り話: -1,
  作意: 0,
  作為: -1,
  作家: 0,
  作況: 0,
  作業効率: 1,
  作業時間: 0,
  作曲家: 0,
  作成: 0,
  作製: 0,
  作風: 0,
  作柄: 0,
  作用: 0,
  削除: 0,
  削除依頼: 0,
  搾取: -1,
  昨報: 0,
  錯誤: -1,
  桜花: 0,
  桜色: 0,
  刷新: 0,
  擦り傷: -1,
  擦過傷: -1,
  殺し: -1,
  殺意: -1,
  殺害: -1,
  殺気: -1,
  殺菌: 1,
  殺人: -1,
  殺人鬼: -1,
  殺人罪: -1,
  殺人事件: -1,
  殺人未遂: -1,
  殺人容疑: -1,
  殺生: -1,
  殺風景: -1,
  殺戮: -1,
  雑音: -1,
  雑貨: 0,
  雑菌: -1,
  雑言: -1,
  雑事: -1,
  雑草: 0,
  雑踏: 0,
  雑念: -1,
  雑費: 0,
  雑報: -1,
  雑務: -1,
  雑役: -1,
  雑用: -1,
  錆: -1,
  三すくみ: -1,
  三ヶ月: 0,
  三悪道: -1,
  三景: 1,
  三顧の礼: 0,
  三国一: 1,
  三時間: 0,
  三週間: 0,
  三十分: 0,
  三重殺: 0,
  三振: -1,
  三嘆: 1,
  三段: 0,
  三哲: 1,
  三日: 0,
  三拍子: 0,
  三部作: 0,
  三枚目: 0,
  三流: -1,
  参加: 0,
  参加希望: 0,
  参加協力: 0,
  参加者: 1,
  参会: 1,
  参詣: 0,
  参考: 0,
  参入: 0,
  参列: 0,
  山の幸: 0,
  山の神: 0,
  山王: 0,
  山火事: -1,
  山気: -1,
  山紫水明: 1,
  山賊: -1,
  山地: 0,
  山霊: 0,
  惨め: -1,
  惨禍: -1,
  惨害: -1,
  惨苦: -1,
  惨劇: -1,
  惨事: -1,
  惨状: -1,
  惨敗: -1,
  惨落: -1,
  散逸: -1,
  散財: -1,
  散策: 1,
  散散: -1,
  散髪: 0,
  散歩: 1,
  散漫: -1,
  散乱: -1,
  産すな神: 0,
  産みの苦しみ: -1,
  産休: 0,
  産業: 1,
  産業廃棄物: -1,
  産土神: 0,
  産婦人科: 0,
  産霊の神: 0,
  蚕: 0,
  賛意: 1,
  賛仰: 1,
  賛辞: 1,
  賛助: 1,
  賛成: 0,
  賛成反対: 0,
  賛嘆: 1,
  賛同: 1,
  賛同者: 0,
  賛否: 0,
  賛否両論: 0,
  賛美: 1,
  酸: -1,
  酸化反応: 0,
  酸欠: -1,
  酸欠状態: -1,
  酸性雨: -1,
  酸素不足: -1,
  酸味: 0,
  斬撃: -1,
  斬新さ: 1,
  暫定: 0,
  暫定政権: 0,
  残虐: -1,
  残虐行為: -1,
  残業: -1,
  残酷: -1,
  残酷さ: -1,
  残暑: -1,
  残暑見舞い: 1,
  残像: 0,
  残敵: -1,
  残党: -1,
  残念: -1,
  残塁: 0,
  仕合わせ: 1,
  仕込み: 0,
  仕事: 0,
  仕事上: 0,
  仕事内容: 0,
  仕上がり: 0,
  仕上げ: 0,
  仕打ち: -1,
  仕置き: -1,
  仕入れ: 0,
  仕返し: -1,
  仕立て上がり: 0,
  使いすぎ: -1,
  使い回し: 0,
  使い捨て: 0,
  使い手: 0,
  使い勝手: 1,
  使途: 0,
  使役: -1,
  使用回数: 0,
  使用感: -1,
  使用者責任: 0,
  使用人: 0,
  使用制限: -1,
  使用頻度: 1,
  使用率: 0,
  使用料: -1,
  使用量: 1,
  刺客: -1,
  刺激: 0,
  刺殺: 0,
  刺傷: -1,
  司法: 0,
  史実: 1,
  史跡: 0,
  四つ: 0,
  四恩: 1,
  四季: 0,
  四季折々: 1,
  四季報: 0,
  四球: 0,
  四苦八苦: -1,
  四死球: 0,
  四時間: 0,
  四神: 0,
  四聖: 1,
  四年: 0,
  士気: 1,
  始まり: 1,
  始末: -1,
  始末書: -1,
  姉弟: 0,
  姿: 0,
  子ども達: 0,
  子育て支援: 1,
  子宮筋腫: -1,
  子宮内膜症: -1,
  子供っぽさ: -1,
  子供らしさ: 1,
  子供心: 0,
  子犬: 0,
  子守歌: 1,
  子種: 1,
  子孫繁栄: 1,
  子役: 0,
  屍: -1,
  市街戦: -1,
  市況: 0,
  市場シェア: 1,
  市場価格: 0,
  市場心理: 0,
  市場調査: 0,
  市内通話料金: 0,
  市民活動: 0,
  市民参加: 0,
  師恩: 1,
  師匠: 1,
  志: 1,
  志なかば: -1,
  志気: 1,
  志向: 0,
  志操: 1,
  思いやり: 1,
  思い違い: -1,
  思い過ごし: -1,
  思い込み: -1,
  思い出: 1,
  思い出し笑い: 0,
  思い切り: 1,
  思い当たり: 0,
  思い入れ: 0,
  思い付き: 0,
  思う存分: 0,
  思う様: 0,
  思案: 0,
  思案投げ首: -1,
  思考能力: 1,
  思考力: 1,
  思索: 0,
  思想: 0,
  思念: 0,
  思惑: 0,
  指しゃぶり: -1,
  指針: 1,
  指摘: -1,
  指導: 0,
  指導案: 0,
  指導員: 0,
  指導者: 1,
  指導力: 1,
  指南: 0,
  指紋: 0,
  支え: 1,
  支援: 1,
  支援措置: 0,
  支援体制: 1,
  支給: 1,
  支持: 1,
  支出: -1,
  支所: 0,
  支障: -1,
  支点: 0,
  支配: -1,
  支配権: 1,
  支配力: 1,
  支払い: 0,
  支払義務: 0,
  支離滅裂: -1,
  施策: 0,
  施術: 0,
  施設内: 0,
  旨: 0,
  旨さ: 1,
  旨み: 1,
  旨味: 1,
  枝毛: -1,
  枝葉: 0,
  枝葉末節: -1,
  止めど: 0,
  止血: 0,
  死: -1,
  死に場所: -1,
  死に神: -1,
  死に体: -1,
  死骸: -1,
  死角: -1,
  死期: -1,
  死球: -1,
  死去: -1,
  死刑: -1,
  死刑判決: -1,
  死語: -1,
  死罪: -1,
  死産: -1,
  死者: -1,
  死出の旅: -1,
  死傷者: -1,
  死神: 0,
  死人: -1,
  死線: -1,
  死相: -1,
  死体: -1,
  死地: -1,
  死闘: -1,
  死肉: 0,
  死病: -1,
  死亡: -1,
  死亡者: -1,
  死没: -1,
  死魔: -1,
  死滅: -1,
  死力: 0,
  死霊: -1,
  氏神: 0,
  氏名等: 0,
  獅子舞: 0,
  獅子奮迅: 1,
  私怨: -1,
  私曲: -1,
  私権: 0,
  私見: 0,
  私恨: -1,
  私傷: -1,
  私情: -1,
  私信: 0,
  私徳: 1,
  糸目: 0,
  紙詰まり: -1,
  紫班病: -1,
  脂肪: -1,
  脂肪肝: -1,
  至り: 0,
  至れり尽くせり: 1,
  至極: 1,
  至芸: 1,
  至上: 1,
  至情: 1,
  至難の業: -1,
  至福: 1,
  至妙: 1,
  視覚: 0,
  視覚障害: -1,
  視覚障害者: 0,
  視線: -1,
  視聴覚: 0,
  視聴者: 0,
  視野: 1,
  視野狭窄: -1,
  視力: 1,
  視力障害: -1,
  視力低下: -1,
  詩境: 0,
  詩興: 1,
  詩情: 1,
  詩神: 0,
  詩人: 0,
  試飲: 0,
  試技: 0,
  試供: 0,
  試験: -1,
  試乗: 0,
  試食: 0,
  試問: 0,
  試練: 0,
  資格: 0,
  資格取得: 0,
  資金: 1,
  資金援助: 1,
  資金繰り: -1,
  資金需要: 1,
  資金難: -1,
  資金不足: -1,
  資金面: 0,
  資源: 1,
  資産: 1,
  資産デフレ: 0,
  資質: 1,
  資本: 1,
  資本力: 1,
  資力: 1,
  雌伏: -1,
  歯がゆさ: -1,
  歯ぎしり: -1,
  歯ごたえ: 1,
  歯垢: -1,
  歯周病: -1,
  歯石: -1,
  歯槽膿漏: -1,
  歯痛: -1,
  歯肉炎: -1,
  歯並び: 0,
  歯磨き粉: 0,
  事: 0,
  事案: 0,
  事業化: 0,
  事業活動: 0,
  事業実施: 0,
  事業推進: 0,
  事業展開: 0,
  事件: -1,
  事件事故: -1,
  事故: -1,
  事故死: -1,
  事故等: -1,
  事故発生: -1,
  事故防止: 0,
  事後: 0,
  事後承諾: -1,
  事後報告: 0,
  事細か: 1,
  事実: 1,
  事実誤認: -1,
  事実無根: -1,
  事態: -1,
  事犯: -1,
  事変: -1,
  事由: 0,
  似たようなの: 0,
  似非: -1,
  児童ポルノ: -1,
  児童委員: 0,
  児童虐待: -1,
  児童買春: -1,
  字状: 0,
  慈しみ: 1,
  慈愛: 1,
  慈悲: 1,
  慈悲深さ: 1,
  持ち金: 1,
  持ち合い: 0,
  持ち出し: 0,
  持ち逃げ: -1,
  持ち味: 1,
  持久力: 1,
  持続: 1,
  持続性: 1,
  持病: -1,
  時運: 0,
  時価: 0,
  時間: 0,
  時間不足: -1,
  時限爆弾: 'o',
  時効: -1,
  時差: -1,
  時差ぼけ: -1,
  時差ボケ: -1,
  時事ネタ: 1,
  時代考証: 0,
  時代錯誤: -1,
  時代性: 0,
  時代遅れ: -1,
  次作: 0,
  次善: 1,
  次男: -1,
  滋味: 0,
  治安: 1,
  治外法権: 0,
  治癒: 1,
  治療: 0,
  治療効果: 1,
  治療方針: 0,
  痔: -1,
  痔核: -1,
  痔瘻: -1,
  磁器: 0,
  磁気: 0,
  磁性: 0,
  磁束: 0,
  磁力: 0,
  示唆: 0,
  耳下腺炎: -1,
  耳障り: -1,
  耳鳴り: -1,
  自愛: 0,
  自意識: 1,
  自衛: 0,
  自衛隊派兵: 0,
  自演: 0,
  自家中毒: -1,
  自家撞着: 1,
  自我: 1,
  自覚: 1,
  自覚症状: 1,
  自棄: -1,
  自虐: -1,
  自虐史観: -1,
  自給: 0,
  自彊: 1,
  自業自得: -1,
  自己管理: 0,
  自己管理能力: 1,
  自己犠牲: -1,
  自己教育力: 1,
  自己決定権: 1,
  自己決定能力: 1,
  自己嫌悪: -1,
  自己治癒力: 1,
  自己実現: 0,
  自己中心性: 0,
  自己表現: 0,
  自己表現力: 1,
  自己矛盾: -1,
  自己免疫疾患: -1,
  自国語: 0,
  自在: 1,
  自作自演: -1,
  自殺: -1,
  自殺者: -1,
  自殺点: -1,
  自治: 0,
  自治権: 1,
  自治政府: 0,
  自室: 1,
  自主: 1,
  自主回収: -1,
  自主財源: 1,
  自主自立: 0,
  自主性: 1,
  自主性自律性: 1,
  自重: 1,
  自粛: -1,
  自照: 0,
  自信: 1,
  自制: 0,
  自然: 0,
  自然さ: 0,
  自然光: 1,
  自然災害: -1,
  自然治癒力: 1,
  自然食: 1,
  自然選択: 0,
  自然体: 1,
  自然淘汰: 1,
  自然破壊: -1,
  自然保護: 0,
  自然林: 0,
  自尊: 0,
  自尊心: 1,
  自損: -1,
  自堕落: -1,
  自動車事故: -1,
  自動車保険: 0,
  自任: 0,
  自認: 0,
  自白: 0,
  自爆: -1,
  自爆テロ: -1,
  自爆攻撃: -1,
  自発性: 1,
  自負: 0,
  自負心: 1,
  自腹: 0,
  自分: 0,
  自分らしさ: 1,
  自分勝手: -1,
  自閉症: -1,
  自暴自棄: -1,
  自慢: 0,
  自民党政権: 0,
  自明: 1,
  自由: 1,
  自由競争: 0,
  自由刑: -1,
  自由研究: 0,
  自由自在: 1,
  自由度: 1,
  自律: 0,
  自律神経失調症: -1,
  自律性: 1,
  自立: 1,
  自立心: 1,
  自立性: 1,
  自力: 0,
  自恃: 0,
  辞意: -1,
  辞職: 0,
  辞退: 0,
  辞任: 0,
  識字力: 1,
  識別力: 1,
  雫: 0,
  七難: -1,
  七難八苦: -1,
  七日: 0,
  七年: 0,
  七面倒: -1,
  叱正: 0,
  叱声: -1,
  叱責: -1,
  叱咤: -1,
  執行: 0,
  執行停止: 0,
  執心: -1,
  執着: -1,
  執刀: 0,
  執念: -1,
  執筆: 0,
  執拗: -1,
  失意: -1,
  失格: -1,
  失脚: -1,
  失業: -1,
  失業給付: 1,
  失業者: -1,
  失敬: -1,
  失血: -1,
  失言: -1,
  失語症: -1,
  失効: -1,
  失策: -1,
  失笑: -1,
  失心: -1,
  失神: -1,
  '失神・失心': -1,
  失政: -1,
  失速: -1,
  失態: -1,
  失着: -1,
  失調: -1,
  失点: -1,
  失投: -1,
  失当: -1,
  失敗: -1,
  失望: -1,
  失望感: -1,
  失明: -1,
  失礼: -1,
  失恋: -1,
  嫉視: -1,
  嫉妬: -1,
  湿り気: 0,
  湿気: 0,
  湿疹: -1,
  湿性: 0,
  疾患: -1,
  疾走感: 1,
  疾病: -1,
  疾風: 1,
  質: 1,
  質屋: 0,
  質感: 1,
  質疑: 0,
  質実: 1,
  質素: 0,
  質的向上: 1,
  質朴: 1,
  質問: 0,
  質問攻め: -1,
  実: 1,
  実のところ: 0,
  実り: 1,
  実意: 0,
  実益: 1,
  実演: 0,
  実害: -1,
  実感: 0,
  実刑: -1,
  実験室: 0,
  実現: 1,
  実効: 1,
  実効性: 1,
  実行: 0,
  実行速度: 1,
  実行力: 1,
  実際: 0,
  実質: 0,
  実習: 0,
  実証: 0,
  実績: 1,
  実践: 0,
  実践力: 1,
  実損: -1,
  実体験: 0,
  実態: 0,
  実地: 0,
  実費: 0,
  実務能力: 1,
  実名: 0,
  実用: 0,
  実用性: 1,
  実利: 1,
  実力: 1,
  実力差: 0,
  実力者: 1,
  実力主義: 1,
  実力派: 1,
  実例: 1,
  実話: 1,
  縞模様: 0,
  写真家: 0,
  捨て鉢: -1,
  煮汁: 1,
  社運: 0,
  社会参加: 0,
  社会性: 1,
  社会正義: 1,
  社会生活: 0,
  社会的合意: 1,
  社会的弱者: -1,
  社会的地位: 1,
  社会的評価: 1,
  社会不安: -1,
  社会復帰: 0,
  社会福祉: 0,
  社交: 0,
  社交性: 0,
  謝意: 1,
  謝恩: 1,
  謝礼: 1,
  車室内: 0,
  車上荒らし: -1,
  車酔い: -1,
  遮断: -1,
  蛇: 0,
  蛇行: -1,
  邪: -1,
  邪悪: -1,
  邪気: -1,
  邪鬼: -1,
  邪曲: -1,
  邪見: -1,
  邪神: 0,
  邪推: -1,
  邪知: -1,
  邪念: -1,
  邪魔: -1,
  邪魔者: -1,
  邪魔物: -1,
  '邪魔物・邪魔者': -1,
  邪魔立て: -1,
  邪欲: -1,
  邪恋: -1,
  借り: -1,
  借りてきた猫のよう: 0,
  借金: -1,
  借金返済: 0,
  借財: -1,
  釈迦: 1,
  釈然: 0,
  若々しさ: 1,
  若さ: 1,
  若気の過ち: -1,
  若気の至り: -1,
  若者: 1,
  若返り: 1,
  若葉マーク: -1,
  寂: -1,
  寂しさ: -1,
  寂声: -1,
  寂滅: -1,
  寂寥: -1,
  寂寥感: -1,
  弱: -1,
  弱さ: -1,
  弱み: -1,
  弱音: -1,
  弱化: -1,
  弱気: -1,
  弱腰: -1,
  弱者: -1,
  弱小: -1,
  弱体: -1,
  弱敵: -1,
  弱点: -1,
  弱肉強食: 0,
  主: 0,
  主イエス: 0,
  主恩: 1,
  主我: 0,
  主観: 0,
  主観性: 0,
  主義主張: 0,
  主計: 0,
  主罪: -1,
  主従: -1,
  主従関係: 1,
  主神: 1,
  主人公: 0,
  主税: 0,
  主線: 0,
  主体性: 0,
  主題歌: 1,
  主張: 0,
  主調: 0,
  主導権争い: -1,
  主役: 0,
  取りこぼし: -1,
  取り越し苦労: -1,
  取り下げ: 0,
  取り壊し: 0,
  取り合い: 0,
  取り消し: -1,
  取り繕い: 0,
  取り組み: 0,
  取り調べ: 0,
  取り柄: 1,
  取り返し: 0,
  取り留め: 0,
  取引: -1,
  取引価格: 0,
  取消: -1,
  取締役: 1,
  取得: 0,
  守り神: 0,
  守り本尊: 0,
  守株: 0,
  守旧: 0,
  守護: 0,
  守護神: 0,
  守秘義務: 0,
  守備力: 1,
  手: 0,
  手がかり: 1,
  手ごたえ: 1,
  手だれ: 1,
  手の内: 0,
  手ほどき: 0,
  手まめ: 0,
  手ブレ: -1,
  手違い: -1,
  手一杯: -1,
  手引き: 0,
  手下: 0,
  手加減: 0,
  手掛かり: 1,
  手間: -1,
  手詰まり: -1,
  手筋: 0,
  手軽: 1,
  手軽さ: 1,
  手口: -1,
  手向かい: -1,
  手込め: -1,
  手頃: 1,
  手作り: 0,
  手仕事: 0,
  手持ち無沙汰: 0,
  手術: 0,
  手順: 0,
  手助け: 1,
  手錠: -1,
  手数: -1,
  手数料: -1,
  手前勝手: -1,
  手造り: 1,
  手足: 0,
  手足口病: -1,
  手短: 0,
  手段: 1,
  手遅れ: -1,
  手伝い: 0,
  手渡し: 0,
  手刀: -1,
  手当て: 0,
  手動: 0,
  手抜かり: -1,
  手抜き: -1,
  手不足: -1,
  手柄: 1,
  手癖: -1,
  手放し: 0,
  手落ち: -1,
  手利き: 1,
  手練: 1,
  手腕: 1,
  朱色: 0,
  殊勝: 1,
  種まき: 0,
  種子: 0,
  種切れ: -1,
  種痘: 0,
  種類: 0,
  腫: -1,
  腫れ: -1,
  腫れ物: -1,
  腫脹: -1,
  腫物: -1,
  腫瘍: -1,
  趣: 1,
  趣き: 1,
  趣向: 1,
  趣味: 0,
  酒飲み: -1,
  酒気: -1,
  酒色: -1,
  酒神: 0,
  酒税: 0,
  酒癖: -1,
  酒乱: -1,
  首肯: 0,
  首吊り: -1,
  受け口: 0,
  受け入れ: 0,
  受け売り: 0,
  受給: 1,
  受検技術: 0,
  受験競争: -1,
  受験者数: 0,
  受験生: 0,
  受験戦争: -1,
  受賞: 1,
  受診: 0,
  受諾: 0,
  受注: 0,
  受動喫煙: -1,
  受難: -1,
  受領: 1,
  呪: -1,
  呪い: -1,
  呪術: -1,
  呪縛: -1,
  呪詛: -1,
  寿: 1,
  寿命: 0,
  授業: 0,
  授業研究: 0,
  授賞式: 1,
  樹海: -1,
  需給: 1,
  需要: 1,
  囚人: -1,
  収益: 1,
  収益性: 1,
  収益力: 1,
  収穫: 1,
  収穫期: 1,
  収穫祭: 1,
  収穫時期: 0,
  収拾: 0,
  収入: 1,
  収入印紙: 0,
  収容所: -1,
  収攬: 1,
  周: 0,
  周知: 0,
  周知徹底: 0,
  周到: 1,
  周密: 1,
  宗教色: 0,
  宗教心: 0,
  宗教性: 0,
  宗教戦争: 0,
  宗匠: 0,
  宗派: 0,
  就業: 0,
  就業機会: 0,
  就職: 0,
  就職難: -1,
  修交: 0,
  修好: 1,
  '修好・修交': 1,
  修行: 0,
  修士: 1,
  修身: 0,
  修正: 0,
  修正案: 0,
  修正箇所: -1,
  修正点: -1,
  修得: 0,
  修羅: -1,
  修羅場: -1,
  修理: 0,
  修了: 0,
  修煉: 0,
  愁い: -1,
  '愁い・愁え': -1,
  愁え: -1,
  愁傷: -1,
  愁訴: -1,
  愁嘆: -1,
  愁悲: -1,
  秀: 1,
  秀逸: 1,
  秀才: 1,
  秀作: 1,
  秀抜: 1,
  秀美: 1,
  秋: 0,
  秋寒: 0,
  秋気: -1,
  秋風: 0,
  終結: 0,
  終息: 0,
  終端: 0,
  終焉: -1,
  習慣: 0,
  習作: 0,
  習得: 1,
  習癖: 0,
  臭さ: -1,
  臭覚: 0,
  臭気: -1,
  臭味: -1,
  衆生: 0,
  襲撃: -1,
  週刊: 0,
  週報: 0,
  集団感染: -1,
  集団行動: 0,
  集中: 0,
  集中砲火: -1,
  集中力: 1,
  集注攻撃: 0,
  醜: -1,
  醜さ: -1,
  醜悪: -1,
  醜行: -1,
  醜女: -1,
  醜態: -1,
  醜聞: -1,
  醜名: -1,
  住まい: 0,
  住宅ローン: -1,
  住宅街: 0,
  住宅建設: 0,
  住宅事情: 0,
  住宅難: -1,
  充血: -1,
  充実: 1,
  充実感: 1,
  充足: 1,
  十五年: 0,
  十字架上: 0,
  十数年: 0,
  十全: 1,
  十二指腸潰瘍: -1,
  十二分: 1,
  十日: 0,
  十年一日: 0,
  十分: 1,
  従業員数: 0,
  従順: 1,
  '従順・柔順': 1,
  柔: -1,
  柔らかさ: 1,
  柔順: 1,
  柔軟: 1,
  柔軟さ: 1,
  柔軟性: 1,
  柔和: 1,
  汁: 0,
  汁気: 0,
  渋さ: 1,
  渋み: -1,
  渋め: 1,
  渋滞: -1,
  獣: -1,
  獣欲: -1,
  縦割り: -1,
  縦割り行政: -1,
  重き: 0,
  重し: -1,
  重ね重ね: 0,
  重圧: -1,
  重恩: 1,
  重荷: -1,
  重過失: -1,
  重患: -1,
  重苦しさ: -1,
  重刑: -1,
  重厚: 1,
  重厚さ: 1,
  重罪: -1,
  重重: 1,
  重傷: -1,
  重症: -1,
  重症化: -1,
  重症急性呼吸器症候群: -1,
  重税: -1,
  重石: 0,
  重体: -1,
  重態: -1,
  '重態・重体': -1,
  重大: 0,
  重大事件: -1,
  重大事故: -1,
  重点化: 1,
  重度: -1,
  重篤: -1,
  重罰: -1,
  重犯: -1,
  重病: -1,
  重複: -1,
  重宝: 1,
  '重宝・調法': 1,
  重要: 0,
  重要性: 0,
  重量感: 1,
  重力: 0,
  重労働: -1,
  銃撃: -1,
  銃撃戦: -1,
  銃弾: 0,
  夙成: 1,
  宿意: 0,
  宿怨: -1,
  宿題: -1,
  宿敵: -1,
  宿便: -1,
  宿命: 0,
  淑徳: 1,
  祝い: 1,
  祝意: 1,
  祝賀: 1,
  祝儀: 1,
  祝言: 1,
  祝辞: 1,
  祝勝: 1,
  祝福: 1,
  縮減: 0,
  粛正: -1,
  粛清: -1,
  熟女: 0,
  熟成: 0,
  熟成期間: 0,
  熟練: 1,
  熟練者: 1,
  出し: 0,
  出し投げ: 0,
  出っ歯: -1,
  出雲の神: 0,
  出演作: 0,
  出荷時期: 0,
  出荷量: 1,
  出過ぎ: -1,
  出会い: 1,
  出玉: 0,
  出稽古: 0,
  出欠: 0,
  出血: 0,
  出血多量: -1,
  出血量: -1,
  出産: 0,
  出資: 1,
  出自: 0,
  出場: 0,
  出場停止: -1,
  出色: 1,
  出水: -1,
  出世: 1,
  出世作: 1,
  出精: 0,
  出席: 0,
  出題: 0,
  出遅れ: -1,
  出入り: 0,
  出入り禁止: -1,
  出馬: 0,
  出版権: 0,
  出番: 1,
  出費: -1,
  出不精: -1,
  出癖: -1,
  出来: 1,
  出来過ぎ: -1,
  出来具合: 0,
  出来事: 0,
  出来損ない: -1,
  出来物: -1,
  出藍の誉れ: 1,
  出力: 0,
  俊: 1,
  俊英: 1,
  俊傑: 1,
  俊才: 1,
  '俊才・駿才': 1,
  俊秀: 1,
  俊足: 1,
  春: 1,
  春らしさ: 1,
  春寒: 0,
  春情: -1,
  春風駘蕩: 1,
  瞬発力: 1,
  駿才: 1,
  循環型社会: 0,
  循環障害: -1,
  旬: 1,
  旬刊: 0,
  楯: 0,
  殉教者: -1,
  準備: 0,
  準備期間: 0,
  準備不足: -1,
  準優勝: 1,
  潤い: 1,
  潤滑: 1,
  潤沢: 1,
  純愛: 1,
  純化: 0,
  純潔: 1,
  純情: 1,
  純真: 1,
  純真無垢: 1,
  純粋: 1,
  純粋さ: 1,
  純正: 1,
  純朴: 1,
  遵守: 1,
  醇化: 0,
  '醇化・純化': 0,
  醇美: 1,
  順逆: 0,
  順調: 1,
  順番待ち: 0,
  順良: 1,
  処遇: 0,
  処刑: 0,
  処女: 0,
  処女作: 0,
  処罰: -1,
  処分: -1,
  処理速度: 1,
  処理能力: 1,
  処理落ち: -1,
  初々しさ: 1,
  初学者: -1,
  初刊: 0,
  初感染: -1,
  初耳: 0,
  初勝利: 1,
  初心者: 0,
  初心者マーク: 0,
  初体験: 1,
  初犯: -1,
  初恋: 1,
  所持金: 1,
  所定労働時間: 0,
  所得: 1,
  所払い: -1,
  所有者: 0,
  所要: 0,
  所領: 1,
  暑さ: -1,
  暑気払い: 0,
  暑中見舞い: 0,
  署名: 0,
  署名運動: 0,
  署名活動: 0,
  書き込みエラー: -1,
  書き込み数: 1,
  書き出し: 0,
  書き直し: 0,
  書き味: 1,
  書類審査: 0,
  諸経費: 0,
  諸費用: 0,
  助: 0,
  助け: 1,
  助け合い: 0,
  助け船: 1,
  助演者: 0,
  助教授: 0,
  助教諭: 0,
  助言: 0,
  助手: 0,
  助勢: 0,
  助成: 0,
  助成金: 1,
  助太刀: 0,
  助長: -1,
  助命: 0,
  助力: 0,
  叙情性: 1,
  女の子らしさ: 1,
  女らしさ: 1,
  女王: 0,
  女傑: 1,
  女殺し: 0,
  女手: 0,
  女神: 0,
  女人禁制: 0,
  女人結界: 0,
  女性: 0,
  女性らしさ: 1,
  女性差別: -1,
  女声: 0,
  女装: 0,
  女日照り: -1,
  女癖: -1,
  女冥利: 1,
  女優: 0,
  序章: 0,
  除外: 0,
  除草: 0,
  除名: -1,
  傷: -1,
  傷だらけ: -1,
  傷み: -1,
  傷害: -1,
  傷害事件: -1,
  傷害致死: -1,
  傷口: -1,
  傷痕: -1,
  傷者: -1,
  傷心: -1,
  傷跡: -1,
  傷病: -1,
  傷病兵: -1,
  勝ち: 1,
  勝ち越し: 1,
  勝ち残り: 1,
  勝ち戦: 1,
  勝運: 1,
  勝機: 1,
  勝者: 1,
  勝手: -1,
  勝手気まま: -1,
  勝手次第: -1,
  勝負強さ: 1,
  勝報: 1,
  勝利: 1,
  勝率: 1,
  匠: 1,
  商運: 1,
  商況: 1,
  商業主義: -1,
  商魂: 0,
  商取引: 1,
  商状: 0,
  商売繁盛: 1,
  商品: 0,
  商品価値: 1,
  商品券: 1,
  唱歌: 0,
  奨学: 0,
  奨学金: 0,
  奨励: 0,
  奨励金: 1,
  奨励賞: 1,
  将軍: 1,
  将来: 1,
  将来性: 1,
  将来不安: -1,
  小ささ: 0,
  小じわ: -1,
  小またすくい: 0,
  小ネタ: 0,
  小一時間: 0,
  小宇宙: 0,
  小過: -1,
  小我: 0,
  小技: 1,
  小休止: 0,
  小競り合い: -1,
  小型: 1,
  小型軽量: 1,
  小型軽量化: 0,
  小径: -1,
  小遣い: 1,
  小遣いかせぎ: 0,
  小言: -1,
  小細工: -1,
  小事: -1,
  小成: 0,
  小声: 0,
  小節: 0,
  小走り: 0,
  小太り: -1,
  小町: 0,
  小腸: 0,
  小敵: -1,
  小難: -1,
  小物: -1,
  小変: -1,
  小便: 0,
  小味: -1,
  小役: 0,
  少子化: 0,
  少数者: 0,
  少年たち: 0,
  少年期: 0,
  少年犯罪: -1,
  少年法: 0,
  床ずれ: -1,
  床下: 0,
  承諾: 0,
  承認: 0,
  招待: 0,
  招待券: 1,
  掌握: 1,
  昇進: 1,
  松山: 0,
  消化吸収: 0,
  消化不良: -1,
  消極: -1,
  消失: -1,
  消沈: -1,
  消毒: 0,
  消費: 0,
  消費意欲: 0,
  消費拡大: 0,
  消費支出: 0,
  消費者: 0,
  消費者被害: -1,
  消費税: 0,
  消費電力: -1,
  消耗: -1,
  消耗戦: -1,
  焼きたて: 1,
  焼きもち: -1,
  焼ける: -1,
  焼失: -1,
  焦り: -1,
  焦燥: -1,
  焦燥感: -1,
  焦点: 0,
  焦眉の急: -1,
  照れ: 0,
  照れ笑い: 0,
  照れ性: 0,
  照準: 0,
  症: -1,
  症候群: -1,
  症例: -1,
  省エネ: 1,
  省エネルギー: 1,
  省スペース: 1,
  省スペース性: 1,
  称号: 1,
  称賛: 1,
  称美: 1,
  称揚: 1,
  '称揚・賞揚': 1,
  笑: 0,
  笑い: 1,
  笑い顔: 1,
  笑い事: 1,
  笑い声: 1,
  笑み: 1,
  笑顔: 1,
  笑声: 1,
  紹介: 0,
  紹介ページ: 0,
  紹興酒: 0,
  衝撃: -1,
  衝撃音: 0,
  衝撃波: -1,
  衝動: -1,
  衝突: -1,
  衝突事故: 0,
  証: 0,
  証拠: 0,
  証言: 0,
  証人: 0,
  証明: 0,
  証明力: 0,
  詳細: 0,
  詳報: 0,
  象徴: 0,
  象皮病: -1,
  賞: 1,
  賞金: 1,
  賞賛: 1,
  '賞賛・称賛': 1,
  賞状: 1,
  賞美: 1,
  '賞美・称美': 1,
  賞品: 1,
  賞揚: 1,
  鍾愛: 1,
  障り: -1,
  障害: -1,
  障害基礎年金: 1,
  障碍: -1,
  障壁: -1,
  上がり気味: 0,
  上げ膳据え膳: 0,
  上の上: 1,
  上り調子: 1,
  上下動: 0,
  上機嫌: 1,
  上靴: 0,
  上限: 0,
  上向き: 1,
  上告: 0,
  上作: 1,
  上質: 1,
  上質感: 1,
  上手: 1,
  上手さ: 1,
  上手回し: 0,
  上手投げ: 0,
  上昇率: 0,
  上乗せ: 0,
  上澄み: 0,
  上達: 1,
  上品: 1,
  上品さ: 1,
  上洛: 0,
  上履き: 0,
  丈夫: 1,
  丈夫さ: 1,
  乗り換え: 0,
  乗り気: 0,
  乗り物酔い: -1,
  乗客: 0,
  冗談: -1,
  冗談抜き: 0,
  剰余金: 0,
  城門: 0,
  場つなぎ: -1,
  場違い: -1,
  場外ホームラン: 0,
  場所ふさぎ: -1,
  場打て: -1,
  常: 0,
  常識: 1,
  常識外れ: -1,
  常識的: 1,
  常勝: 1,
  常人: 0,
  常套: 0,
  常連: 0,
  情: 1,
  情けなさ: -1,
  情け知らず: -1,
  情け無用: -1,
  情愛: 1,
  情意: 0,
  情炎: 0,
  情火: 0,
  情感: 1,
  情誼: 1,
  情況: 0,
  情合い: 1,
  情実: 0,
  情緒: 1,
  情緒障害: -1,
  情状酌量: 0,
  情痴: -1,
  情調: 0,
  情動: 0,
  情熱: 1,
  情念: 0,
  情報: 1,
  情報開示: 0,
  情報活用能力: 1,
  情報源: 0,
  情報公開: 0,
  情報処理能力: 1,
  情報操作: -1,
  情報提供: 0,
  情報不足: -1,
  情報満載: 1,
  情報漏洩: -1,
  情欲: -1,
  擾乱: -1,
  条件反射: 0,
  条約: 0,
  条理: 1,
  条例: 0,
  浄化: 0,
  浄財: 1,
  浄土: 1,
  状況: 0,
  状態: 0,
  状態異常: -1,
  蒸し暑さ: -1,
  蒸し返し: -1,
  蒸気: 0,
  蒸発: 0,
  譲位: 0,
  醸成: 0,
  飾り: 0,
  植皮: 0,
  植民地化: -1,
  植民地支配: -1,
  植毛: 0,
  職業訓練: 0,
  職業能力: 1,
  職種: 0,
  職人: 1,
  職人芸: 1,
  職責: 0,
  職能: 0,
  色っぽさ: 1,
  色とりどり: 0,
  色覚異常: -1,
  色感: 1,
  色気: 1,
  色香: 0,
  色合い: 0,
  色彩: 1,
  色彩感覚: 1,
  色女: 1,
  色情: -1,
  色数: 0,
  色鮮やか: 1,
  色素沈着: 0,
  色男: 1,
  色調: 1,
  色欲: -1,
  色落ち: -1,
  触れ合い: 0,
  触覚: 0,
  触感: 0,
  食: 0,
  食い意地: 0,
  食い違い: -1,
  食い過ぎ: -1,
  食い気: 1,
  食い合わせ: -1,
  食い扶持: -1,
  食い料: -1,
  食べかす: -1,
  食べすぎ: -1,
  食べず嫌い: -1,
  食べもの: 1,
  食べ過ぎ: -1,
  食わず嫌い: -1,
  食感: 1,
  食言: -1,
  食思: 1,
  食事療法: 0,
  食傷: -1,
  食卓: 0,
  食中毒: -1,
  食中毒事件: -1,
  食通: 1,
  食当たり: -1,
  食道がん: -1,
  食道楽: 1,
  食費: 0,
  食用油: 0,
  食欲: 1,
  食欲不振: -1,
  食料不足: -1,
  食糧難: -1,
  食糧不足: -1,
  伸び: 0,
  伸びやか: 1,
  伸び悩み: -1,
  伸張: 0,
  伸展: 0,
  信: 1,
  信義: 1,
  信仰: 0,
  信仰心: 1,
  信実: 1,
  信心: 1,
  信任: 0,
  信認: 0,
  信念: 1,
  信奉: 1,
  信望: 1,
  信用: 1,
  信用不安: -1,
  信頼: 1,
  信頼回復: 1,
  信頼感: 1,
  信頼関係: 1,
  信頼性: 1,
  信憑: 1,
  信憑性: 1,
  侵害: -1,
  侵害行為: -1,
  侵攻: -1,
  侵食: -1,
  侵入: -1,
  侵入者: -1,
  侵略: -1,
  侵略行為: -1,
  侵略戦争: -1,
  寝たきり: -1,
  寝たきり老人: -1,
  寝だめ: 0,
  寝ばれ: -1,
  寝汗: -1,
  寝技: 0,
  寝言: -1,
  寝室: 0,
  寝食: 0,
  寝息: 0,
  寝不足: -1,
  寝癖: -1,
  寝返り: 0,
  寝坊: -1,
  審議: 0,
  審査員: 0,
  審査基準: 0,
  審美眼: 1,
  心意気: 1,
  心気症: -1,
  心技体: 0,
  心強さ: 1,
  心筋梗塞: -1,
  心懸かり: -1,
  心遣い: 1,
  心構え: 1,
  心細さ: -1,
  心算: 0,
  心残り: -1,
  心疾患: -1,
  心祝い: 1,
  心情: 0,
  心神喪失: -1,
  心神耗弱: -1,
  心身: 0,
  心身症: -1,
  心身障害者: 0,
  心尽くし: 1,
  心性: 0,
  心積もり: 0,
  心組み: 0,
  心臓マヒ: -1,
  心臓移植: 0,
  心臓疾患: -1,
  心臓発作: -1,
  心臓病: -1,
  心臓麻痺: -1,
  心待ち: 1,
  心地よさ: 1,
  心地良さ: 1,
  心置き: -1,
  心中: 0,
  心痛: -1,
  心停止: -1,
  心得違い: -1,
  心配: -1,
  心配り: 1,
  心配事: -1,
  心配性: -1,
  心拍数: -1,
  心肥大: -1,
  心不全: -1,
  心服: 1,
  心変わり: 0,
  心理学: 0,
  心霊: 0,
  心霊写真: -1,
  心労: -1,
  慎み: -1,
  慎重: 0,
  慎重さ: 1,
  慎重論: 0,
  慎独: -1,
  振興: 1,
  新: 0,
  新しさ: 1,
  新鋭: 1,
  新刊: 0,
  新患: -1,
  新歓: 1,
  新規: 0,
  新規性: 1,
  新旧: 0,
  新興住宅地: 0,
  新曲: 0,
  新型肺炎: -1,
  新婚旅行: 0,
  新作: 0,
  新参: 0,
  新参者: 0,
  新式: 0,
  新車: 1,
  新酒: 0,
  新情報: 0,
  新人: 0,
  新鮮: 1,
  新鮮さ: 1,
  新鮮味: 1,
  新増設: 0,
  新知識: 0,
  新築: 0,
  新築祝い: 1,
  新陳代謝: 1,
  新入: 0,
  新品: 1,
  新味: 1,
  新羅: 0,
  新来: 0,
  新緑: 1,
  晋: 0,
  森林破壊: -1,
  森林浴: 1,
  浸食: -1,
  浸水: -1,
  浸水被害: -1,
  浸透: 0,
  深さ: 0,
  深化: 0,
  深呼吸: 0,
  深刻: -1,
  深刻化: -1,
  深謝: 1,
  深甚: 1,
  深憂: -1,
  申し分: 0,
  真: 1,
  真っ向勝負: 0,
  真価: 1,
  真剣: 1,
  真剣勝負: 0,
  真骨頂: 1,
  真作: 1,
  真似: -1,
  真実: 1,
  真実性: 1,
  真実味: 1,
  真情: 1,
  真心: 1,
  真髄: 1,
  真善美: 1,
  真相解明: 0,
  真相究明: 0,
  真打ち: 1,
  真直ぐ: 1,
  真如: 0,
  真面目: 1,
  真理: 1,
  神: 0,
  神々しさ: 1,
  神さま: 0,
  神格: 1,
  神器: 1,
  神気: 0,
  神祇: 0,
  神経: 0,
  神経過敏: -1,
  神経質: -1,
  神経症: -1,
  神経症状: -1,
  神経障害: -1,
  神経衰弱: -1,
  神経痛: -1,
  神助: 1,
  神職: 0,
  神性: 1,
  神速: 1,
  神智: 1,
  神通力: 1,
  神田: 0,
  神徳: 1,
  神奈川: 0,
  神罰: -1,
  神秘: 1,
  神秘性: 1,
  神品: 1,
  神妙: 1,
  神明: 1,
  神様: 0,
  神霊: 0,
  神話: 0,
  秦: 0,
  臣節: 1,
  親しさ: 1,
  親しみ: 1,
  親の恩: 1,
  親ぼく: 1,
  親愛: 1,
  親衛: 1,
  親近: 0,
  親近感: 1,
  親交: 0,
  親孝行: 1,
  親心: 1,
  親身: 1,
  親水性: 0,
  親切: 1,
  親切さ: 1,
  親善: 1,
  親疎: 0,
  親知らず: 0,
  親睦: 1,
  親密: 1,
  親密さ: 1,
  親密感: 1,
  親友: 1,
  親類付き合い: 1,
  親和: 1,
  親和性: 0,
  診断治療: 0,
  診断名: 0,
  診療: 0,
  診療報酬: 1,
  身から出たさび: -1,
  身だしなみ: 1,
  身の程: -1,
  身軽: 1,
  身勝手: -1,
  身状: 1,
  身性: 0,
  身体症状: -1,
  身体障害: -1,
  身体障害者: 0,
  身長差: 0,
  身売り: -1,
  身分証明: 0,
  身方: 1,
  身命: 1,
  辛さ: 0,
  辛み: 0,
  辛苦: -1,
  辛口: -1,
  辛酸: -1,
  辛勝: 0,
  辛労: -1,
  辛労辛苦: -1,
  進運: 1,
  進化: 1,
  進攻: 0,
  進行: 0,
  進出: 0,
  進捗: 1,
  進展: 1,
  進入: 0,
  進歩: 1,
  進歩性: 1,
  進塁: 0,
  針: -1,
  針のむしろ: -1,
  針小棒大: -1,
  震え: -1,
  震え声: 0,
  震災: -1,
  震撼: -1,
  震駭: -1,
  人: 0,
  人おじ: -1,
  人ごみ: -1,
  人でなし: -1,
  人びと: 0,
  人為選択: 0,
  人為淘汰: 0,
  人員: 1,
  人員削減: -1,
  人格: 1,
  人間らしさ: 1,
  人間ドック: 0,
  人間嫌い: -1,
  人間性: 1,
  人間的魅力: 1,
  人間味: 1,
  人間力: 1,
  人気: 1,
  人気者: 1,
  人気商品: 1,
  人傑: 1,
  人件費: 0,
  人嫌い: -1,
  人権: 1,
  人権侵害: -1,
  人権問題: -1,
  人権擁護: 0,
  人見知り: -1,
  人交わり: 1,
  人口: 0,
  人口減少: -1,
  人口密度: 0,
  人工妊娠中絶: -1,
  人混み: -1,
  人妻: 1,
  人材: 0,
  人材育成: 0,
  人材不足: -1,
  人擦れ: 1,
  人事不省: -1,
  人手: 1,
  人手不足: -1,
  人種: 0,
  人種差別: -1,
  人情: 1,
  人情味: 1,
  人身事故: -1,
  人性: 0,
  人生: 1,
  人通り: 0,
  人的被害: -1,
  人当たり: 0,
  人徳: 1,
  人非人: -1,
  人付き合い: 1,
  人物: 0,
  人望: 1,
  人命: 1,
  人命救助: 0,
  人面獣心: -1,
  人目: 0,
  人力車: 0,
  人類愛: 1,
  人類滅亡: -1,
  人恋しさ: -1,
  仁: 1,
  仁愛: 1,
  仁義: 1,
  仁恵: 1,
  仁慈: 1,
  仁者: 1,
  仁恕: 1,
  仁徳: 1,
  刃こぼれ: -1,
  刃向かい: -1,
  刃傷ざた: -1,
  刃物: 0,
  塵: -1,
  塵肺: -1,
  甚だ: 0,
  甚大: 0,
  尽力: 0,
  腎炎: -1,
  腎機能障害: -1,
  腎臓: 0,
  腎臓病: -1,
  腎不全: -1,
  腎盂炎: -1,
  迅速: 1,
  陣痛: -1,
  陣容: 0,
  靭性: 1,
  酢っぱみ: 0,
  図書券: 0,
  図星: -1,
  厨: 0,
  厨房: 0,
  吹き出もの: -1,
  吹き出物: -1,
  吹雪: 0,
  垂れ流し: 0,
  推奨: 0,
  推称: 0,
  推賞: 0,
  '推賞・推称': 1,
  推進: 0,
  推薦: 1,
  推薦状: 1,
  推測: 0,
  推力: 1,
  水しぶき: 0,
  水ばれ: -1,
  水アカ: -1,
  水位: 0,
  水禍: -1,
  水害: -1,
  水気: 0,
  水源: 0,
  水戸黄門: 0,
  水垢: -1,
  水際作戦: 0,
  水子: -1,
  水質汚染: -1,
  水質汚濁: -1,
  水腫: -1,
  水準: 0,
  水蒸気爆発: 0,
  水神: 0,
  水切り: 0,
  水増し: 0,
  水虫: -1,
  水鳥: 0,
  水痘: -1,
  水頭症: -1,
  水道水: 0,
  水難: -1,
  水難事故: -1,
  水入らず: 1,
  水入り: -1,
  水不足: -1,
  水分子: 0,
  水分摂取: 0,
  水分補給: 0,
  水辺: 0,
  水膨れ: -1,
  水没: -1,
  水俣病: -1,
  水密: 0,
  水揚げ: 0,
  水溶性: 0,
  水漏れ: -1,
  水疱: -1,
  水疱瘡: -1,
  睡魔: 0,
  睡眠: 0,
  睡眠障害: -1,
  睡眠不足: -1,
  粋: 1,
  衰え: -1,
  衰運: -1,
  衰残: -1,
  衰弱: -1,
  衰退: -1,
  衰微: -1,
  衰亡: -1,
  衰滅: -1,
  遂行: 0,
  随一: 1,
  随喜: 1,
  随分: 0,
  髄膜炎: -1,
  崇: 1,
  崇敬: 1,
  崇拝: 1,
  数カ所: 0,
  数ヵ月: 0,
  数ヶ月: 0,
  数奇: -1,
  数時間: 0,
  数週間: 0,
  数十分: 0,
  数値予報: 0,
  数点: 0,
  数日: 0,
  菅原道真: 0,
  寸志: 1,
  世の常: 0,
  世界: 0,
  世界一: 1,
  世界一周: 1,
  世界観: 0,
  世界支配: -1,
  世界新: 1,
  世界進出: 1,
  世界戦争: -1,
  世界大戦: -1,
  世界平和: 1,
  世慣れ: 0,
  世間擦れ: 0,
  世間知らず: -1,
  世故: 0,
  世襲: -1,
  世俗: -1,
  世知: 0,
  世田谷: 0,
  世評: 1,
  世話: 0,
  是正: 0,
  凄さ: 0,
  凄まじさ: 0,
  凄艶: 1,
  制圧: -1,
  制球: 0,
  制球力: 1,
  制限: 0,
  制限速度: 0,
  制裁: -1,
  制止: -1,
  制度そのもの: 0,
  制度化: 0,
  制度疲労: -1,
  制覇: 1,
  制約: -1,
  制約条件: -1,
  勢い: 1,
  勢力: 1,
  勢力争い: -1,
  征服: 0,
  性: 0,
  性感: 0,
  性感染症: -1,
  性根: 1,
  性差: 0,
  性差別: -1,
  性情: 0,
  性的興奮: -1,
  性同一性障害: -1,
  性能: 1,
  性能差: 0,
  性能低下: -1,
  性犯罪: -1,
  性病: -1,
  性分: 0,
  性暴力: -1,
  性欲: 0,
  成育: 0,
  成果: 1,
  成業: 1,
  成功: 1,
  成功者: 1,
  成功体験: 1,
  成功報酬: 1,
  成就: 1,
  成就感: 1,
  成熟: 1,
  成熟期: 1,
  成心: 0,
  成人病: -1,
  成績: 1,
  成長: 1,
  成長ぶり: 1,
  成長性: 1,
  成長力: 1,
  成敗: -1,
  成仏: 1,
  成立: 0,
  政況: 0,
  政策転換: 0,
  政治参加: 0,
  政治犯: -1,
  政治不信: -1,
  政治腐敗: -1,
  政敵: 0,
  政変: 0,
  政務: 0,
  政略結婚: -1,
  整形手術: 0,
  整合: 0,
  整合性: 1,
  整備: 0,
  整備充実: 1,
  整備促進: 0,
  星空: 1,
  星目: -1,
  晴れやか: 1,
  晴れ晴れ: 1,
  晴天: 1,
  正: 1,
  正しさ: 1,
  正解: 1,
  正確: 1,
  正確さ: 1,
  正確性: 1,
  正規: 1,
  正義: 1,
  正義感: 1,
  正月気分: 1,
  正誤: 0,
  正邪: 0,
  正常: 1,
  正常化: 0,
  正真正銘: 0,
  正体: 0,
  正調: 1,
  正直: 1,
  正当: 1,
  正当化: -1,
  正当性: 1,
  正統: 1,
  正統性: 1,
  正念場: 0,
  正反対: 0,
  正否: 0,
  正銘: 1,
  正論: 1,
  清: 0,
  清々しさ: 1,
  清らか: 1,
  清潔: 1,
  清潔さ: 1,
  清潔感: 1,
  清純: 1,
  清祥: 1,
  清浄: 0,
  清浄無垢: 1,
  清水: 1,
  清澄: 1,
  清清: 1,
  清楚: 1,
  清掃: 0,
  清濁: 0,
  清貧: 1,
  清流: 1,
  清涼感: 1,
  清冽: 1,
  生: 0,
  生きがい: 1,
  生き甲斐: 1,
  生き残り: 1,
  生き神様: 0,
  生き体: 0,
  生き仏: 1,
  生き返り: 1,
  生き霊: 0,
  生ごみ: 0,
  生まれ変わり: 1,
  '生み・産みの苦しみ': -1,
  生みの苦しみ: -1,
  生ゴミ: -1,
  生意気: -1,
  生育: 0,
  生育障害: -1,
  生育量: 0,
  生涯: 0,
  生涯スポーツ: 0,
  生涯現役: 1,
  生活感: 0,
  生活苦: -1,
  生活空間: 0,
  生活指導: 0,
  生活支援: 0,
  生活習慣病: -1,
  生活難: -1,
  生活排水: -1,
  生活反応: 0,
  生活費: 1,
  生活保護: 1,
  生活力: 1,
  生還: 1,
  生気: 1,
  生彩: 1,
  生殺し: -1,
  生産: 0,
  生産意欲: 1,
  生産拡大: 0,
  生産拠点: 0,
  生産効率: 1,
  生産性: 1,
  生産性向上: 0,
  生産中止: 0,
  生産能力: 1,
  生産量: 1,
  生産力: 1,
  生死: 0,
  生糸: 0,
  生者: 0,
  生臭さ: -1,
  生殖機能: 1,
  生成: 0,
  生存: 1,
  生存者: 1,
  生体反応: 0,
  生長: 1,
  生徒たち: 1,
  生物時計: 0,
  生命: 1,
  生命反応: 0,
  生命力: 1,
  生命倫理: 1,
  生理: 0,
  生理現象: 0,
  生理痛: -1,
  生理日: 0,
  生理不順: -1,
  生霊: -1,
  盛り: 1,
  盛りだくさん: 1,
  盛り上がり: 1,
  盛ん: 1,
  盛運: 1,
  盛況: 1,
  盛者: 1,
  盛衰: 0,
  盛大: 1,
  精: 0,
  精鋭: 1,
  精華: 1,
  精確: 1,
  精気: 1,
  精強: 1,
  精巧: 1,
  精子: 0,
  精神汚染: -1,
  精神疾患: -1,
  精神障害: -1,
  精神障害者: 0,
  精神性: 1,
  精神的ダメージ: -1,
  精神的苦痛: -1,
  精神的疲労: -1,
  精神病: -1,
  精神病院: 0,
  精神分裂症: -1,
  精神分裂病: -1,
  精神崩壊: -1,
  精神力: 1,
  精進: 1,
  精美: 1,
  精密: 1,
  精密さ: 1,
  精妙: 1,
  精力: 1,
  精力絶倫: 1,
  精力的: 1,
  精励: 1,
  精霊: 1,
  精悍さ: 1,
  精緻: 1,
  聖: 1,
  聖恩: 1,
  聖歌: 0,
  聖賢: 1,
  聖者: 0,
  聖書: 0,
  聖女: 0,
  聖人: 0,
  聖水: 0,
  聖哲: 0,
  聖徳: 1,
  聖徳太子: 0,
  聖母: 0,
  聖母マリア: 1,
  聖夜: 0,
  聖霊: 1,
  声援: 1,
  声価: 0,
  声楽家: 0,
  声質: 0,
  声名: 0,
  声優: 0,
  西夏: 0,
  西哲: 1,
  西南戦争: -1,
  誠: 1,
  誠意: 1,
  誠実: 1,
  誠実さ: 1,
  誓約書: 0,
  逝去: -1,
  青い鳥: 0,
  青そこひ: -1,
  青びょうたん: -1,
  青鬼: -1,
  青空: 1,
  青臭さ: -1,
  青汁: 0,
  青春: 1,
  青少年: 0,
  青息: -1,
  青天の霹靂: 0,
  静: 1,
  静か: 0,
  静かさ: 1,
  静けさ: 1,
  静穏: 1,
  静止: 0,
  静寂: 0,
  静粛性: 1,
  静電気: 0,
  静脈瘤: -1,
  税金: 0,
  税収: 1,
  税収入: 1,
  税負担: -1,
  税務: 0,
  脆さ: -1,
  脆弱: -1,
  惜敗: -1,
  斥力: 0,
  石化: 0,
  石灰化: 0,
  石油: 0,
  石油ショック: -1,
  石油危機: -1,
  積もり: 0,
  積怨: -1,
  積極性: 0,
  積極的: 1,
  積雪: 0,
  脊髄カリエス: -1,
  責: -1,
  責め苦: -1,
  責任感: 1,
  責務: 0,
  赤い羽根: 1,
  赤ら顔: 0,
  赤禍: 0,
  赤鬼: 0,
  赤錆: -1,
  赤字: -1,
  赤字経営: -1,
  赤水: 0,
  赤潮: -1,
  赤点: -1,
  赤貧: -1,
  赤味: 0,
  赤面: 0,
  赤目: -1,
  赤痢: -1,
  跡: 0,
  切なさ: -1,
  切り捨て: -1,
  切り傷: -1,
  切り返し: 0,
  切れ: 0,
  切れ者: 1,
  切れ端: -1,
  切れ味: 1,
  切開: 0,
  切実: -1,
  切実さ: 0,
  切迫: -1,
  切腹: -1,
  拙攻: -1,
  拙作: -1,
  拙速: 0,
  接種: 0,
  接触不良: -1,
  接続性: 0,
  接着性: 1,
  接点: 1,
  摂取: 0,
  摂食: 0,
  摂食障害: -1,
  折り合い: 1,
  折り紙: 1,
  折り紙付き: 1,
  折檻: -1,
  設計者: 0,
  設題: 0,
  設定ミス: -1,
  設備: 1,
  設備投資: 0,
  設問: 0,
  窃取: -1,
  窃盗: -1,
  節: 0,
  節煙: 0,
  節義: 1,
  節減: 0,
  節酒: 0,
  節制: 0,
  節操: 1,
  節電: 0,
  節度: 1,
  節約: 0,
  説得: 0,
  説得力: 1,
  説明: 0,
  説明不足: -1,
  雪: 0,
  雪害: -1,
  雪駄: 0,
  雪道: 0,
  雪崩: -1,
  雪盲: -1,
  絶: -1,
  絶佳: 1,
  絶叫: 0,
  絶景: 1,
  絶後: 0,
  絶好: 1,
  絶好調: 1,
  絶賛: 1,
  絶勝: 1,
  絶唱: 0,
  絶息: -1,
  絶対音感: 1,
  絶頂: 1,
  絶版: -1,
  絶美: 1,
  絶望: -1,
  絶望感: -1,
  絶望的: -1,
  絶妙: 1,
  絶命: -1,
  絶滅: -1,
  絶倫: 1,
  舌なめずり: 0,
  舌禍: -1,
  舌鼓: 1,
  舌足らず: -1,
  舌打ち: -1,
  仙道: 0,
  先覚: 0,
  先覚者: 1,
  先駆者: 1,
  先見: 1,
  先見の明: 1,
  先見性: 1,
  先賢: 1,
  先攻: 0,
  先行き不透明感: -1,
  先細り: -1,
  先勝: 1,
  先進性: 1,
  先制: 0,
  先制ゴール: 1,
  先制パンチ: -1,
  先制攻撃: 0,
  先制点: 1,
  先生: 0,
  先太り: -1,
  先達: 0,
  先哲: 1,
  先天性: 0,
  先入観: -1,
  千辛万苦: -1,
  千人力: 1,
  千編一律: -1,
  千慮の一失: -1,
  占領: -1,
  宣旨: 0,
  宣戦布告: -1,
  宣撫: 0,
  専横: -1,
  専任講師: 0,
  専売特許: 0,
  専門: 1,
  専門医: 1,
  専門家: 0,
  専門性: 1,
  専門知識: 1,
  専門的知識: 1,
  専門的能力: 1,
  専門能力: 1,
  川上: 0,
  戦意: 1,
  戦果: 1,
  戦火: -1,
  戦禍: -1,
  戦況: 0,
  戦後: 0,
  戦後復興: 0,
  戦功: 1,
  戦災: -1,
  戦士: 0,
  戦死: -1,
  戦死者: -1,
  戦勝: 1,
  戦場: -1,
  戦績: 1,
  戦線離脱: 0,
  戦争: -1,
  戦争責任: -1,
  戦争反対: 0,
  戦争犯罪: -1,
  戦闘: -1,
  戦闘能力: 1,
  戦闘力: 1,
  戦敗: -1,
  戦犯: -1,
  戦費: -1,
  戦没: -1,
  戦友: 0,
  戦略性: 1,
  戦力: 1,
  浅見: -1,
  浅知恵: -1,
  洗い物: 0,
  洗浄: 0,
  洗浄力: 1,
  洗濯物: 0,
  洗礼: 0,
  洗練: 1,
  染色: 0,
  潜在意識: 0,
  潜在能力: 0,
  煽り: -1,
  穿刺: 0,
  穿鑿好き: -1,
  線維化: 0,
  線画: 0,
  繊細: 1,
  繊細さ: 1,
  羨望: 0,
  船酔い: -1,
  船霊: 0,
  選び: 0,
  選り好み: -1,
  選球: 0,
  選挙協力: 0,
  選曲: 0,
  選考: 0,
  選考会: 0,
  選奨: 1,
  選択科目: 0,
  選抜: 0,
  遷化: -1,
  閃光: 0,
  鮮やか: 1,
  鮮やかさ: 1,
  鮮度: 1,
  鮮明: 1,
  鮮烈: 1,
  前科: -1,
  前景気: 0,
  前後関係: 0,
  前座: 0,
  前作: 0,
  前祝い: 0,
  前進: 1,
  前人未到: 0,
  前代未聞: 0,
  前途: 1,
  前評判: 0,
  前立腺: 0,
  前立腺がん: -1,
  前立腺癌: -1,
  前立腺肥大: -1,
  前立腺肥大症: -1,
  善: 1,
  善し悪し: 0,
  善意: 1,
  善因善果: 0,
  善玉: 0,
  善行: 1,
  善政: 1,
  善戦: 1,
  善美: 1,
  漸騰: -1,
  漸落: -1,
  然諾: 0,
  全く: 0,
  全壊: -1,
  全快: 1,
  全開: 0,
  全額: 0,
  全国制覇: 1,
  全国統一: 0,
  全勝: 1,
  全焼: 'o',
  全身全霊: 0,
  全人: 0,
  全盛: 1,
  全精力: 1,
  全責任: 0,
  全速: 1,
  全速力: 1,
  全損: -1,
  全知: 1,
  全知全能: 1,
  全能: 1,
  全敗: -1,
  全米: 0,
  全滅: -1,
  全面協力: 0,
  全面戦争: -1,
  全力: 1,
  塑性: 0,
  疎外感: -1,
  祖国統一: 1,
  祖先: 1,
  租税: 0,
  粗: -1,
  粗さ: -1,
  粗悪: -1,
  粗忽: -1,
  粗相: -1,
  粗品: 0,
  素: 0,
  素行: 0,
  素材: 0,
  素質: 1,
  素振り: 0,
  素人: -1,
  素晴らしさ: 1,
  素早さ: 1,
  素地: 1,
  素直: 1,
  素敵: 1,
  素朴: 1,
  素養: 1,
  訴え: -1,
  訴追: 0,
  阻害: -1,
  阻止: 0,
  僧侶: 0,
  創意: 0,
  創意工夫: 1,
  創価学会: 0,
  創刊: 0,
  創業者: 1,
  創見: 1,
  創作: 0,
  創作意欲: 1,
  創造: 0,
  創造性: 1,
  創造力: 1,
  倉庫荒し: -1,
  喪失: -1,
  喪失感: -1,
  喪心: -1,
  '喪心・喪神': -1,
  喪神: -1,
  壮快: 1,
  壮観: 1,
  壮健: 1,
  壮絶: -1,
  壮美: 1,
  奏功: 1,
  奏効: 1,
  爽やか: 1,
  爽やかさ: 1,
  爽快: 1,
  爽快感: 1,
  宋: 0,
  想い: 1,
  想い出: 0,
  想像力: 1,
  想定: 0,
  想到: 0,
  想念: 0,
  捜索: 0,
  挿入: 0,
  掻爬: 0,
  操: 0,
  操作性: 1,
  操守: 0,
  操縦席: 0,
  早さ: 0,
  早とちり: -1,
  早め: 0,
  早期: 0,
  早期完成: 1,
  早期治療: 0,
  早期実現: 1,
  早期成立: 1,
  早期着工: 0,
  早期導入: 0,
  早期発見: 1,
  早期発見早期治療: 1,
  早起き: 0,
  早業: 1,
  早口: -1,
  早寝早起き: 1,
  早成: 0,
  早打ち: 0,
  早退: -1,
  早発: 0,
  早発性: 0,
  早発性痴呆: -1,
  早漏: -1,
  巣: 0,
  巣窟: -1,
  争: -1,
  争い: -1,
  争奪戦: -1,
  争乱: -1,
  相: 0,
  相愛: 1,
  相違: 0,
  相違点: 0,
  相姦: 0,
  相互依存関係: -1,
  相互運用性: 1,
  相互干渉: 0,
  相互協力: 0,
  相互理解: 0,
  相四つ: 0,
  相思: 0,
  相思相愛: 1,
  相乗効果: 1,
  相性問題: 0,
  相続: 0,
  相打ち: 0,
  相聞: 0,
  総すかん: -1,
  総て: 1,
  総なめ: 1,
  総スカン: -1,
  総攻撃: -1,
  総合: 0,
  総合力: 1,
  総辞職: 0,
  総点: 0,
  総点検: 0,
  総崩れ: -1,
  草稿: 0,
  草野球: 0,
  荘厳: 1,
  荘厳さ: 1,
  蒼白: -1,
  装い: 0,
  装飾: 0,
  装飾性: 0,
  装備: 0,
  走性: 0,
  走力: 1,
  走塁: 0,
  送りバント: 0,
  送り倒し: 0,
  送球: 0,
  送料: 0,
  送料無料: 0,
  遭難: -1,
  遭難者: -1,
  霜: 0,
  霜害: -1,
  騒がしさ: -1,
  騒ぎ: -1,
  騒音: -1,
  騒動: -1,
  騒乱: -1,
  増: 0,
  増し: 0,
  増員: 0,
  増援: 0,
  増加: 0,
  増加傾向: 0,
  増刊: 0,
  増強: 0,
  増減: 0,
  増産: 1,
  増収: 1,
  増進: 0,
  増水: -1,
  増勢: 0,
  増税: -1,
  憎さ: -1,
  憎しみ: -1,
  憎まれ役: -1,
  憎らしさ: -1,
  憎悪: -1,
  憎憎しさ: -1,
  臓器移植: 0,
  贈与税: 0,
  造詣: 0,
  造反: -1,
  促進: 0,
  即興: 0,
  即決: 0,
  即効: 0,
  即座: 0,
  即戦力: 1,
  即諾: 0,
  即答: 0,
  息: 0,
  息の根: -1,
  息苦しさ: -1,
  息遣い: 0,
  息災: 1,
  息吹: 1,
  息切れ: -1,
  息抜き: 0,
  束縛: -1,
  足まめ: 0,
  足技: 0,
  足止め: -1,
  足弱: -1,
  足跡: 0,
  足切り: -1,
  足袋: 0,
  足踏み: -1,
  足踏み状態: -1,
  足払い: 0,
  足並み: 0,
  足癖: -1,
  足留め: 0,
  足枷: -1,
  速さ: 1,
  速やか: 1,
  速乾性: 0,
  速球: 1,
  速効: 1,
  速攻: 0,
  速度: 0,
  速度差: 0,
  速度低下: -1,
  速報: 0,
  速報性: 1,
  速力: 1,
  俗: -1,
  俗化: -1,
  俗情: -1,
  俗世: -1,
  俗世間: -1,
  俗念: -1,
  賊: -1,
  賊徒: -1,
  続伸: -1,
  続投: 0,
  続騰: 0,
  続報: 0,
  続落: -1,
  卒去: -1,
  卒業: 0,
  卒業研究: 0,
  卒業資格: 1,
  卒中: -1,
  卒倒: -1,
  卒論: 0,
  其処: 0,
  存じ寄り: 0,
  存在意義: 1,
  存在感: 1,
  存在証明: 0,
  存在理由: 0,
  存生: 1,
  存続: 0,
  存分: 0,
  存命: 0,
  存立: 0,
  孫: 0,
  尊さ: 1,
  尊王: 0,
  尊敬: 1,
  尊厳: 1,
  尊皇: 0,
  '尊皇・尊王': 0,
  尊重: 1,
  尊信: 1,
  尊崇: 1,
  損: 0,
  損じ: -1,
  損壊: -1,
  損害: -1,
  損害賠償: -1,
  損害賠償請求権: 0,
  損害賠償責任: -1,
  損失: -1,
  損傷: -1,
  村夫子: 0,
  遜色: 0,
  他: 0,
  他愛: 0,
  他界: -1,
  他極: 0,
  他殺: -1,
  他者性: 0,
  他方: 0,
  多さ: 0,
  多角: 0,
  多機能: 1,
  多極: 0,
  多血質: -1,
  多幸: 1,
  多彩: 1,
  多謝: 1,
  多重債務: -1,
  多情仏心: 1,
  多動: -1,
  多読: 0,
  多尿: -1,
  多方面: 0,
  多忙: -1,
  多面的機能: 1,
  多様化: 0,
  多様性: 1,
  太っ腹: 1,
  太りすぎ: -1,
  太線: 0,
  太刀打ち: -1,
  太平: 1,
  太平楽: 1,
  太平洋戦争: 0,
  太陽: 0,
  太陽神: 0,
  唾: 0,
  唾液: 0,
  唾棄: -1,
  堕落: -1,
  妥当: 0,
  妥当性: 0,
  惰性: -1,
  惰性的: -1,
  惰力: 0,
  打ち: 0,
  打ち間違い: -1,
  打ち合い: 0,
  打ち傷: -1,
  打ち身: -1,
  打ち切り: -1,
  打開: 0,
  打球: 0,
  打撃: -1,
  打算: -1,
  打線: 0,
  打点: 0,
  打倒: 0,
  打撲: -1,
  打擲: -1,
  駄スレ: -1,
  駄作: -1,
  駄文: -1,
  駄目: -1,
  駄目押し: 0,
  駄目出し: -1,
  駄洒落: 0,
  体位: 0,
  体液: 0,
  体外受精: 0,
  体格差: 0,
  体感: 0,
  体刑: -1,
  体験: 0,
  体脂肪: -1,
  体脂肪率: -1,
  体質: 0,
  体臭: 0,
  体重: -1,
  体重差: 0,
  体重増加: -1,
  体制整備: 0,
  体中: 0,
  体調不良: -1,
  体内時計: 1,
  体罰: -1,
  体力: 1,
  体力づくり: 0,
  体力気力: 1,
  体力差: -1,
  体力作り: 0,
  体力低下: -1,
  体力不足: -1,
  堆積: -1,
  対: 0,
  対応力: 1,
  対価: 0,
  対岸の火事: -1,
  対極: 0,
  対空砲火: -1,
  対決: 0,
  対抗: -1,
  対策: 0,
  対人関係: 0,
  対人恐怖: -1,
  対人恐怖症: -1,
  対陣: -1,
  対等合併: 0,
  対面: 0,
  対面販売: 0,
  対立: -1,
  対流: 0,
  対話: 0,
  耐圧: 1,
  耐久性: 1,
  耐久力: 1,
  耐候性: 1,
  耐湿性: 0,
  耐衝撃性: 1,
  耐食性: 1,
  耐震: 0,
  耐震性: 1,
  耐水性: 1,
  耐性: 1,
  耐熱性: 1,
  耐摩耗性: 0,
  帯域: 0,
  帯域幅: 1,
  帯状疱疹: -1,
  待ちぼうけ: -1,
  待ち時間: -1,
  待ち伏せ: 0,
  待避: 0,
  待望: 0,
  怠け: -1,
  怠け者: -1,
  怠惰: -1,
  怠慢: -1,
  泰: 0,
  滞り: -1,
  滞納: -1,
  胎動: 0,
  胎毒: -1,
  胎内: 0,
  袋小路: 0,
  袋叩き: -1,
  貸し: 0,
  退位: 0,
  退院: 0,
  退院祝い: 1,
  退隠: 0,
  退化: -1,
  退会: -1,
  退学: -1,
  退官: 0,
  退却: 0,
  退局: 0,
  退屈: -1,
  退行: -1,
  退治: 0,
  退社: 0,
  退所: 0,
  退場: 0,
  退場処分: -1,
  退職: 0,
  退色: -1,
  退陣: 0,
  退団: 0,
  退潮: 0,
  退任: 0,
  退廃: -1,
  '退廃・頽廃': -1,
  退避: 0,
  退部: -1,
  退歩: -1,
  退役: 0,
  逮捕: -1,
  鯛: 0,
  代わり: 0,
  代金: 0,
  代稽古: 0,
  代謝: 1,
  代償: -1,
  代打: 0,
  代替: 0,
  代替案: 0,
  代表: 0,
  代表作: 1,
  代表選手: 1,
  代役: 1,
  台: 0,
  台形: 0,
  台風: -1,
  台無し: -1,
  台湾: 0,
  台湾語: 0,
  大げさ: -1,
  大ざっぱ: -1,
  大にぎわい: 1,
  大の字: 0,
  大まか: -1,
  大ダメージ: -1,
  大ヒット: 1,
  大ブーイング: -1,
  大移動: 0,
  大悦: 1,
  大往生: 1,
  大恩: 1,
  大音声: 0,
  大家: 1,
  大火災: -1,
  大過: -1,
  大我: 0,
  大怪我: -1,
  大外刈り: 0,
  大学名: 0,
  大喝: -1,
  大患: -1,
  大間違い: -1,
  大企業: 1,
  大喜び: 1,
  大器: 0,
  大器晩成: 1,
  大気汚染: -1,
  大気圏: 0,
  大飢饉: -1,
  大儀: -1,
  大技: 1,
  大義: 1,
  大吉: 1,
  大虐殺: -1,
  大逆: -1,
  大泣き: -1,
  大漁: 1,
  大凶: -1,
  大恐慌: -1,
  大金: 1,
  大袈裟: -1,
  大型魚: 0,
  大型店: 0,
  大型倒産: -1,
  大慶: 1,
  大喧嘩: -1,
  大賢: 1,
  大好き: 1,
  大好評: 1,
  大工仕事: 0,
  大洪水: -1,
  大国: 1,
  大災害: -1,
  大罪: -1,
  大作: 1,
  大雑把: -1,
  大惨事: -1,
  大仕事: 1,
  大事: 1,
  大事故: -1,
  大慈大悲: 0,
  大自然: 1,
  大失態: -1,
  大失敗: -1,
  大酒: 0,
  大衆: 0,
  大衆性: 0,
  大渋滞: -1,
  大出力: 0,
  大勝: 1,
  大勝利: 1,
  大笑: 0,
  大笑い: 1,
  大丈夫: 1,
  大震災: -1,
  大人: 0,
  大人物: 1,
  大水: -1,
  大水害: -1,
  大成: 1,
  大成功: 1,
  大盛況: 1,
  大聖: 1,
  大声: 0,
  大切: 1,
  大雪: -1,
  大戦: -1,
  大戦争: -1,
  大層: 0,
  大掃除: 0,
  大蔵: 0,
  大損: -1,
  大損害: -1,
  大多数: 1,
  大打撃: -1,
  大胆: 1,
  大胆さ: 1,
  大地震: -1,
  大腸がん: -1,
  大腸ガン: -1,
  大腸癌: -1,
  大腸菌: -1,
  大津波: -1,
  大敵: -1,
  大東亜戦争: -1,
  大盗: -1,
  大統領: 1,
  大難: -1,
  大任: 0,
  大波: 0,
  大破: -1,
  大敗: -1,
  大爆発: -1,
  大盤石: 1,
  大被害: -1,
  大病: -1,
  大風: -1,
  大福: 1,
  大物: 1,
  大変: -1,
  大便: -1,
  大味: -1,
  大迷惑: -1,
  大目玉: -1,
  大厄: -1,
  大容量: 0,
  大量失点: -1,
  大量消費: 0,
  大力: 1,
  大和魂: 1,
  大和心: 1,
  第１次世界大戦: -1,
  第一次世界大戦: -1,
  第一次大戦: -1,
  第一人者: 1,
  第一声: 0,
  第一報: 0,
  第一流: 1,
  第三部: 0,
  第二次世界大戦: -1,
  第二次大戦: -1,
  第二部: 0,
  第二話: 0,
  醍醐味: 1,
  卓識: 1,
  卓抜: 1,
  濁り: -1,
  濁る: -1,
  濁流: 0,
  諾: 0,
  諾意: 0,
  諾否: 0,
  叩き: -1,
  達識: 1,
  達者: 1,
  達人: 1,
  達成: 1,
  達成感: 1,
  奪い合い: -1,
  奪回: 1,
  奪還: 0,
  脱臼: -1,
  脱会: 0,
  脱却: 1,
  脱出: 0,
  脱色: 0,
  脱水: 0,
  脱水症状: -1,
  脱税: -1,
  脱線: -1,
  脱走: -1,
  脱退: 0,
  脱腸: -1,
  脱党: 0,
  脱藩: 0,
  脱法: -1,
  脱毛: 0,
  脱落: -1,
  脱落者: -1,
  脱力: 0,
  脱力感: -1,
  脱輪: -1,
  脱疽: -1,
  棚卸し: -1,
  丹青: 1,
  丹毒: -1,
  丹念: 1,
  単価: 0,
  単彩: 0,
  単純: 0,
  単身赴任: 0,
  単打: 0,
  単調: -1,
  単調さ: -1,
  単独犯: -1,
  嘆: -1,
  嘆かわしさ: -1,
  嘆き: -1,
  嘆願: 0,
  嘆美: 1,
  担い手: 0,
  担税力: 0,
  担任: 0,
  探求: 0,
  探求心: 1,
  探究心: 1,
  探検: 0,
  探検気分: 1,
  淡彩: 1,
  淡泊: 0,
  炭肺: -1,
  短気: -1,
  短所: -1,
  短打: 0,
  短調: 0,
  短編: 0,
  短命: -1,
  端役: -1,
  胆汁質: -1,
  胆石: -1,
  胆力: 0,
  誕生: 1,
  誕生会: 0,
  誕生祝い: 1,
  誕生石: 1,
  誕生日: 1,
  誕生日プレゼント: 1,
  誕生日祝い: 1,
  団結: 0,
  団結力: 1,
  団体客: 0,
  団欒: 1,
  弾圧: -1,
  弾丸: 0,
  弾性: 0,
  弾薬: 0,
  弾力: 1,
  弾力性: 1,
  弾力的: 0,
  断トツ: 1,
  断罪: -1,
  断食: 0,
  断水: -1,
  断絶: -1,
  断線: -1,
  断層: 0,
  断腸: -1,
  断腸の思い: -1,
  断定: 0,
  断熱: 0,
  断熱性: 1,
  断念: -1,
  断片: -1,
  断片化: 0,
  断末魔: -1,
  暖かさ: 1,
  段ボール: 0,
  段違い: 1,
  段差: -1,
  男らしさ: 0,
  男気: 1,
  男嫌い: -1,
  男子禁制: 0,
  男手: 1,
  男女共同参画: 1,
  男女差: -1,
  男女差別: -1,
  男女同権: 1,
  男女平等: 1,
  男神: 1,
  男声: 0,
  男前: 1,
  男日照り: -1,
  男冥利: 1,
  談合: -1,
  値ごろ: 1,
  値引き: 0,
  値下がり: 0,
  値下げ: 0,
  値下げ競争: 0,
  値札: 0,
  値上がり: -1,
  値上げ: -1,
  値打ち: 1,
  値段: -1,
  値崩れ: -1,
  知: 1,
  '知・智': 1,
  知らせ: 0,
  知る権利: 1,
  知覚: 0,
  知恵: 1,
  知恵袋: 1,
  知恵熱: 0,
  知見: 0,
  知行: 1,
  知識: 1,
  知識経験: 1,
  知識人: 1,
  知識不足: -1,
  知性: 1,
  知的: 1,
  知的活動: 0,
  知的興奮: 1,
  知的好奇心: 1,
  知的所有権: 1,
  知的障害: -1,
  知徳: 1,
  知能: 1,
  知能犯: -1,
  知名: 1,
  知名度: 1,
  知勇: 1,
  知力: 1,
  地ばれ: -1,
  地位: 1,
  地域格差: -1,
  地域活動: 0,
  地域差: -1,
  地域振興: 0,
  地域性: 1,
  地域福祉: 0,
  地域紛争: -1,
  地下鉄サリン事件: -1,
  地殻変動: 0,
  地気: 0,
  地祇: 0,
  地球温暖化対策: 0,
  地球上: 0,
  地獄: -1,
  地震: -1,
  地声: 0,
  地層: 0,
  地盤沈下: -1,
  地方都市: 0,
  地味: -1,
  地雷: -1,
  地力: 1,
  地霊: 0,
  弛緩: 0,
  恥: -1,
  恥さらし: -1,
  恥じらい: 0,
  恥ずかしさ: -1,
  恥辱: -1,
  恥部: -1,
  智: 1,
  智恵: 1,
  智慧: 1,
  痴ほう: -1,
  痴漢: -1,
  痴漢行為: -1,
  痴情: -1,
  痴呆: -1,
  痴呆症: -1,
  痴話げんか: -1,
  稚魚: 0,
  稚拙: -1,
  置いてきぼり: -1,
  置き引き: -1,
  置換: 0,
  致仕: 0,
  致死: -1,
  致命: -1,
  致命傷: -1,
  蜘蛛: 0,
  遅さ: -1,
  遅れ: -1,
  遅ればせ: -1,
  遅れ馳せ: -1,
  遅延: -1,
  遅刻: -1,
  遅参: -1,
  遅速: 0,
  遅滞: -1,
  遅知恵: -1,
  竹林の七賢: 1,
  蓄え: 1,
  蓄積: -1,
  蓄膿症: -1,
  秩序: 1,
  窒息: -1,
  茶々: 0,
  茶気: 1,
  茶飯事: 0,
  着意: 0,
  着実: 0,
  着手: 0,
  着色: 0,
  着信: 0,
  着想: 0,
  着道楽: -1,
  中: 0,
  中だるみ: -1,
  中華: 0,
  中華人民共和国: 0,
  中許し: 0,
  中古: -1,
  中古市場: 0,
  中国: 0,
  中国国内: 0,
  中国市場: 0,
  中止: -1,
  中耳炎: -1,
  中傷: -1,
  中心: 0,
  中身: 0,
  中辛: 0,
  中性: 0,
  中性脂肪: -1,
  中正: 0,
  中絶: -1,
  中速: 0,
  中断: -1,
  中途: -1,
  中途半端: -1,
  中毒: -1,
  中風: 0,
  中庸: 1,
  中立: 0,
  中立性: 1,
  仲: 0,
  仲間: 1,
  仲間意識: 1,
  仲間内: 0,
  仲人: 1,
  仲直り: 1,
  仲良し: 1,
  忠義: 1,
  忠勤: 1,
  忠告: 0,
  忠魂: 1,
  忠実: 1,
  忠心: 1,
  忠誠: 1,
  忠誠心: 1,
  忠節: 1,
  忠霊: 1,
  昼食会: 0,
  昼寝: 0,
  昼夜逆転: -1,
  注意: -1,
  注意義務: 0,
  注意力: 1,
  注目: 0,
  虫の息: -1,
  虫刺され: -1,
  虫歯: -1,
  虫食い: -1,
  虫垂炎: -1,
  衷情: 1,
  駐車違反: -1,
  著作権違反: -1,
  著作権侵害: -1,
  著作権侵害行為: -1,
  著名: 1,
  著名人: 1,
  貯金: 1,
  貯蓄: 0,
  丁重: 1,
  丁寧: 1,
  丁寧さ: 1,
  凋落: -1,
  寵愛: 1,
  帳消し: 1,
  弔い: -1,
  弔意: 0,
  弔慰: 0,
  弔事: 0,
  弔辞: 0,
  張り: 1,
  張り合い: 1,
  張り紙: 0,
  張り手: 0,
  張力: 0,
  徴収: -1,
  徴用: -1,
  懲らしめ: -1,
  懲戒: -1,
  懲戒処分: -1,
  懲罰: -1,
  懲役: -1,
  挑戦: 0,
  朝: 0,
  朝恩: 1,
  朝寒: 0,
  朝鮮: 0,
  朝鮮戦争: -1,
  朝敵: 0,
  朝令暮改: -1,
  朝礼: 0,
  潮流: 0,
  町おこし: 0,
  眺望: 0,
  聴き手: 1,
  聴覚: 0,
  聴覚障害: -1,
  聴許: 0,
  聴視覚: 0,
  聴衆: 0,
  聴力: 1,
  聴力障害: -1,
  腸カタル: -1,
  腸チフス: -1,
  腸炎: -1,
  腸捻転症: -1,
  腸閉塞: -1,
  調: 0,
  調音: 0,
  調査結果: 0,
  調査票: 0,
  調査方法: 0,
  調子: 0,
  調達: 0,
  調停: 0,
  調度: 0,
  調法: 1,
  調律師: 0,
  調和: 1,
  超音波: 0,
  超過: -1,
  超過料金: -1,
  超克: 0,
  超人: 1,
  超絶: 0,
  超能力: 1,
  超能力者: 0,
  長: 1,
  長期化: 0,
  長期計画: 0,
  長期欠席: -1,
  長期投資: 0,
  長期入院: -1,
  長欠: -1,
  長持ち: 1,
  長時間: 0,
  長時間労働: -1,
  長者: 1,
  長寿: 1,
  長寿命化: 1,
  長所: 1,
  長生: 1,
  長生き: 1,
  長逝: -1,
  長槍: 0,
  長打: 1,
  長打力: 1,
  長嘆: -1,
  長短: 0,
  長男: 0,
  長調: 0,
  長文: 0,
  長命: 1,
  長旅: 0,
  鳥たち: 0,
  鳥インフルエンザ: -1,
  鳥害: -1,
  鳥肌: -1,
  鳥目: -1,
  勅勘: 0,
  勅令: 0,
  直し: 0,
  直感: 0,
  直観: 0,
  直球: 0,
  直撃: 0,
  直撃弾: -1,
  直情: 0,
  直線コース: 0,
  直線美: 1,
  沈静: 1,
  沈静化: 0,
  沈滞: -1,
  沈滞ムード: -1,
  沈着: -1,
  沈殿: 0,
  沈没: -1,
  沈黙: 0,
  沈淪: -1,
  沈湎: -1,
  珍しさ: 1,
  珍奇: -1,
  珍事: -1,
  珍味: -1,
  珍妙: -1,
  珍無類: -1,
  珍問: -1,
  賃下げ: -1,
  賃金: 1,
  賃金格差: -1,
  賃上げ: 1,
  賃労働: 0,
  鎮護: 0,
  鎮静: 0,
  陳情: -1,
  陳腐化: -1,
  津波: -1,
  墜落: -1,
  墜落事故: -1,
  椎間板ヘルニア: -1,
  追いはぎ: -1,
  追い上げ: -1,
  追い立て: -1,
  追っ手: -1,
  追加: 0,
  追加効果: 1,
  追加点: 1,
  追加料金: -1,
  追及: 0,
  追手: -1,
  追突事故: -1,
  追放: 0,
  痛: -1,
  痛々しさ: -1,
  痛さ: -1,
  痛ましさ: -1,
  痛み: -1,
  痛快: 1,
  痛覚: 0,
  痛感: -1,
  痛苦: -1,
  痛恨: -1,
  痛恨事: -1,
  痛手: -1,
  痛心: -1,
  痛打: -1,
  痛嘆: -1,
  痛罵: -1,
  痛風: -1,
  痛烈: -1,
  痛哭: -1,
  痛痒: -1,
  通: 1,
  通り魔: -1,
  通貨危機: -1,
  通過: 0,
  通気: 0,
  通気性: 1,
  通勤ラッシュ: -1,
  通行止め: -1,
  通産: 0,
  通商: 0,
  通常: 0,
  通常実施権: 0,
  通信費: 0,
  通達: 0,
  通風: -1,
  通訳: 0,
  通話料: 0,
  椿事: -1,
  潰し: -1,
  潰走: -1,
  潰瘍: -1,
  爪: 0,
  吊るし上げ: -1,
  釣り人: 0,
  釣果: 1,
  低め: 0,
  低コスト: 1,
  低コスト化: 0,
  低域: 0,
  低下: -1,
  低価格: 1,
  低価格競争: 0,
  低血圧: -1,
  低血圧症: -1,
  低消費電力: 1,
  低声: 0,
  低速: 0,
  低調: -1,
  低迷: -1,
  低迷期: -1,
  低迷状態: -1,
  低落: -1,
  低利: -1,
  低廉: 0,
  停会: 0,
  停学: -1,
  停学処分: -1,
  停止: 0,
  停止ボタン: 0,
  停職: -1,
  停戦: -1,
  停滞: -1,
  停滞感: -1,
  停滞期: -1,
  停電: -1,
  停頓: -1,
  貞潔: 1,
  貞淑: 1,
  貞節: 1,
  貞操: 1,
  貞操帯: 0,
  定員: 0,
  定員割れ: -1,
  定額料金: 0,
  定期検診: 0,
  定在波: 0,
  定着: 1,
  定番: 1,
  定評: 1,
  帝王切開: 0,
  底: 0,
  底割れ: 0,
  底質: 0,
  底上げ: 1,
  底生生物: 0,
  底辺: 0,
  底力: 1,
  弟子: 0,
  弟子たち: 0,
  抵抗: -1,
  抵抗感: -1,
  抵抗勢力: -1,
  抵抗性: 1,
  抵抗力: 1,
  提供: 1,
  提携関係: 1,
  提言: 0,
  程々: 1,
  締まり: 0,
  締め出し: -1,
  締め切り: 0,
  締め付け: -1,
  締切り: 0,
  訂正: 0,
  訂正箇所: -1,
  邸内: 0,
  泥: -1,
  泥沼: -1,
  泥沼化: -1,
  泥水: -1,
  泥酔状態: -1,
  泥棒: -1,
  泥流: -1,
  摘発: -1,
  敵: -1,
  敵意: -1,
  敵失: 1,
  敵性: 0,
  敵対: -1,
  敵対心: -1,
  敵兵: -1,
  敵方: 0,
  敵味方: 0,
  敵役: -1,
  敵愾心: -1,
  滴: 0,
  的外れ: -1,
  的確: 1,
  的中: 1,
  笛吹き: 0,
  適応性: 1,
  適応力: 1,
  適合: 1,
  適材: 0,
  適材適所: 1,
  適時打: 1,
  適者生存: 0,
  適職: 1,
  適性: 1,
  適正: 1,
  適正化: 0,
  適切: 1,
  適度: 1,
  適任: 1,
  適否: 0,
  適評: 1,
  適不適: 0,
  適法: 1,
  適役: 1,
  溺愛: -1,
  溺死: -1,
  哲学: 1,
  哲人: 0,
  徹底: 0,
  徹底抗戦: 0,
  徹底的: 1,
  徹夜: 0,
  撤回: 0,
  撤廃: 0,
  轍: -1,
  鉄欠乏性貧血: -1,
  鉄拳: -1,
  鉄鋼: 0,
  鉄条網: 0,
  鉄槌: 0,
  鉄砲水: -1,
  典拠: 0,
  典麗: 1,
  天つ神: 0,
  天にも昇る心地: 1,
  天安門広場: 0,
  天衣無縫: 1,
  天恩: 1,
  天下一: 1,
  天下一品: 1,
  天下統一: 0,
  天気予報: 0,
  天恵: 1,
  天候不順: -1,
  天皇: 0,
  天皇杯: 1,
  天国: 1,
  天才: 1,
  天災: -1,
  天使: 1,
  天助: 1,
  天女: 0,
  天真爛漫: 1,
  天神: 0,
  天然: 0,
  天然温泉: 1,
  天然痘: -1,
  天地神明: 1,
  天敵: -1,
  天道: 1,
  天罰: -1,
  天罰てきめん: -1,
  天魔: 0,
  天目山: 0,
  天野: 0,
  天佑: 1,
  天祐: 1,
  天誅: -1,
  展開: 0,
  展性: 0,
  店じまい: -1,
  店づくり: 0,
  店員: 0,
  店舗数: 0,
  添え: 0,
  添加: 0,
  添削: 0,
  添付: 0,
  '添付・添附': 0,
  添付ファイル: 0,
  添附: 0,
  転移: 0,
  転嫁: -1,
  転校: 0,
  転送速度: 0,
  転倒: -1,
  転倒事故: -1,
  転落: -1,
  転落事故: -1,
  点: 0,
  点差: 0,
  点数: 1,
  伝言: 0,
  伝承: 0,
  伝説: 0,
  伝染: -1,
  伝染性: 0,
  伝染病: -1,
  伝線: -1,
  伝達: 0,
  伝統: 1,
  伝統美: 1,
  伝統文化: 1,
  伝播: 0,
  伝令: 0,
  殿堂入り: 1,
  田虫: -1,
  電圧降下: 0,
  電気ショック: -1,
  電気代: -1,
  電撃: -1,
  電源スイッチ: 0,
  電光: 0,
  電磁波: -1,
  電車内: 0,
  電池切れ: -1,
  電波: -1,
  電波障害: -1,
  電力: 1,
  電力危機: -1,
  電話代: -1,
  吐き気: -1,
  吐血: -1,
  吐息: -1,
  吐瀉物: -1,
  塗炭: 0,
  塗炭の苦しみ: -1,
  塗料: 0,
  妬心: -1,
  徒刑: -1,
  徒手空拳: -1,
  徒費: -1,
  徒労感: -1,
  登校拒否: -1,
  登山靴: 0,
  登場: 0,
  登仙: -1,
  登頂: 0,
  途上: -1,
  途中下車: 0,
  都合: 0,
  都市化: 0,
  都市環境: 0,
  都市機能: 1,
  都市計画税: 0,
  努力: 0,
  努力の結晶: 1,
  努力義務: 1,
  土気色: -1,
  土砂: 0,
  土砂災害: -1,
  土砂崩れ: -1,
  土産: 1,
  土壌ｐＨ: 0,
  土壌水分: 0,
  土石流: -1,
  土壇場: 0,
  土地利用: 0,
  土俵際: 0,
  土付かず: 1,
  土埃: 0,
  奴: 0,
  奴ら: 0,
  奴等: 0,
  怒り: -1,
  怒りっぽさ: -1,
  怒気: -1,
  怒号: -1,
  怒声: -1,
  怒張: 0,
  怒鳴り声: -1,
  倒壊: -1,
  倒閣: 0,
  倒産: -1,
  倒幕: 0,
  倒木: 0,
  冬: 0,
  凍結: -1,
  凍死: -1,
  凍傷: -1,
  凍瘡: -1,
  唐: 0,
  島唄: 0,
  島流し: -1,
  悼み: -1,
  投げ: 0,
  投げやり: -1,
  投げ技: 0,
  投下: 0,
  投棄: -1,
  投機: 0,
  投球: 0,
  投降: 0,
  投獄: -1,
  投資: 0,
  投資意欲: 0,
  投資環境: 0,
  投資効率: 1,
  投入: 0,
  投票権: 1,
  投法: 0,
  投薬: 0,
  搭載: 0,
  東海地震: -1,
  東京都: 0,
  盗: -1,
  盗み: -1,
  盗み笑い: -1,
  盗掘: -1,
  盗作: -1,
  盗人: -1,
  盗賊: -1,
  盗賊団: -1,
  盗聴: -1,
  盗電: -1,
  盗難: -1,
  盗難被害: -1,
  盗犯: -1,
  盗癖: -1,
  盗塁: 0,
  淘汰: 0,
  湯煙: 0,
  湯気: 0,
  当たり: 1,
  当たり外れ: 0,
  当たり障り: -1,
  当たり役: 1,
  当てっこ: 0,
  当て物: 0,
  当意即妙: 1,
  当院: 0,
  当該権利: 0,
  当座しのぎ: -1,
  当選: 1,
  当否: 0,
  当惑: -1,
  痘苗: 0,
  痘瘡: -1,
  等級: 1,
  等速: 0,
  答: 0,
  答案: 0,
  糖: 0,
  糖尿: -1,
  糖尿病: -1,
  統一: 0,
  統一感: 1,
  統一性: 1,
  統合化: 0,
  統合失調症: -1,
  統廃合: 0,
  到達: 0,
  到着: 0,
  豆知識: 0,
  豆鉄砲: 0,
  豆腐屋: 0,
  逃避: -1,
  逃避行: -1,
  逃亡: -1,
  逃亡生活: -1,
  透過性: 0,
  透水性: 0,
  透徹: 0,
  透明感: 1,
  透明性: 1,
  鐙: 0,
  陶酔: 0,
  頭: 0,
  頭でっかち: -1,
  頭巾: 0,
  頭声: 0,
  頭痛: -1,
  頭痛鉢巻き: -1,
  頭脳: 1,
  騰貴: -1,
  騰落: -1,
  闘気: 1,
  闘魂: 1,
  闘志: 1,
  闘争: 0,
  闘争心: 0,
  闘争本能: 0,
  闘病生活: -1,
  働き: 1,
  働きかけ: 1,
  働き過ぎ: -1,
  動機: 0,
  動作速度: 1,
  動作不良: -1,
  動脈硬化: -1,
  動脈硬化症: -1,
  動脈瘤: -1,
  動揺: -1,
  動乱: -1,
  動悸: -1,
  同意: 0,
  同一性: 0,
  同化: 0,
  同感: 1,
  同居: 0,
  同慶: 1,
  同時多発テロ: -1,
  同情: 0,
  同情心: 0,
  同心: 0,
  同棲: 0,
  同定: 0,
  同点ゴール: 0,
  同病: -1,
  同憂: -1,
  同様: 0,
  同類: 0,
  憧憬: 1,
  撞着: 1,
  洞察: 0,
  洞察力: 1,
  童心: 0,
  童貞: -1,
  胴間声: -1,
  道しるべ: 0,
  道化役: -1,
  道楽: 0,
  道義: 1,
  道草: 0,
  道徳: 1,
  道徳性: 1,
  道半ば: -1,
  峠: 0,
  得: 1,
  得るところ: 1,
  得意: 1,
  得策: 1,
  得失: 0,
  得手: 1,
  得手勝手: -1,
  得点: 1,
  得点力: 1,
  得票: 1,
  得物: 0,
  得分: 1,
  '得分・徳分': 1,
  得用: 1,
  徳: 1,
  徳義: 1,
  徳性: 1,
  徳操: 1,
  徳分: 1,
  徳用: 1,
  '徳用・得用': 1,
  特異体質: 0,
  特技: 1,
  特急: 0,
  特許: 1,
  特訓: 0,
  特恵: 1,
  特権: 1,
  特効: 1,
  特攻: -1,
  特産: 0,
  特産品: 0,
  特質: 1,
  特殊: 0,
  特殊能力: 1,
  特選: 1,
  特段: 1,
  特徴: 0,
  特定: 0,
  特典: 1,
  特筆: 0,
  特別措置: 0,
  特報: 0,
  特命: 0,
  特立: 0,
  特例: 0,
  督励: 1,
  禿: 0,
  篤学: 1,
  篤志: 1,
  毒: -1,
  毒よけ: 0,
  毒ガス: -1,
  毒液: -1,
  毒気: -1,
  毒殺: -1,
  毒蛇: 0,
  毒状態: -1,
  毒性: -1,
  毒舌: -1,
  毒素: -1,
  毒電波: -1,
  毒当たり: -1,
  毒婦: -1,
  毒霧: -1,
  独り言: 0,
  独眼竜: 0,
  独裁: -1,
  独裁者: -1,
  独自性: 1,
  独自路線: 1,
  独善: -1,
  独善的: -1,
  独創性: 1,
  独断: -1,
  独立: 1,
  独立国家: 0,
  独立心: 1,
  独立性: 1,
  独立戦争: 0,
  独立独歩: 1,
  読み癖: -1,
  読解力: 1,
  読者: 0,
  読書活動: 0,
  読書感想文: 0,
  読書時間: 0,
  凸凹: -1,
  突き: -1,
  突き出し: 0,
  突き傷: -1,
  突き目: -1,
  突っ張り: 0,
  突進: 0,
  突破: 0,
  突厥: 0,
  届け: 0,
  届け出: 0,
  届出義務: 0,
  酉の市: 0,
  頓挫: -1,
  頓着: -1,
  曇り声: -1,
  鈍さ: -1,
  鈍化: -1,
  鈍感: -1,
  鈍才: -1,
  鈍痛: -1,
  内: 0,
  内ゲバ: -1,
  内懐: 0,
  内観: 0,
  内気: -1,
  内股: 0,
  内実: 0,
  内祝い: 0,
  内祝言: 1,
  内出血: -1,
  内助: 0,
  内政: 0,
  内戦: -1,
  内争: -1,
  内臓脂肪: -1,
  内臓疾患: -1,
  内諾: 0,
  内偵: 0,
  内定: 0,
  内部結露: -1,
  内部構造: 0,
  内部分裂: -1,
  内紛: -1,
  内務: 0,
  内蒙古: 0,
  内訳: 0,
  内憂外患: -1,
  内乱: -1,
  内輪げんか: -1,
  内輪もめ: -1,
  内輪争い: -1,
  内訌: -1,
  薙刀: 0,
  謎: 0,
  南アフリカ: 0,
  南下: 0,
  南国ムード: 0,
  南国気分: 1,
  南北戦争: 0,
  軟化: 0,
  軟弱: -1,
  軟調: 0,
  難: -1,
  難解: -1,
  難関: -1,
  難儀: -1,
  難局: -1,
  難工事: -1,
  難攻不落: 1,
  難航: -1,
  難産: -1,
  難事: -1,
  難渋: -1,
  難所: -1,
  難症: -1,
  難色: -1,
  難船: -1,
  難題: -1,
  難中の難事: -1,
  難聴: -1,
  難敵: -1,
  難点: -1,
  難度: -1,
  難破: -1,
  難病: -1,
  難癖: -1,
  難民: -1,
  難問: -1,
  二の足: -1,
  二ヶ月: 0,
  二元論: 0,
  二時間: 0,
  二次感染: -1,
  二次災害: -1,
  二次障害: -1,
  二次被害: -1,
  二者択一: 0,
  二種類: 0,
  二十年: 0,
  二十分: 0,
  二心: -1,
  二進一退: 0,
  二択: 0,
  二度寝: 0,
  二日: 0,
  二日酔い: -1,
  二年半: 0,
  二枚舌: -1,
  二枚目: 1,
  二律背反: -1,
  二流: -1,
  賑やか: 1,
  賑やかさ: 1,
  賑わい: 1,
  肉感: 1,
  肉眼: 0,
  肉腫: -1,
  肉食妻帯: -1,
  肉体美: 1,
  肉体労働: 0,
  肉弾: 0,
  肉弾戦: -1,
  肉片: 0,
  肉欲: -1,
  日の下開山: 0,
  日の目: 0,
  日刊: 0,
  日系企業: 0,
  日光浴: 1,
  日差し: 1,
  日射病: -1,
  日焼け: 0,
  日照り: 0,
  日照不足: -1,
  日常性: 0,
  日常茶飯: 0,
  日進月歩: 0,
  日数: 0,
  日清戦争: -1,
  日中戦争: -1,
  日報: 0,
  日本一: 1,
  日本語対応: 0,
  日本脳炎: -1,
  日曜大工: 0,
  日用品: 0,
  日露戦争: 0,
  乳がん: -1,
  乳ガン: -1,
  乳飲み子: 0,
  乳液: 0,
  乳癌: -1,
  乳酸: 0,
  乳酸菌: 0,
  乳腺炎: -1,
  乳房: 0,
  乳房炎: -1,
  乳幼児: 0,
  入り用: 1,
  入れ歯: 0,
  入れ替え: 0,
  入れ墨: 0,
  入金: 0,
  入試: 0,
  入寂: -1,
  入手: 0,
  入植: 0,
  入定: -1,
  入湯税: 0,
  入念: 1,
  入滅: -1,
  入門者: 0,
  入用: 0,
  入力ミス: -1,
  尿: 0,
  尿意: 0,
  尿失禁: -1,
  尿毒症: -1,
  尿路結石: -1,
  妊娠中毒症: -1,
  妊婦さん: 0,
  忍: -1,
  忍び笑い: -1,
  忍耐: 0,
  忍耐力: 1,
  認可: 1,
  認許: 0,
  認識: 1,
  認識不足: -1,
  認証: 1,
  認知: 0,
  認定: 1,
  認容: 0,
  濡れ: 0,
  濡れ衣: -1,
  猫かわいがり: 1,
  猫なで声: -1,
  猫背: -1,
  熱: 0,
  熱さ: 0,
  熱愛: 1,
  熱意: 1,
  熱感: -1,
  熱気: 1,
  熱血: 1,
  熱源: 0,
  熱賛: 1,
  熱射病: -1,
  熱傷: -1,
  熱情: 0,
  熱心: 1,
  熱戦: 1,
  熱線: 0,
  熱中: 0,
  熱中症: -1,
  熱病: -1,
  熱風: 0,
  熱弁: 0,
  熱暴走: -1,
  熱量: 1,
  熱燗: 0,
  年賀: 1,
  年季: 1,
  年金: 1,
  年収: 1,
  年上: 0,
  年報: 0,
  年令: 0,
  年齢差: 0,
  年齢制限: 0,
  念: 0,
  念願: 0,
  念入り: 1,
  捻挫: -1,
  燃焼: 0,
  燃費: 0,
  粘り: 1,
  粘り強さ: 1,
  粘液質: -1,
  粘性: 0,
  粘着: -1,
  粘着性: 0,
  悩ましさ: -1,
  悩み: -1,
  濃い口: 0,
  濃厚: 0,
  濃彩: 1,
  濃霧: -1,
  濃緑色: 0,
  納税義務: 0,
  納得: 0,
  納入: 0,
  能: 1,
  能力: 1,
  能力差: 0,
  脳こうそく: -1,
  脳しんとう: -1,
  脳溢血: -1,
  脳炎: -1,
  脳血管障害: -1,
  脳血栓: -1,
  脳梗塞: -1,
  脳硬塞: -1,
  脳挫傷: -1,
  脳塞栓: -1,
  脳死: -1,
  脳腫瘍: -1,
  脳充血: -1,
  脳出血: -1,
  脳症: -1,
  脳障害: -1,
  脳震盪: -1,
  脳脊髄膜炎: -1,
  脳卒中: -1,
  脳内出血: -1,
  脳軟化症: -1,
  脳波: 0,
  脳病: -1,
  脳貧血: -1,
  膿: -1,
  膿腫: -1,
  膿疱: -1,
  膿瘍: -1,
  農水: 0,
  農政: 0,
  農林: 0,
  覗き: -1,
  把握: 0,
  覇気: 1,
  波しぶき: 0,
  波風: -1,
  波瀾: -1,
  派手さ: 0,
  破たん: -1,
  破れ: -1,
  破れかぶれ: -1,
  破壊: -1,
  破壊力: 1,
  破戒: -1,
  破顔一笑: 1,
  破産: -1,
  破傷風: -1,
  破損: -1,
  破綻: -1,
  破断: -1,
  破談: -1,
  破調: -1,
  破天荒: -1,
  破片: -1,
  破滅: -1,
  破門: -1,
  破約: -1,
  破裂: -1,
  破裂音: 0,
  婆心: 0,
  罵声: -1,
  罵倒: -1,
  罵詈: -1,
  罵詈雑言: -1,
  罵詈讒謗: -1,
  馬群: 0,
  馬鹿: -1,
  馬鹿馬鹿しさ: -1,
  馬首: 0,
  馬賊: -1,
  馬蹄形: 0,
  馬力: 1,
  俳優: 1,
  廃り: -1,
  廃液: -1,
  廃刊: 0,
  廃棄: 0,
  廃棄物: -1,
  廃業: -1,
  廃校: -1,
  廃材: 0,
  廃止: -1,
  廃疾: -1,
  廃車: -1,
  廃人: -1,
  廃絶: -1,
  廃退: -1,
  廃部: -1,
  廃物: -1,
  廃油: 0,
  廃墟: 0,
  拝謝: 0,
  排ガス: 0,
  排外主義: -1,
  排気ガス: -1,
  排出: 0,
  排水: -1,
  排斥: -1,
  排他性: -1,
  排尿障害: -1,
  排泄: 0,
  排泄物: -1,
  敗局: -1,
  敗血症: -1,
  敗残: -1,
  敗者: -1,
  敗績: -1,
  敗戦: -1,
  敗訴: -1,
  敗走: -1,
  敗退: -1,
  敗報: -1,
  敗北: -1,
  敗北感: -1,
  背筋力: 1,
  背広: 0,
  背信: -1,
  背水の陣: 0,
  背痛: -1,
  背徳: -1,
  背日性: 0,
  背負い投げ: -1,
  背約: -1,
  肺がん: -1,
  肺ガン: -1,
  肺炎: -1,
  肺癌: -1,
  肺気腫: -1,
  肺結核: -1,
  肺浸潤: -1,
  肺水腫: -1,
  肺病: -1,
  輩: 0,
  輩出: 0,
  配給: 0,
  配偶者: 0,
  配偶者特別控除: 1,
  配色: 0,
  配当: 1,
  配流: -1,
  配慮: 0,
  倍音: 0,
  倍速: 1,
  倍率: 0,
  梅雨: 0,
  梅雨寒: 0,
  梅園: 0,
  梅毒: -1,
  煤: 0,
  煤煙: -1,
  買い気: 0,
  買い手市場: 0,
  買春: -1,
  買物: 0,
  売り気: 0,
  売り込み: 0,
  売り子: 0,
  売り手市場: 0,
  売り上げ: 1,
  売り切れ: -1,
  売れ行き: 1,
  売れ残り: -1,
  売春: -1,
  売笑: -1,
  売上: 1,
  売上げ: 1,
  賠償: -1,
  蝿: 0,
  剥離: -1,
  博愛: 1,
  博雅: 1,
  博学: 1,
  博士: 0,
  博士論文: 0,
  博識: 1,
  博聞: 1,
  博覧: 0,
  博覧強記: 1,
  拍手: 1,
  拍手喝采: 1,
  白さ: 0,
  白そこひ: -1,
  白なまず: -1,
  白煙: -1,
  白血病: -1,
  白砂青松: 1,
  白紙撤回: 0,
  白色: 0,
  白人: 0,
  白刃: 0,
  白濁: 0,
  白痴: -1,
  白兎: 0,
  白内障: -1,
  白斑: 0,
  白粉: 0,
  白味: 0,
  薄さ: -1,
  薄ら笑い: -1,
  薄口: 0,
  薄幸: -1,
  薄志: 0,
  薄弱: -1,
  薄笑い: -1,
  薄情: -1,
  薄着: 0,
  薄氷を踏む思い: -1,
  薄味: 0,
  薄命: -1,
  薄毛: -1,
  迫害: -1,
  迫真: 1,
  迫力: 1,
  迫力満点: 1,
  爆撃: -1,
  爆笑: 0,
  爆弾: -1,
  爆弾テロ: -1,
  爆発: -1,
  爆発事故: -1,
  爆風: -1,
  爆裂: -1,
  縛り: 0,
  麦粒腫: -1,
  肌あれ: -1,
  肌トラブル: -1,
  肌荒れ: -1,
  八岐大蛇: 0,
  八景: 1,
  八幡: 1,
  八方ふさがり: -1,
  八方手: 0,
  八方破れ: -1,
  発意: 0,
  発育: 0,
  発火: -1,
  発芽: 0,
  発覚: -1,
  発刊: 0,
  発汗: 0,
  発揮: 1,
  発給: 0,
  発禁: 0,
  発券: 0,
  発見: 0,
  発言力: 1,
  発効: 0,
  発行: 0,
  発作: -1,
  発散: 0,
  発症: -1,
  発色: 0,
  発信音: 0,
  発信元: 0,
  発振周波数: 0,
  発疹: -1,
  発生: -1,
  発生件数: 0,
  発生源: 0,
  発声練習: 0,
  発想: 0,
  発想力: 1,
  発達: 1,
  発注: 0,
  発展: 1,
  発展性: 1,
  発熱: -1,
  発表資料: 0,
  発病: -1,
  発明: 0,
  発問: 0,
  発令: 0,
  発兌: 0,
  発疱: 0,
  罰: -1,
  罰金: -1,
  罰金刑: -1,
  罰則: 0,
  罰当たり: -1,
  抜かり: -1,
  抜き: 0,
  抜け毛: -1,
  抜群: 1,
  伴侶: 0,
  判じ物: 0,
  判断ミス: -1,
  判断能力: 1,
  判断力: 1,
  判定: 0,
  判明: 0,
  判例: 0,
  半壊: -1,
  半休: 0,
  半月: 0,
  半作: 0,
  半殺し: -1,
  半死: -1,
  半死半生: -1,
  半信半疑: -1,
  半睡: 0,
  半袖: 0,
  半端: -1,
  半年以上: 0,
  半年近く: 0,
  半病人: -1,
  反ユダヤ主義: 0,
  反応: 0,
  反感: -1,
  反逆: -1,
  反響: 0,
  反抗: -1,
  反攻: 0,
  反骨: 1,
  反作用: 0,
  反射: 0,
  反省: 0,
  反省点: -1,
  反証: 0,
  反戦: 0,
  反戦運動: 0,
  反戦平和: 1,
  反則: -1,
  反体制: -1,
  反対: -1,
  反対意見: 0,
  反対運動: -1,
  反対派: 0,
  反吐: -1,
  反騰: -1,
  反動: 0,
  反発: -1,
  反発力: 0,
  反米: 0,
  反米感情: -1,
  反目: -1,
  反落: -1,
  反乱: -1,
  反論: -1,
  叛乱: -1,
  斑点: 0,
  板の間稼ぎ: -1,
  板挟み: -1,
  氾濫: -1,
  汎愛: 1,
  汎用ドメイン: 0,
  汎用性: 1,
  版行: 0,
  犯行: -1,
  犯罪: -1,
  犯罪行為: -1,
  犯罪者: -1,
  犯罪性: -1,
  犯人: -1,
  犯人逮捕: 0,
  繁栄: 1,
  繁華: 1,
  繁簡: 0,
  繁盛: 1,
  般若: -1,
  販売: 0,
  販売競争: 0,
  販売中止: -1,
  販路: 1,
  販路拡大: 0,
  範疇: 0,
  煩い: -1,
  煩わしさ: -1,
  煩雑: -1,
  煩多: -1,
  煩悩: -1,
  煩悶: -1,
  煩瑣: -1,
  晩成: 1,
  晩節: 0,
  晩飯: 0,
  蛮行: -1,
  蛮声: -1,
  匪賊: -1,
  卑怯: -1,
  卑屈: -1,
  卑見: -1,
  否: -1,
  否定: 0,
  否認: 0,
  庇護: 0,
  彼女たち: 0,
  彼女達: 0,
  悲しさ: -1,
  悲しみ: -1,
  悲哀: -1,
  悲運: -1,
  悲観: -1,
  悲観論: -1,
  悲喜: 0,
  悲劇: -1,
  悲惨: -1,
  悲惨さ: -1,
  悲愁: -1,
  悲傷: -1,
  悲壮感: -1,
  悲嘆: -1,
  悲痛: -1,
  悲報: -1,
  悲鳴: -1,
  悲恋: 0,
  悲愴感: -1,
  扉: 0,
  批准: 0,
  批正: 0,
  批判: -1,
  批判精神: 1,
  披露: 0,
  比高: 0,
  比翼連理: 1,
  疲れ: -1,
  疲れ目: -1,
  疲弊: -1,
  疲労: -1,
  疲労感: -1,
  疲労困憊: -1,
  疲労度: -1,
  疲憊: -1,
  皮疹: -1,
  皮肉: -1,
  皮膚がん: -1,
  皮膚炎: -1,
  皮膚感覚: 0,
  皮膚疾患: -1,
  皮膚病: -1,
  皮癬: -1,
  秘密保持: 0,
  秘密保持義務: 0,
  肥大: -1,
  肥満: -1,
  肥料: 0,
  被害: -1,
  被害拡大: -1,
  被害者: -1,
  被害者意識: -1,
  被害妄想: -1,
  被虐: -1,
  被災: -1,
  被災者: 0,
  被災地: -1,
  被写界深度: 1,
  被弾: -1,
  被曝: -1,
  被爆: -1,
  被爆者: -1,
  誹謗: -1,
  誹謗中傷: -1,
  費消: 0,
  費用: -1,
  避退: 0,
  避難: -1,
  非: -1,
  非の打ちどころ: -1,
  非運: -1,
  非行: -1,
  非合理: -1,
  非常: -1,
  非常事態: -1,
  非常識: -1,
  非情: -1,
  非人: -1,
  非人間的: -1,
  非人情: -1,
  非接触: 0,
  非対称性: 0,
  非道: -1,
  非難: -1,
  非能率: -1,
  非暴力: 0,
  非凡: 1,
  非力: -1,
  非礼: -1,
  飛蚊症: -1,
  飛距離: 1,
  飛行機: 1,
  飛散: -1,
  飛鳥: 0,
  飛報: 0,
  飛躍: 0,
  飛来: 0,
  備え: 1,
  備蓄: 0,
  微意: 0,
  微苦笑: 0,
  微罪: -1,
  微笑: 0,
  微笑み: 1,
  微衷: 0,
  微熱: -1,
  微妙: 0,
  微力: 0,
  眉目秀麗: 1,
  美: 1,
  美しさ: 1,
  美化: 0,
  美果: 1,
  美学: 1,
  美観: 1,
  美形: 1,
  美景: 1,
  美質: 1,
  美醜: 0,
  美術家: 0,
  美女: 1,
  美少女: 1,
  美少年: 1,
  美丈夫: 1,
  美食: 1,
  美神: 0,
  美人: 1,
  美声: 1,
  美男: 1,
  美男子: 1,
  美男美女: 1,
  美的感覚: 1,
  美点: 1,
  美徳: 1,
  美肌: 1,
  美貌: 1,
  美味: 1,
  美味しさ: 1,
  美妙: 1,
  美名: 1,
  美麗: 1,
  鼻っぱし: 0,
  鼻クソ: 0,
  鼻炎: -1,
  鼻詰まり: -1,
  鼻血: -1,
  鼻呼吸: 0,
  鼻水: -1,
  鼻声: -1,
  鼻息: -1,
  鼻風邪: -1,
  必携: 0,
  必見: 0,
  必殺: 0,
  必至: 0,
  必需: 0,
  必勝: 0,
  必須: 0,
  必然性: 0,
  必備: 1,
  必用: 0,
  必要: 0,
  必要性: 0,
  筆まめ: 1,
  筆圧: 0,
  筆意: 0,
  筆禍: -1,
  筆不精: -1,
  筆癖: 0,
  筆力: 0,
  逼塞: -1,
  逼迫: -1,
  百も承知: 0,
  百済: 0,
  百人力: 1,
  百戦百勝: 1,
  百日ぜき: -1,
  謬見: -1,
  標準偏差: 0,
  氷: 0,
  氷河期: -1,
  氷水: 0,
  漂流: -1,
  票数: 0,
  表敬: 1,
  表現能力: 1,
  表現力: 1,
  表示方法: -1,
  表彰: 1,
  表情: 1,
  表徳: 1,
  表面張力: 0,
  表裏: 0,
  評: 0,
  評価: 0,
  評価方法: 0,
  評点: 1,
  評判: 1,
  病: -1,
  病い: -1,
  病み付き: 0,
  病院: -1,
  病院内: 0,
  病臥: -1,
  病害虫: -1,
  病気: -1,
  病気そのもの: -1,
  病気欠席: -1,
  病気等: -1,
  病菌: -1,
  病苦: -1,
  病欠: -1,
  病原菌: -1,
  病原性: 0,
  病原体: -1,
  病妻: -1,
  病児: -1,
  病室: 0,
  病者: -1,
  病弱: -1,
  病床: -1,
  病状: -1,
  病身: -1,
  病人: -1,
  病巣: -1,
  病態: 0,
  病夫: -1,
  病婦: -1,
  病癖: -1,
  病変: -1,
  病魔: -1,
  病理: -1,
  品: 1,
  品位: 1,
  品格: 1,
  品質: 1,
  品種: 0,
  品性: 1,
  品切れ: -1,
  品薄: -1,
  品不足: -1,
  品柄: 0,
  品目: 0,
  瀕死: -1,
  貧: -1,
  貧しさ: -1,
  貧窮: -1,
  貧苦: -1,
  貧血: -1,
  貧困: -1,
  貧者: -1,
  貧弱: -1,
  貧打: -1,
  貧富: 0,
  貧乏: -1,
  貧乏くじ: -1,
  貧乏神: -1,
  貧乏性: -1,
  貧乏生活: -1,
  貧賤: -1,
  頻尿: -1,
  敏感: 0,
  敏感肌: 0,
  敏捷: 1,
  敏捷性: 1,
  敏速: 1,
  敏腕: 1,
  不安: -1,
  不安感: -1,
  不安定: -1,
  不安定さ: -1,
  不案内: -1,
  不一致: -1,
  不運: -1,
  不可: -1,
  不可欠: 1,
  不可能: -1,
  不快: -1,
  不快さ: -1,
  不快感: -1,
  不拡大: 0,
  不確か: -1,
  不確かさ: -1,
  不確実: -1,
  不確定: -1,
  不確定要素: -1,
  不覚: -1,
  不完全: -1,
  不完全燃焼: -1,
  不感症: -1,
  不慣れ: -1,
  不器用: -1,
  不器量: -1,
  不機嫌: -1,
  不気味: -1,
  不気味さ: -1,
  不規則: -1,
  不規律: -1,
  不軌: -1,
  不義: -1,
  不義理: -1,
  不吉: -1,
  不急: -1,
  不漁: -1,
  不協和音: -1,
  不況: -1,
  不興: -1,
  不均衡: -1,
  不倶戴天: -1,
  不倶戴天の敵: -1,
  不具合: -1,
  不遇: -1,
  不屈: 1,
  不敬: -1,
  不景気: -1,
  不経済: -1,
  不潔: -1,
  不健康: -1,
  不健全: -1,
  不公平: -1,
  不公平感: -1,
  不向き: -1,
  不幸: -1,
  不幸せ: -1,
  不幸中の幸い: 1,
  不行き届き: -1,
  不行状: -1,
  不行跡: -1,
  不合格: -1,
  不合理: -1,
  不細工: -1,
  不在: -1,
  不作: -1,
  不参: -1,
  不参加: -1,
  不賛成: -1,
  不始末: -1,
  不思議さ: 0,
  不死: 1,
  不死身: 1,
  不時着: 0,
  不治の病: -1,
  不自然: -1,
  不自然さ: -1,
  不自由: -1,
  不自由さ: -1,
  不実: -1,
  不手際: -1,
  不十分: -1,
  不十分さ: -1,
  不祝儀: -1,
  不出来: -1,
  不純: -1,
  不順: -1,
  不所存: -1,
  不祥: -1,
  不祥事: -1,
  不条理: -1,
  不浄: -1,
  不信: -1,
  不信感: -1,
  不信任: -1,
  不審: -1,
  不審者: -1,
  不心得: -1,
  不振: -1,
  不親切: -1,
  不身持ち: -1,
  不人気: -1,
  不人情: -1,
  不随: 0,
  不随意運動: 0,
  不成功: -1,
  不整合: -1,
  不整脈: -1,
  不正: -1,
  不正アクセス: -1,
  不正確: -1,
  不正行為: -1,
  不正使用: -1,
  不正利用: -1,
  不精: -1,
  '不精・無精': -1,
  不摂生: -1,
  不戦勝: 1,
  不全: -1,
  不測: 0,
  不足: -1,
  不足分: -1,
  不仲: -1,
  不注意: -1,
  不調: -1,
  不調法: -1,
  '不調法・無調法': -1,
  不釣り合い: -1,
  不貞: -1,
  不定芽: 0,
  不定愁訴: -1,
  不敵: -1,
  不適格: -1,
  不適任: -1,
  不渡り: -1,
  不登校: 0,
  不都合: -1,
  不当: -1,
  不当労働行為: -1,
  不透明さ: -1,
  不動: 1,
  不同沈下: -1,
  不道徳: -1,
  不得意: -1,
  不得手: -1,
  不徳: -1,
  不徳義: -1,
  不届き: -1,
  不妊: -1,
  不妊症: -1,
  不熱心: -1,
  不燃性: 0,
  不能: -1,
  不敗: 1,
  不備: -1,
  不美人: -1,
  不必要: -1,
  不評: -1,
  不評判: -1,
  不品行: -1,
  不風流: -1,
  不服: -1,
  不文: 0,
  不平: -1,
  不平等: -1,
  不平不満: -1,
  不偏: 1,
  不便: -1,
  不便さ: -1,
  不勉強: -1,
  不法: -1,
  不法行為: -1,
  不法行為責任: -1,
  不法侵入: -1,
  不法滞在: -1,
  不法投棄: -1,
  不満: -1,
  不満足: -1,
  不満点: -1,
  不味: -1,
  不眠: -1,
  不眠症: -1,
  不名誉: -1,
  不明: 0,
  不明確: -1,
  不明点: -1,
  不毛: -1,
  不愉快: -1,
  不溶性: 0,
  不用: -1,
  不用意: -1,
  不要: -1,
  不利: -1,
  不利益: -1,
  不良: -1,
  不良債権: -1,
  不良債権処理: -1,
  不良在庫: -1,
  不倫: -1,
  不老: 1,
  不老長寿: 1,
  不老不死: 1,
  不和: -1,
  不埒: -1,
  不愍: -1,
  不憫: -1,
  '不憫・不愍': -1,
  不逞: -1,
  付かぬ事: -1,
  付き: 0,
  付き合い: 1,
  付け: -1,
  付け景気: -1,
  付加: 0,
  '付加・附加': 0,
  付近: 0,
  夫婦げんか: -1,
  夫婦喧嘩: -1,
  婦女暴行: -1,
  婦人病: -1,
  婦徳: 1,
  富: 1,
  富強: 1,
  富国: 1,
  富国強兵: 1,
  富力: 1,
  布教: 0,
  布施: 1,
  布陣: 0,
  怖さ: -1,
  怖気: -1,
  扶助: 0,
  扶養義務: 0,
  敷居: 0,
  斧正: 0,
  普及: 1,
  普及拡大: 0,
  普及促進: 0,
  普通: 0,
  普遍性: 1,
  浮かぶ瀬: 0,
  浮き: 0,
  浮き名: -1,
  浮気: -1,
  浮腫: -1,
  浮力: 0,
  父親: 0,
  父母: 0,
  腐臭: -1,
  腐食: -1,
  腐敗: -1,
  負: -1,
  負け: -1,
  負けじ魂: 1,
  負けん気: 1,
  負け越し: -1,
  負け犬: -1,
  負け戦: -1,
  負圧: -1,
  負荷: -1,
  負債: -1,
  負傷: -1,
  負傷者: -1,
  負担: -1,
  負担軽減: 0,
  負担増: -1,
  附加: 0,
  武: 1,
  武運: 1,
  武士道: 0,
  武神: 0,
  武蔵: 0,
  武道: 0,
  武名: 1,
  武勇: 1,
  武勇伝: 1,
  武力: 1,
  部員数: 0,
  部外者: -1,
  部材: 1,
  部品: -1,
  部落差別: -1,
  封: 0,
  封鎖: 0,
  封殺: 1,
  風雨: 0,
  風雲児: 1,
  風格: 1,
  風景: 0,
  風光明媚: 1,
  風合い: 1,
  風災: -1,
  風邪: -1,
  風邪引き: -1,
  風邪気: -1,
  風邪気味: -1,
  風邪声: -1,
  風樹の嘆: -1,
  風情: 1,
  風疹: -1,
  風神: 0,
  風水害: -1,
  風雪: 0,
  風潮: -1,
  風評: 1,
  風評被害: -1,
  風変わり: 0,
  風味: 1,
  風流: 1,
  風流韻事: 1,
  風呂場: 0,
  伏字: 0,
  副作用: -1,
  副収入: 0,
  副賞: 1,
  副反応: 0,
  副鼻腔炎: -1,
  復活: 1,
  復刊: 0,
  復帰: 1,
  復興: 0,
  復権: 1,
  復元: 0,
  復原力: 1,
  復刻: 0,
  復習: 1,
  復調: 1,
  服役: -1,
  福: 1,
  福の神: 1,
  福運: 1,
  福音: 0,
  福祉: 1,
  福祉サービス: 1,
  福沢諭吉: 0,
  福徳: 1,
  福利: 0,
  腹具合: 0,
  腹式呼吸: 0,
  腹水: -1,
  腹積もり: 0,
  腹痛: -1,
  腹膜炎: -1,
  腹立たしさ: -1,
  複雑: 0,
  複雑性: -1,
  複雑多岐: -1,
  複製権: 1,
  覆面: -1,
  淵: 0,
  仏: 0,
  仏さま: 0,
  仏心: 1,
  仏道: 0,
  仏罰: -1,
  仏法: 0,
  仏滅: 0,
  仏様: 0,
  物: 0,
  物おじ: -1,
  物の哀れ: 0,
  物もらい: -1,
  物忌: -1,
  物件費: 0,
  物言い: 0,
  物故: -1,
  物思い: 0,
  物資: 0,
  物質: 0,
  物寂しさ: -1,
  物取り: -1,
  物柔らか: 1,
  物色: 0,
  物静か: 1,
  物騒: -1,
  物足りなさ: -1,
  物知らず: -1,
  物悲しさ: -1,
  物忘れ: -1,
  物憂さ: -1,
  物欲: 0,
  物理的: 0,
  物力: 0,
  分け前: 1,
  分煙: 0,
  分化: 0,
  分外: 0,
  分散投資: 0,
  分数: 0,
  分析: 0,
  分析力: 1,
  分損: -1,
  分担: 0,
  分泌: 0,
  分明: 1,
  分力: 0,
  分裂: 0,
  分裂症: -1,
  分裂病: -1,
  噴火: -1,
  噴火口: -1,
  噴出: 0,
  噴飯: -1,
  噴飯物: -1,
  憤慨: -1,
  憤懣: -1,
  焚書: -1,
  奮起: 0,
  奮迅: 1,
  奮戦: 0,
  奮闘: 0,
  奮励: 1,
  粉: 0,
  粉塵: 0,
  糞: -1,
  糞スレ: -1,
  糞尿: -1,
  糞便: -1,
  紛れ: 0,
  紛糾: -1,
  紛失: -1,
  紛擾: -1,
  紛争: -1,
  雰囲気: 1,
  文: 0,
  文なし: -1,
  文化: 1,
  文化人: 1,
  文句: -1,
  文句なし: 1,
  文言: 0,
  文字コード: -1,
  文字化け: -1,
  文殊の知恵: 1,
  文書等: 0,
  文章力: 1,
  文人: 1,
  文通: 0,
  文武両道: 1,
  文部: 0,
  文名: 1,
  文面: 0,
  聞き巧者: 1,
  聞き上手: 1,
  併殺: -1,
  兵力: 0,
  平安: 1,
  平易: 1,
  平穏: 1,
  平気: 1,
  平衡: 0,
  平衡感覚: 0,
  平作: 0,
  平謝り: -1,
  平手: -1,
  平手打ち: -1,
  平常心: 0,
  平静: 1,
  平静さ: 1,
  平等: 1,
  平等主義: 0,
  平等性: 1,
  平凡: -1,
  平明: 1,
  平和: 1,
  平和主義: 1,
  平和的解決: 1,
  弊害: -1,
  閉館: -1,
  閉業: -1,
  閉口: -1,
  閉校: -1,
  閉鎖: -1,
  閉鎖性: -1,
  閉塞: -1,
  閉塞感: -1,
  閉塞状況: -1,
  閉山: -1,
  閉止: 0,
  閉庁: 0,
  閉店: -1,
  米: 0,
  米軍基地: 0,
  米国: 0,
  僻: -1,
  僻見: -1,
  壁: -1,
  癖: -1,
  別れ: -1,
  別居: -1,
  別行動: 0,
  別世界: 0,
  別段: 1,
  別離: -1,
  別料金: 0,
  偏り: -1,
  偏愛: 0,
  偏見: -1,
  偏向: -1,
  偏差: -1,
  偏差値: 1,
  偏執: -1,
  偏執狂: -1,
  偏執病: -1,
  偏食: -1,
  偏頭痛: -1,
  変: -1,
  変わり者: -1,
  変異: -1,
  変化球: 0,
  変更: 0,
  変更箇所: 0,
  変更等: 0,
  変死: -1,
  変事: -1,
  変質: -1,
  変色: -1,
  変人: -1,
  変性: 0,
  変成: 0,
  変態: -1,
  変態性欲: -1,
  変調: -1,
  変動: -1,
  変貌: 0,
  変約: -1,
  片栗粉: 0,
  片恨み: -1,
  片思い: 0,
  片手間: -1,
  片田舎: -1,
  片頭痛: -1,
  '片頭痛・偏頭痛': -1,
  片恋: 0,
  編み物: 0,
  編集者: 0,
  編入: 0,
  編物: 0,
  辺境: -1,
  返し技: 0,
  返す返す: 0,
  返り咲き: 1,
  返り討ち: 0,
  返還: 1,
  返金: 1,
  返済: 1,
  返事: 1,
  返信: 1,
  返答: 0,
  便: 1,
  便り: 0,
  便宜: 1,
  便所: 0,
  便乗: 0,
  便秘: -1,
  便便: -1,
  便利: 1,
  便利さ: 1,
  勉強時間: 1,
  勉強不足: -1,
  勉励: 0,
  弁解: -1,
  弁慶の泣きどころ: 0,
  弁護: 0,
  弁償: 0,
  弁舌: 0,
  弁膜症: -1,
  鞭撻: 0,
  保安: 0,
  保育: 0,
  保温: 1,
  保温性: 1,
  保菌者: -1,
  保険料: 0,
  保険料収入: 0,
  保護: 1,
  保持: 1,
  保湿: 0,
  保湿効果: 1,
  保湿性: 0,
  保守: 0,
  保証: 1,
  保証付き: 1,
  保障: 0,
  保障額: 0,
  保水力: 1,
  保全: 0,
  保存: 0,
  保存活用: 0,
  保存性: 1,
  保養: 0,
  捕球: 0,
  捕虜収容所: -1,
  歩き方: 0,
  歩み: 1,
  歩行者: 0,
  歩調: 0,
  補給: 1,
  補強: 0,
  補佐: 0,
  補修: 0,
  補充: 1,
  補助: 0,
  補償: 0,
  補正: 0,
  補訂: 0,
  補填: 1,
  補弼: 0,
  募金: 0,
  募集期間: 0,
  墓: -1,
  墓参: 0,
  墓場: 0,
  墓地: -1,
  母音: 0,
  母集団: 0,
  母性愛: 1,
  菩薩: 0,
  菩提心: 1,
  包囲: -1,
  包囲網: 0,
  包茎: -1,
  包容力: 1,
  呆れ顔: 0,
  呆気: -1,
  呆然: -1,
  報い: 0,
  報告数: 0,
  報酬: 1,
  報奨: 0,
  報奨金: 1,
  報道陣: 0,
  報道被害: -1,
  報復: -1,
  報復攻撃: -1,
  奉加: 1,
  奉仕: 0,
  奉祝: 1,
  宝石: 1,
  宝刀: 1,
  宝物: 1,
  峰打ち: -1,
  崩れ: -1,
  崩壊: -1,
  崩御: -1,
  崩落: -1,
  抱腹絶倒: 0,
  放逸: -1,
  放下: 0,
  放火魔: -1,
  放棄: 0,
  放射線: -1,
  放射線障害: -1,
  放射能: -1,
  放射能汚染: -1,
  放射能漏れ: -1,
  放縦: -1,
  放出: -1,
  放水: 0,
  放線菌: 0,
  放送禁止: -1,
  放置: -1,
  放蕩: -1,
  放熱: 0,
  放念: 0,
  放漫: -1,
  放浪: -1,
  放恣: -1,
  方位: 0,
  方形: 0,
  方向音痴: -1,
  方策: 0,
  方法: 1,
  法悦: 1,
  法規制: 0,
  法整備: 0,
  法曹: 1,
  法的根拠: 1,
  法的責任: 0,
  法典: 1,
  法難: -1,
  法務: 0,
  法律違反: -1,
  法律問題: 0,
  法力: 1,
  泡: 0,
  砲撃: 0,
  砲弾: -1,
  胞子: 0,
  芳志: 1,
  芳醇: 1,
  芳情: 1,
  蜂: -1,
  訪問: 0,
  訪問看護: 0,
  訪問者: 0,
  豊か: 1,
  豊かさ: 1,
  豊艶: 1,
  豊漁: 1,
  豊凶: 0,
  豊作: 1,
  豊穣: 1,
  豊年: 1,
  豊年満作: 1,
  豊富: 1,
  豊麗: 1,
  豊饒: 1,
  飽き: -1,
  飽き性: -1,
  飽き飽き: -1,
  飽食: 0,
  飽満: 1,
  鳳凰: 0,
  乏しさ: -1,
  亡骸: -1,
  亡魂: -1,
  亡失: -1,
  亡者: 0,
  亡羊の嘆: -1,
  亡霊: -1,
  傍若無人: -1,
  剖検: 0,
  坊主: 0,
  妨げ: -1,
  妨害: -1,
  忘れ: -1,
  忘れ物: -1,
  忘失: -1,
  忙しさ: 0,
  暴圧: -1,
  暴漢: -1,
  暴虐: -1,
  暴挙: -1,
  暴君: -1,
  暴言: -1,
  暴行: -1,
  暴行事件: -1,
  暴状: -1,
  暴走: -1,
  暴走行為: -1,
  暴走族: -1,
  暴徒: -1,
  暴投: -1,
  暴騰: -1,
  暴動: -1,
  暴発: -1,
  暴評: -1,
  暴風: -1,
  暴風雨: 0,
  暴落: -1,
  暴力: -1,
  暴力行為: -1,
  暴力沙汰: -1,
  暴力事件: -1,
  暴力性: -1,
  暴力団: -1,
  暴露: -1,
  暴論: -1,
  望み: 1,
  望洋の嘆: -1,
  棒下げ: -1,
  棒球: 0,
  棒上げ: -1,
  冒険: 0,
  冒険心: 1,
  膨満: -1,
  謀反: -1,
  貿易収支: 0,
  貿易赤字: -1,
  貿易摩擦: -1,
  防衛: 0,
  防疫: 0,
  防火: 0,
  防寒: 0,
  防空頭巾: 0,
  防御: 0,
  防御力: 1,
  防災: 0,
  防災訓練: 0,
  防止: 1,
  防除: 0,
  防食: 0,
  防水: 0,
  防水性: 1,
  防毒: 0,
  防犯: 0,
  防腐: 0,
  北京語: 0,
  北朝鮮: 0,
  北魏: 0,
  墨: 0,
  墨守: 0,
  撲滅: 0,
  朴訥: 1,
  牧神: 0,
  牧羊神: 0,
  勃起: 0,
  勃興: 1,
  没: -1,
  没我: 0,
  没落: -1,
  奔走: 0,
  奔騰: -1,
  奔放: 0,
  本格: 1,
  本格焼酎: 0,
  本気: 1,
  本規約: 0,
  本質: 0,
  本然: 1,
  本尊: 1,
  本調子: 1,
  本土決戦: 0,
  本当: 1,
  本能: 0,
  本病: -1,
  本物: 1,
  本分: 1,
  本編: 0,
  本名: 0,
  本命: 0,
  本来: 0,
  本塁打: 1,
  凡ミス: -1,
  凡作: -1,
  凡手: 0,
  凡戦: -1,
  凡打: -1,
  凡退: -1,
  摩擦: 0,
  摩耗: 0,
  磨耗: -1,
  魔: -1,
  魔王: 0,
  魔球: 0,
  魔手: -1,
  魔女: 0,
  魔女狩り: -1,
  魔神: -1,
  魔人: -1,
  魔性: -1,
  魔族: -1,
  魔道: 0,
  魔物: -1,
  魔羅: -1,
  魔力: 0,
  麻疹: -1,
  麻薬: -1,
  麻痺: -1,
  埋め立て: 0,
  埋蔵金: 0,
  埋蔵文化財: 0,
  毎日更新: 0,
  末期: -1,
  末期ガン: -1,
  末期癌: -1,
  末期症状: -1,
  末広がり: 1,
  万が一: 0,
  万引: -1,
  万引き: -1,
  万死: -1,
  万謝: 1,
  万全: 1,
  万難: -1,
  万能: 1,
  万病: -1,
  万福: 1,
  万万歳: 1,
  慢性: -1,
  慢性肝炎: -1,
  慢性関節リウマチ: -1,
  慢性疾患: -1,
  慢性病: -1,
  満員: 0,
  満悦: 1,
  満開: 1,
  満喫: 1,
  満載: 1,
  満作: 1,
  満室: -1,
  満車: 0,
  満州: 0,
  満州事変: -1,
  満身創痍: -1,
  満席: 0,
  満足: 1,
  満足感: 1,
  満足度: 1,
  満点: 1,
  満杯: 0,
  満腹: 1,
  満腹感: 1,
  満満: 0,
  満塁: 1,
  漫画家: 0,
  漫然: -1,
  漫罵: -1,
  蔓延: -1,
  味: 1,
  味わい: 1,
  味覚: 1,
  味見: 0,
  味方: 1,
  '味方・身方': 1,
  未刊: 0,
  未完成: -1,
  未熟: -1,
  未熟さ: -1,
  未熟者: -1,
  未曾有: 0,
  未納: -1,
  未病: -1,
  未来: 1,
  未練: -1,
  魅: 1,
  魅了: 1,
  魅力: 1,
  密命: 0,
  脈: 0,
  妙: 0,
  妙案: 1,
  妙技: 1,
  妙手: 1,
  民間企業: 0,
  民主: 0,
  民主化: 1,
  民主主義: 0,
  民族差別: -1,
  民力: 0,
  眠さ: -1,
  眠たさ: -1,
  眠り: 1,
  眠気: -1,
  夢: 0,
  夢中: 0,
  夢中遊行症: -1,
  夢魔: -1,
  夢遊病: -1,
  夢寐: 1,
  無愛想: -1,
  無意味: -1,
  無一物: -1,
  無一文: -1,
  無益: -1,
  無遠慮: -1,
  無音部分: 0,
  無我: 0,
  無害: 1,
  無学: -1,
  無関心: 0,
  無機質: -1,
  無気力: -1,
  無軌道: -1,
  無休: -1,
  無限: 0,
  無限ループ: -1,
  無呼吸: -1,
  無呼吸症候群: -1,
  無効: -1,
  無口: 0,
  無垢: 1,
  無彩色: -1,
  無策: -1,
  無雑作: -1,
  無惨: -1,
  無産: -1,
  無残: -1,
  '無残・無惨': -1,
  無私: 0,
  無視: -1,
  無事: 1,
  無事故: 1,
  無慈悲: -1,
  無識: 0,
  無実: 0,
  無邪気: 1,
  無傷: 1,
  無償: 0,
  無情: -1,
  無条件: 0,
  無心: 1,
  無神経: -1,
  無人島: 0,
  無精: -1,
  無声: 0,
  無責任: -1,
  無節操: -1,
  無銭: -1,
  無造作: -1,
  '無造作・無雑作': -1,
  無駄: -1,
  無駄遣い: -1,
  無駄使い: -1,
  無断: -1,
  無断リンク: -1,
  無断使用: -1,
  無知: -1,
  無秩序: -1,
  無茶: -1,
  無茶苦茶: -1,
  無調法: -1,
  無痛: 0,
  無敵: 1,
  無添加: 0,
  無難: 0,
  無念: -1,
  無念さ: -1,
  無念無想: -1,
  無能: -1,
  無能力: -1,
  無農薬: 0,
  無敗: 1,
  無筆: -1,
  無病息災: 1,
  無風流: -1,
  '無風流・不風流': -1,
  無法: -1,
  無謀: -1,
  無防備: -1,
  無名: -1,
  無明: -1,
  無目的: -1,
  無様: -1,
  無用: -1,
  無頼: 0,
  無理: -1,
  無理難題: -1,
  無料: 1,
  無料化: 0,
  無力: -1,
  無力さ: -1,
  無力感: -1,
  無礼: -1,
  矛盾: -1,
  矛盾点: -1,
  霧: -1,
  冥加: 1,
  冥福: -1,
  冥利: 1,
  名: 1,
  名うて: 1,
  名セリフ: 1,
  名案: 1,
  名医: 1,
  名演: 1,
  名家: 1,
  名花: 1,
  名曲: 1,
  名言: 1,
  名工: 1,
  名作: 1,
  名残: -1,
  名残り: -1,
  名手: 1,
  名所: 1,
  名勝: 1,
  名勝負: 1,
  名匠: 1,
  名乗り: 1,
  名場面: 1,
  名人: 1,
  名声: 1,
  名前: 0,
  名代: 1,
  名台詞: 1,
  名店: 1,
  名馬: 1,
  名品: 1,
  名物: 1,
  名聞: 1,
  名門: 1,
  名誉: 1,
  名誉毀損: -1,
  名利: 1,
  名流: 1,
  命: 1,
  命がけ: 1,
  命取り: -1,
  命拾い: 0,
  命終: -1,
  命脈: 1,
  命冥加: 1,
  明らか: 0,
  明るさ: 1,
  明暗: 0,
  明解: 1,
  明快: 1,
  明確: 1,
  明確化: 0,
  明記: 0,
  明鏡止水: 1,
  明治維新: 0,
  明示: 0,
  明神: 0,
  明日: 1,
  明白: 0,
  明明白白: 1,
  明瞭: 1,
  明瞭度: 1,
  明朗: 1,
  明媚: 1,
  明晰: 1,
  迷い: -1,
  迷子: -1,
  迷信: -1,
  迷走: -1,
  迷路: 0,
  迷惑: -1,
  迷惑メール: -1,
  迷惑行為: -1,
  迷惑電話: -1,
  銘: 1,
  鳴き: 0,
  鳴り: 0,
  鳴子: 0,
  滅菌: 0,
  滅私: 0,
  滅失: -1,
  滅亡: -1,
  免疫: 1,
  免疫応答: 0,
  免疫機能: 1,
  免疫不全: -1,
  免疫力: 1,
  免許: 0,
  免許皆伝: 1,
  免除: 0,
  免税: 0,
  面々: 0,
  面影: 0,
  面汚し: -1,
  面子: 0,
  面倒: -1,
  面罵: -1,
  面白さ: 1,
  面白み: 1,
  面白味: 1,
  面目: 1,
  面疔: -1,
  模倣: 0,
  模様: 0,
  模様替え: 0,
  妄執: -1,
  妄信: -1,
  妄想: -1,
  妄念: -1,
  妄評: -1,
  毛玉: 0,
  毛嫌い: -1,
  毛虫: -1,
  毛筆: 0,
  毛並み: 0,
  猛スピード: 0,
  猛威: -1,
  猛火: -1,
  猛禽: 0,
  猛攻: 0,
  猛暑: -1,
  猛打: 0,
  猛追: 0,
  猛爆: 0,
  猛反撃: 0,
  猛反対: 0,
  猛反発: 0,
  盲腸: -1,
  盲腸炎: -1,
  盲点: -1,
  盲目: -1,
  網タイツ: 0,
  網膜症: -1,
  網膜剥離: -1,
  蒙古: 0,
  蒙昧: -1,
  木くず: -1,
  木陰: 0,
  木精: 0,
  木造: 0,
  木霊: 0,
  黙許: 0,
  黙諾: 0,
  黙認: 0,
  目: 0,
  目くじら: -1,
  目くらまし: -1,
  目つき: 0,
  目の上のこぶ: -1,
  '目の上のこぶ／のたんこぶ': -1,
  目の上のたんこぶ: -1,
  目の敵: -1,
  目やに: -1,
  目隠し: -1,
  目覚まし: 0,
  目詰まり: -1,
  目視: 0,
  目処: 0,
  目障り: -1,
  目新しさ: 0,
  目切れ: -1,
  目端: 0,
  目潰し: -1,
  目的: 0,
  目的意識: 1,
  目的達成: 1,
  目的物: 0,
  目途: 1,
  目標: 1,
  目標達成: 1,
  目腐れ: -1,
  目利き: 1,
  目眩: -1,
  尤物: 1,
  籾: 0,
  問: 0,
  問い合わせ: 0,
  問責: -1,
  問題: 0,
  問題意識: 1,
  問題解決: 1,
  問題解決能力: 1,
  問題行動: -1,
  問題点: -1,
  問答: 0,
  悶絶: -1,
  悶着: -1,
  紋章: 0,
  門違い: -1,
  門出: 0,
  門前払い: -1,
  夜寒: 0,
  夜泣き: -1,
  夜行性: 0,
  夜叉: -1,
  夜襲: -1,
  夜盗: -1,
  夜働き: 0,
  夜尿症: -1,
  夜這い: -1,
  夜盲症: -1,
  夜遊び: -1,
  爺さん: 0,
  野球: 0,
  野犬: -1,
  野菜不足: -1,
  野次: -1,
  野次馬: -1,
  野心: 0,
  野性味: 1,
  野生: 0,
  野選: 0,
  野鳥: 0,
  野盗: -1,
  野蛮: -1,
  野放図: -1,
  野望: 0,
  厄: -1,
  厄介: -1,
  厄難: -1,
  役者が一枚上: 1,
  役不足: -1,
  役務: 0,
  約１０年: 0,
  約１ヶ月: 0,
  約１年: 0,
  約２０年: 0,
  約２年: 0,
  約３週間: 0,
  約３年: 0,
  約５年: 0,
  約一ヶ月: 0,
  約諾: 0,
  約半年: 0,
  薬: 0,
  薬禍: -1,
  薬害エイズ: -1,
  薬効: 1,
  薬師如来: 1,
  躍進: 1,
  躍動感: 1,
  愉悦: 1,
  愉快: 1,
  油圧: 0,
  油汚れ: -1,
  油断: 0,
  油膜: -1,
  癒着: -1,
  輸送量: 0,
  優: 1,
  優しさ: 1,
  優位: 1,
  優位性: 1,
  優越: 1,
  優越感: 1,
  優雅: 1,
  優雅さ: 1,
  優遇: 0,
  優遇措置: 1,
  優秀: 1,
  優秀賞: 1,
  優柔不断: -1,
  優勝: 1,
  優勝者: 1,
  優勢: 1,
  優性: 0,
  優先: 0,
  優待: 0,
  優男: -1,
  優等: 1,
  優美: 1,
  優美さ: 1,
  勇: 1,
  勇み足: -1,
  勇敢: 1,
  勇気: 1,
  勇健: 1,
  勇者: 1,
  勇戦: 1,
  勇退: 1,
  勇名: 1,
  勇猛: 1,
  勇躍: 1,
  友愛: 1,
  友誼: 1,
  友好: 1,
  友好関係: 1,
  友好親善: 1,
  友情: 1,
  友人: 1,
  友達: 1,
  友達付き合い: 0,
  宥和: 0,
  幽遠: 1,
  幽鬼: 0,
  幽魂: 0,
  幽寂: 1,
  幽愁: -1,
  幽霊: 0,
  幽邃: 1,
  悠悠自適: 1,
  憂: -1,
  '憂い・憂え': -1,
  憂え: -1,
  憂えなし: 1,
  憂き目: -1,
  憂さ: -1,
  憂患: -1,
  憂苦: -1,
  憂国: -1,
  憂愁: -1,
  憂悶: -1,
  憂慮: -1,
  憂鬱: -1,
  有り難さ: 1,
  有意義: 1,
  有意差: 0,
  有益: 1,
  有休: 1,
  有給: 1,
  有限: 0,
  有効: 1,
  有効活用: -1,
  有効性: 1,
  有効成分: 1,
  有効打: 1,
  有効利用: 0,
  有彩色: 1,
  有事: -1,
  有識: 1,
  有償: -1,
  有象無象: 0,
  有情: 1,
  有数: 0,
  有毒: -1,
  有難さ: 1,
  有望: 1,
  有名: 1,
  有名どころ: 1,
  有名無実: -1,
  有用: 1,
  有用性: 1,
  有利: 1,
  有料: -1,
  有力: 1,
  有力選手: 1,
  猶予: 0,
  猶予期間: 1,
  裕福: 1,
  誘い: 1,
  誘拐: -1,
  誘拐事件: -1,
  誘致: 0,
  誘爆: -1,
  遊び: 0,
  遊び感覚: 0,
  遊び心: 1,
  遊び人: -1,
  遊惰: -1,
  遊蕩: -1,
  遊離: 0,
  郵政: 0,
  郵政民営化: 0,
  雄: 0,
  雄健: 1,
  雄大: 1,
  雄編: 0,
  融資: 0,
  融和: 1,
  予後: -1,
  予行演習: 0,
  予算内: 0,
  予習復習: 0,
  予定価格: 0,
  予備知識: 0,
  予備費: 0,
  予報: 0,
  予防: 1,
  予防散布: 0,
  予防措置: 1,
  予約無し: 0,
  余: 0,
  余り: 0,
  余韻: 0,
  余栄: 1,
  余寒: -1,
  余技: 0,
  余慶: 0,
  余罪: -1,
  余情: 0,
  余震: -1,
  余生: 0,
  余談: 0,
  余地: 0,
  余徳: 1,
  余念: -1,
  余波: -1,
  余分: 0,
  余裕: 1,
  余殃: -1,
  与力: 0,
  誉れ: 1,
  預託金: 0,
  預貯金: 1,
  幼児: 0,
  幼弱: -1,
  幼稚: -1,
  幼虫: 0,
  妖しさ: -1,
  妖怪: -1,
  妖気: -1,
  妖女: 0,
  妖精: 0,
  妖美: 1,
  妖婦: 0,
  妖魔: 0,
  妖力: 0,
  容易: 1,
  容疑: -1,
  容赦: 0,
  容積: 0,
  容認: 0,
  容量不足: -1,
  揚力: 0,
  揺り返し: -1,
  揺れ: -1,
  擁護: 0,
  擁立: 0,
  様子: 0,
  様式美: 1,
  溶岩流: -1,
  溶連菌: 0,
  用: 0,
  用なし: -1,
  用意周到: 1,
  用語: 0,
  用済み: -1,
  用心: 0,
  用水: 0,
  用途: 1,
  羊頭狗肉: -1,
  羊毛: 0,
  要: 1,
  要なし: -1,
  '要なし・用なし': -1,
  要介護認定: 0,
  要求: -1,
  要件: 1,
  要職: 1,
  要請行動: 0,
  要素: 0,
  陽: 1,
  陽気: 1,
  陽性: 0,
  陽転: 0,
  養鶏場: 0,
  養護: 0,
  養成: 0,
  養生: 0,
  養分: 1,
  抑圧: -1,
  抑制: 0,
  抑揚: 0,
  抑鬱: -1,
  抑鬱症: -1,
  欲: -1,
  欲しさ: 0,
  欲求: 0,
  欲求不満: -1,
  欲情: -1,
  欲心: -1,
  欲望: 0,
  浴びせ倒し: 0,
  翼賛: 1,
  羅刹: -1,
  裸: 0,
  裸の王様: 0,
  裸一貫: -1,
  裸女: 0,
  裸婦: 0,
  来意: 0,
  来援: 0,
  来世: 0,
  来報: 0,
  頼み: 0,
  頼もしさ: 1,
  雷: -1,
  雷雨: -1,
  雷撃: -1,
  雷神: 0,
  雷名: 1,
  落ちつき: 1,
  落ちる: -1,
  落ち込み: -1,
  落ち着き: 1,
  落ち度: -1,
  落ち目: -1,
  落とし穴: -1,
  落下: -1,
  落果: -1,
  落球: -1,
  落伍: -1,
  '落伍・落後': -1,
  落後: -1,
  落差: -1,
  落車: -1,
  落書き: -1,
  落掌: 0,
  落石: -1,
  落雪: 0,
  落選: -1,
  落胆: -1,
  落馬: -1,
  落盤: -1,
  落命: -1,
  落葉: 0,
  落雷: 0,
  落輪: -1,
  落魄: -1,
  乱れ: -1,
  乱れ打ち: 0,
  乱開発: -1,
  乱獲: -1,
  乱気流: -1,
  乱撃: -1,
  乱行: -1,
  乱高下: -1,
  乱雑: -1,
  乱視: -1,
  乱打: 0,
  乱調: -1,
  乱闘: -1,
  乱発: -1,
  '乱発・濫発': -1,
  乱費: -1,
  '乱費・濫費': -1,
  乱舞: 0,
  乱文: -1,
  乱暴: -1,
  乱用: -1,
  乱立: -1,
  卵巣嚢腫: -1,
  嵐: -1,
  濫行: -1,
  '濫行・乱行': -1,
  濫造: -1,
  濫発: 0,
  濫費: -1,
  濫用: -1,
  蘭: 0,
  利: 1,
  利運: 1,
  利益: 1,
  利益率: 1,
  利回り: 1,
  利権: 1,
  利潤: 1,
  利点: 1,
  利得: 1,
  利幅: 1,
  利便: 1,
  利便性: 1,
  利用: 0,
  利用価値: 1,
  利用権: 1,
  利用効率: 1,
  利用時間: 1,
  利用者: 1,
  利用条件: -1,
  利用制限: -1,
  利用促進: 0,
  利用頻度: 1,
  利用目的: 0,
  利用料: 0,
  利用料金: 0,
  利欲: 0,
  理: 1,
  理解: 1,
  理解力: 1,
  理屈: 0,
  理屈抜き: 1,
  理事: 0,
  理性: 1,
  理想: 1,
  理想郷: 1,
  理想像: 0,
  理想論: 0,
  理知: 1,
  理念: 1,
  理非: 0,
  理不尽: -1,
  理不尽さ: -1,
  理由: 0,
  裏拳: 0,
  裏書き: 0,
  裏声: 0,
  裏切り: -1,
  裏切り者: -1,
  裏付け: 0,
  裏門: 0,
  里帰り: 0,
  離縁: -1,
  離婚: -1,
  離散: -1,
  離愁: 0,
  離職: 0,
  離職者: 0,
  離脱: 0,
  離脱症状: -1,
  離党: 0,
  離任: 0,
  律: 0,
  立ちくらみ: -1,
  立ちすくみ: -1,
  立ち往生: -1,
  立ち技: 0,
  立ち合い: 0,
  立ち役: 0,
  立て役: 0,
  立位: 0,
  立会人: 0,
  立証: 0,
  立体感: 1,
  立派: 1,
  立法: 0,
  略奪: -1,
  流し打ち: 0,
  流れ: 0,
  流感: -1,
  流刑: -1,
  流血: -1,
  流行り病: -1,
  流行性感冒: -1,
  流行病: -1,
  流罪: -1,
  流産: -1,
  流出: -1,
  流動性: 0,
  流入: 0,
  流用: -1,
  流謫: -1,
  溜め息: -1,
  溜飲: 0,
  溜息: 0,
  留守: 0,
  留置権: 0,
  留年: -1,
  硫黄臭: 0,
  隆昌: 1,
  隆盛: 1,
  竜巻: -1,
  竜神: 0,
  旅行: 1,
  旅行気分: 1,
  旅行者: 0,
  旅愁: 0,
  旅情: 1,
  旅疲れ: -1,
  旅費: 0,
  了解: 0,
  了承: 0,
  両極: 0,
  両極端: 0,
  両成敗: 0,
  両生類: 0,
  両全: 1,
  両雄: 1,
  両立: 1,
  料金: -1,
  料理: 0,
  梁上の君子: -1,
  涼: 0,
  涼しげ: 1,
  涼しさ: 1,
  涼感: 1,
  涼風: 0,
  療法: 0,
  療養生活: 0,
  良: 1,
  良さ: 1,
  良縁: 1,
  良型: 1,
  良好: 1,
  良材: 1,
  良作: 1,
  良識: 1,
  良質: 1,
  良心: 1,
  良性: 0,
  良智良能: 1,
  良能: 1,
  良否: 0,
  良品: 1,
  量: 1,
  量感: 1,
  量刑: -1,
  領土: 0,
  力: 1,
  力ずく: -1,
  力学的: 0,
  力関係: 0,
  力技: 0,
  力強さ: 1,
  力攻め: 0,
  力作: 1,
  力仕事: 0,
  力士: 0,
  力戦: 1,
  力添え: 0,
  力投: 0,
  力闘: 1,
  力任せ: 0,
  力不足: -1,
  力編: 1,
  力落とし: -1,
  力量: 1,
  緑: 0,
  緑黄色野菜: 0,
  緑化: 0,
  緑内障: -1,
  倫理: 1,
  倫理観: 1,
  林政: 0,
  淋しさ: -1,
  淋菌: -1,
  臨界: -1,
  臨界事故: -1,
  臨機応変: 0,
  臨終: -1,
  臨床試験: 0,
  臨場感: 1,
  輪禍: -1,
  輪廻転生: 0,
  輪作: 0,
  涙声: -1,
  累犯: -1,
  類: 0,
  類型: 0,
  類焼: -1,
  類推: 0,
  類題: 0,
  令名: 1,
  例題: 0,
  冷: 0,
  冷え: 0,
  冷え症: -1,
  冷え性: -1,
  冷たさ: -1,
  冷ややか: -1,
  冷夏: -1,
  冷害: -1,
  冷覚: 0,
  冷汗: -1,
  冷気: 0,
  冷血: -1,
  冷血漢: -1,
  冷血動物: 0,
  冷笑: -1,
  冷水: 0,
  冷静: 1,
  冷静さ: 1,
  冷静沈着: 1,
  冷戦: -1,
  冷徹: 1,
  冷罵: -1,
  冷評: -1,
  励まし: 1,
  励み: 1,
  礼: 0,
  礼儀: 1,
  礼賛: 0,
  礼状: 0,
  零点: -1,
  零敗: 1,
  零落: -1,
  霊: 0,
  霊気: 0,
  霊験: 1,
  霊魂: 0,
  霊体: 0,
  霊力: 1,
  霊柩車: 0,
  麗人: 1,
  歴史: 0,
  歴史文化: 1,
  歴然: 1,
  歴歴: 1,
  列車事故: -1,
  劣悪: -1,
  劣化: -1,
  劣化ウラン弾: -1,
  劣弱: -1,
  劣情: -1,
  劣勢: -1,
  劣性: -1,
  劣等: -1,
  劣等感: -1,
  烈女: -1,
  裂果: -1,
  裂傷: -1,
  廉価: 1,
  恋: 0,
  恋しさ: 1,
  恋愛: 1,
  恋愛感情: 1,
  恋情: 1,
  恋心: 1,
  恋人: 1,
  恋人気分: 1,
  恋着: 1,
  恋煩い: -1,
  恋風: 1,
  恋路: 0,
  練習不足: -1,
  連休ぼけ: -1,
  連携協力: 0,
  連携強化: 0,
  連合: 0,
  連合王国: 0,
  連鎖倒産: -1,
  連作: 0,
  連勝: 1,
  連戦連勝: 1,
  連続攻撃: -1,
  連続殺人事件: -1,
  連続性: 1,
  連続投稿: 0,
  連打: -1,
  連帯: 0,
  連帯意識: 1,
  連帯感: 1,
  連中: 0,
  連投: 0,
  連覇: 1,
  連破: 0,
  連敗: -1,
  連絡体制: 0,
  路銀: 0,
  路上生活: -1,
  路上駐車: -1,
  路用: 0,
  露: -1,
  露の命: -1,
  露骨: -1,
  露出度: 0,
  露命: -1,
  労: -1,
  労咳: -1,
  労苦: -1,
  労災: -1,
  労作: 0,
  労働: 0,
  労働意欲: 1,
  労働災害: -1,
  労働時間: 0,
  労働争議: -1,
  労働力: 1,
  労働力不足: -1,
  労務: 0,
  労役: 0,
  労力: 0,
  朗笑: 0,
  朗色: 1,
  朗報: 1,
  浪人: -1,
  浪人生活: -1,
  浪費: -1,
  漏り: -1,
  漏れ: -1,
  漏洩: -1,
  漏出: -1,
  漏水: -1,
  漏電: -1,
  牢乎: 1,
  '牢乎・牢固': 1,
  牢固: 1,
  狼: -1,
  狼藉: -1,
  老け役: 0,
  老化: 0,
  老化現象: -1,
  老眼: -1,
  老朽: -1,
  老朽化: -1,
  老巧: 0,
  老熟: 0,
  老人: 0,
  老人介護: 0,
  老年: 0,
  老婆心: 1,
  老廃: -1,
  老舗: 1,
  老齢基礎年金: 1,
  老練: 1,
  老獪: 1,
  蝋: 0,
  六波羅蜜: 1,
  肋間神経痛: -1,
  肋膜炎: -1,
  録: 1,
  論拠: 0,
  論旨: 1,
  論証: 0,
  論戦: 0,
  論敵: 0,
  論点: 0,
  論理: 1,
  論理性: 1,
  論理的: 1,
  論理的思考: 1,
  論理的思考力: 1,
  倭本族: 0,
  倭寇: -1,
  '倭寇・和寇': -1,
  和: 1,
  和解: 0,
  和姦: -1,
  和気: 1,
  和魂漢才: 1,
  和室: 0,
  和食: 0,
  和太鼓: 0,
  和風: 0,
  和平: 1,
  和寇: -1,
  話し上手: 1,
  話し声: 0,
  話題: 1,
  話題性: 1,
  歪: -1,
  歪み: -1,
  歪曲: -1,
  脇見: 0,
  枠組: 0,
  湾岸戦争: -1,
  腕こき: 1,
  '腕こき・腕っこき': 1,
  腕っこき: 1,
  腕試し: 0,
  腕前: 0,
  腕利き: 1,
  腕力: 1,
  乖離: -1,
  偕老同穴: 1,
  傲慢: -1,
  傲慢さ: -1,
  僥倖: 1,
  冤罪: -1,
  几帳面: 1,
  匈奴: 0,
  呵責: -1,
  呵呵大笑: 1,
  咎: -1,
  咆哮: -1,
  咬傷: -1,
  哄笑: 0,
  哺乳類: 0,
  喀血: -1,
  喘息: -1,
  喘鳴: -1,
  嗚咽: 0,
  嗅覚: 1,
  嗟嘆: 1,
  嗤笑: -1,
  嘔吐: -1,
  嘲笑: -1,
  嘲罵: -1,
  嚥下障害: -1,
  埃: 0,
  埓埒: -1,
  毀損: -1,
  毀誉褒貶: 1,
  奸計: -1,
  奸知: -1,
  嬌笑: -1,
  嬌声: -1,
  嬌名: 0,
  屁: -1,
  屁理屈: -1,
  屏息: -1,
  幇助: 0,
  彙報: -1,
  彷徨: 0,
  徘徊: 0,
  恍惚感: 1,
  恣意: -1,
  恣意性: -1,
  惻隠: 0,
  愍然: -1,
  慇懃: 1,
  慇懃無礼: -1,
  憫笑: -1,
  憫然: -1,
  懊悩: -1,
  罹患: -1,
  罹災: -1,
  罹病: -1,
  懶惰: -1,
  扁桃腺: 0,
  捏造: -1,
  掣肘: 0,
  掏り: -1,
  掟: 0,
  攪乱: -1,
  攪拌: 0,
  撥水性: 1,
  擱坐: -1,
  攘夷: 0,
  旱魃: -1,
  曖昧: -1,
  曖昧さ: -1,
  曖昧性: -1,
  杞憂: -1,
  枷: -1,
  桿菌: 0,
  梵鐘: 0,
  檻: -1,
  鬱: -1,
  鬱屈: -1,
  鬱血: -1,
  鬱状態: -1,
  鬱積: -1,
  鬱病: -1,
  鬱憤: -1,
  殷: 0,
  殷賑: 1,
  氣: 1,
  洒落: 1,
  淪落: -1,
  渤海: 0,
  烙印: -1,
  烽火: -1,
  爬虫類: 0,
  狎昵: 0,
  狡知: -1,
  猜疑: -1,
  猩紅熱: -1,
  瑕瑾: -1,
  瑕疵: -1,
  瑕疵担保責任: 0,
  瑣事: -1,
  '瑣事・些事': -1,
  疔: -1,
  疝気: -1,
  疝痛: -1,
  疥癬: -1,
  疼痛: -1,
  疱疹: -1,
  疱瘡: -1,
  痒み: -1,
  痒疹: -1,
  痙攣: -1,
  痰: -1,
  痺れ: -1,
  瘢痕: -1,
  瘤: -1,
  瘴気: -1,
  瘴癘: -1,
  瘰癧: -1,
  癇: -1,
  癇癖: -1,
  癇癪: -1,
  癇癪持ち: -1,
  癪: -1,
  癪持ち: -1,
  癰: -1,
  皺: 0,
  眩暈: -1,
  瞑目: -1,
  矜持: 1,
  矮小化: -1,
  磔刑: -1,
  礫: 0,
  穢れ: -1,
  箏曲家: 0,
  糜爛: -1,
  絆: 1,
  絨緞爆撃: 0,
  綺羅: 1,
  綺麗: 1,
  緻密: 1,
  緻密さ: 1,
  罠: -1,
  罨法: 1,
  羞恥: -1,
  羞恥心: 0,
  翡翠: 1,
  肛門: 0,
  隋: 0,
  腑分け: 0,
  腱鞘炎: -1,
  膀胱炎: -1,
  膂力: 1,
  膠原病: -1,
  膵炎: -1,
  膺懲: -1,
  艱苦: -1,
  艱難: -1,
  艱難辛苦: -1,
  蕁麻疹: -1,
  薨去: -1,
  蘊蓄: 0,
  蜀: 0,
  蟄居: 0,
  褥瘡: -1,
  訃: -1,
  訃音: -1,
  訃報: -1,
  訌争: -1,
  訛り: -1,
  詭弁: -1,
  諫止: -1,
  謗法: -1,
  謫居: -1,
  譴責: -1,
  豁然: 1,
  貪欲: -1,
  贅: 1,
  贅沢: 0,
  贅肉: -1,
  跼蹐: -1,
  踵: 0,
  踵落とし: -1,
  蹉跌: 0,
  躁: -1,
  躁病: -1,
  躁鬱: -1,
  躁鬱病: -1,
  躊躇: -1,
  躾: 0,
  軋轢: -1,
  輻輳: -1,
  辟易: -1,
  辣腕: 1,
  醵金: 1,
  醵出: 0,
  鉈: 0,
  錚錚: -1,
  閨怨: -1,
  隘路: -1,
  隕石: 0,
  靄: 0,
  頌徳: 1,
  頽唐: -1,
  頽廃: -1,
  顰蹙: -1,
  騙り: -1,
  驍名: 1,
  髀肉の嘆: -1,
  魏: 0,
  魑魅魍魎: -1,
  鮑: 0,
  鹹味: 0,
  麒麟: 0
};

export default sentimentWords;
