import AppBar from '@mui/material/AppBar';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import { Link } from 'react-router-dom';

export const AppBarLanding = () => {
  return (
    <AppBar
      position="relative"
      color="transparent"
      elevation={0}
      sx={{ height: 100, minHeight: 100 }}
    >
      <br />
      <Toolbar sx={{ height: 100, minHeight: 100 }}>
        <Grid container>
          <Grid item>
            <Typography
              variant="h5"
              noWrap
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'flex' },
                flexGrow: 1,
                fontWeight: 700,
                color: 'RGBA(255,255,255,1)',
                textDecoration: 'none'
              }}
            >
              <ContentPasteSearchIcon
                sx={{
                  display: { xs: 'flex', md: 'flex' },
                  mt: 0.43,
                  mr: 1
                }}
              />
              CommentScope
            </Typography>
          </Grid>
          <Grid item xs />
          <Grid item>
            <Button
              color="inherit"
              variant="contained"
              disableElevation
              target="_blank"
              href="https://forms.gle/j5qCbFJZcZRMvZgP8"
              size="large"
              sx={{ borderRadius: 20 }}
            >
              お問い合わせ
            </Button>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  );
};
