import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useFile } from '@/stores';
import { iconColor } from '@/config/colors';

// const baseStyle = {};

// const focusedStyle = {
//   borderColor: '#2196f3',
//   background: 'red'
// };

// const acceptStyle = {
//   borderColor: '#00e676'
// };

// const rejectStyle = {
//   borderColor: '#ff1744'
// };

export function StyledDropzone(props: any) {
  const { setAcceptedFiles } = useFile();

  const onDrop = useCallback((draggedFiles: any) => {
    setAcceptedFiles(draggedFiles);
  }, []);

  // const style: any = useMemo(
  //   () => ({
  //     ...baseStyle,
  //     ...(isFocused ? focusedStyle : {}),
  //     ...(isDragAccept ? acceptStyle : {}),
  //     ...(isDragReject ? rejectStyle : {})
  //   }),
  //   [isFocused, isDragAccept, isDragReject]
  // );

  // const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });
  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      <IconButton
        sx={{
          margin: 0,
          width: '100%',
          height: 150,
          borderRadius: 2,
          border: '1px solid',
          borderColor: 'RGBA(240,240,240,1)'
        }}
        aria-label="save"
      >
        <Grid container>
          <Grid item xs={12}>
            <AddCircleOutlineIcon
              sx={{ fontSize: 30, color: iconColor }}
              fontSize="large"
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle2">
              ファイルをドラッグ＆ドロップ
              <br />
              または<u>クリックして選択</u>
            </Typography>
          </Grid>
        </Grid>
      </IconButton>
    </div>
  );
}
